import { BotActionFeedbackTagSelect } from './../../../../../shared/model/bot-action-feedback-tag-select.interface';
import { BotEventType } from './../../../../../shared/model/bot-event-type.enum';
import { BotActionTeamSelect } from './../../../../../shared/model/bot-action-team-select.interface';
import { BotMessageBlock } from './../../../../../shared/model/bot-message-block.interface';
import { BotActionTextInput } from './../../../../../shared/model/bot-action-text-input.interface';
import { BotActionFeedbackTypeSelect } from './../../../../../shared/model/bot-action-feedback-type-select.interface';
import { BotActionUserSelect } from './../../../../../shared/model/bot-action-user-select.interface';
import { BotActionButton } from './../../../../../shared/model/bot-action-button.interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BotActionType } from '../../../../../shared/model/bot-action-type.enum';
import { BotAction } from '../../../../../shared/model/bot-action.interface';
import { BotMessage } from '../../../../../shared/model/bot-message.interface';
import { BotMessageAuthor } from '../../../../../shared/model/bot-message-author.enum';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
import * as structjson from 'structjson';
import * as dialogFlow from 'dialogflow';
import { Logger } from '@nsalaun/ng-logger';
import { environment } from './../../../environments/environment';
import { BotMessageBlockType } from '../../../../../shared/model/bot-message-block-type.enum';
import { TranslateService } from '@ngx-translate/core';



@Injectable()
export class BotService {

  private messagesArray: BotMessage[] = [];
  public messages = new Subject<BotMessage[]>();
  private actionsArray: BotAction[] = [];
  public actions = new Subject<BotAction[]>();
  public isMessageLoading = new BehaviorSubject<boolean>(true);

  constructor(
    private logger: Logger,
    private translateService: TranslateService
  ) { }

  // Sends and receives messages via DialogFlow
  public converse(message: BotMessage) {

    this.showMessage(message);

    const dialogFlowQueryInput: dialogFlow.QueryInput = {
      text: {
        text: message.text,
        languageCode: this.translateService.currentLang
      }
    }

    this.callBotApi(dialogFlowQueryInput);

  }

  public clearActions() {
    this.actionsArray = [];
    this.actions.next(this.actionsArray);
  }

  public clearMesages() {
    this.messagesArray = [];
    this.messages.next(this.messagesArray);
  }

  private showMessage(message: BotMessage) {
    this.isMessageLoading.next(false);

    this.messagesArray.push(message);
    this.messages.next(this.messagesArray);
  }

  private parseMessageBlocks(messageBlocks: BotMessageBlock[]) {

    // get actions from action blocks
    messageBlocks.map((messageBlock: BotMessageBlock) => {

      if (messageBlock.type === BotMessageBlockType.Section) {
        const messageFromBot: BotMessage = {
          text: messageBlock.text.text,
          sentBy: BotMessageAuthor.Bot,
          producedByActionInteraction: false
        }
        this.showMessage(messageFromBot);
      }
      else if (messageBlock.type === BotMessageBlockType.Actions) {
        messageBlock.elements.map(element => {
          // console.log('element', element);

          let botAction: BotAction;

          switch (element.type) {
            case BotActionType.Button:
              const botActionButton: BotActionButton = {
                label: element.text.text,
                value: element.value
              };
              botAction = {
                type: BotActionType.Button,
                data: botActionButton
              };
              this.showAction(botAction);
              break;

            case BotActionType.UserSelect:
              const botActionUserSelect: BotActionUserSelect = {
                placeholder: element.placeholder.text
              };
              botAction = {
                type: BotActionType.UserSelect,
                data: botActionUserSelect
              };
              this.showAction(botAction);
              break;

            case BotActionType.TeamSelect:
              const botActionTeamSelect: BotActionTeamSelect = {
                placeholder: element.placeholder.text
              };
              botAction = {
                type: BotActionType.TeamSelect,
                data: botActionTeamSelect
              };
              this.showAction(botAction);
              break;

            case BotActionType.FeedbackTagSelect:
              const botActionFeedbackTagSelect: BotActionFeedbackTagSelect = {
                placeholder: element.placeholder.text
              };
              botAction = {
                type: BotActionType.FeedbackTagSelect,
                data: botActionFeedbackTagSelect
              };
              this.showAction(botAction);
              break;

            case BotActionType.FeedbackTypeSelect:
              const botActionFeedbackTypeSelect: BotActionFeedbackTypeSelect = {
                placeholder: element.placeholder.text,
                onlyPositive: false
              }
              botAction = {
                type: BotActionType.FeedbackTypeSelect,
                data: botActionFeedbackTypeSelect
              };
              this.showAction(botAction);
              break;

            case BotActionType.PositiveFeedbackTypeSelect:
              const botActionPositiveFeedbackTypeSelect: BotActionFeedbackTypeSelect = {
                placeholder: element.placeholder.text,
                onlyPositive: true
              }
              botAction = {
                type: BotActionType.FeedbackTypeSelect,
                data: botActionPositiveFeedbackTypeSelect
              };
              this.showAction(botAction);
              break;

            case BotActionType.TextInput:
              const botActionTextInput: BotActionTextInput = {
                placeholder: element.placeholder.text
              }
              botAction = {
                type: BotActionType.TextInput,
                data: botActionTextInput
              };
              this.showAction(botAction);
              break;
          }
        })
      }

    })
  }

  private showAction(action: BotAction) {
    // console.log('showAction', action);
    this.actionsArray.push(action);
    this.actions.next(this.actionsArray);
  }

  // private async callBotApi(dialogFlowQueryInput: dialogFlow.QueryInput): Promise<dialogFlow.QueryResult> {
  private async callBotApi(dialogFlowQueryInput: dialogFlow.QueryInput) {
    this.logger.info('[callBotApi]', 'dialogFlowQueryInput', dialogFlowQueryInput);

    this.isMessageLoading.next(true);

    try {

      // Route request using local emulator
      if (environment.local) {
        firebase.functions().useFunctionsEmulator(environment.apiUrl);
        // firebase.functions().useFunctionsEmulator('http://localhost:5000');
      }

      const botApi = firebase.functions().httpsCallable('botApi');

      const botApiResponse: firebase.functions.HttpsCallableResult = await botApi(dialogFlowQueryInput);
      const dialogFlowQueryResult: dialogFlow.QueryResult = botApiResponse.data;

      this.logger.info('[callBotApi]', 'dialogFlowQueryResult', dialogFlowQueryResult);

      this.processBotApiResponse(dialogFlowQueryResult)

    } catch (error) {
      this.logger.error(error); //TODO: obsłużyć błąd
    }

  }

  private processBotApiResponse(dialogFlowQueryResult: dialogFlow.QueryResult) {
    const fulfillmentMessages = _.filter(dialogFlowQueryResult.fulfillmentMessages, ['platform', 'PLATFORM_UNSPECIFIED']);
    fulfillmentMessages.map((fulfillmentMessage: dialogFlow.Message) => {

      if (fulfillmentMessage.text) {
        const messageFromBot: BotMessage = {
          text: fulfillmentMessage.text.text[0],
          sentBy: BotMessageAuthor.Bot,
          producedByActionInteraction: false
        }
        this.showMessage(messageFromBot);

      }
      else if (fulfillmentMessage.payload) {
        const fulfillmentMessageJsonPaylod = structjson.structProtoToJson(fulfillmentMessage.payload);
        const messageBlocks = fulfillmentMessageJsonPaylod.blocks;
        this.parseMessageBlocks(messageBlocks);
      }

    })

    if (dialogFlowQueryResult.webhookPayload) {
      const jsonWebhookPaylod = structjson.structProtoToJson(dialogFlowQueryResult.webhookPayload);
      const messageBlocks = jsonWebhookPaylod['WEBAPP'].blocks;
      this.parseMessageBlocks(messageBlocks);
    }
  }

  public triggerWelcomeIntent() {
    const queryInput: dialogFlow.QueryInput = {
      event: {
        name: "welcome",
        languageCode: this.translateService.currentLang
      }
    };
    this.callBotApi(queryInput);
  }
  
  public triggerResponseFeedbackIntent(feedbackRequestTokenId: string, feedbackRequestRecipientId: string) {
    const queryInput: dialogFlow.QueryInput = {
      event: {
        name: "response_feedback",
        languageCode: this.translateService.currentLang,
        parameters: structjson.jsonToStructProto({
          feedbackRequestTokenId: feedbackRequestTokenId,
          feedbackRecipientIds: [feedbackRequestRecipientId], 
          // "textFeedbackRecipientIds": [`<@${feedbackRequestRecipientId}>`], // Ids are extracted from original variable later on (ids enclosed in <@ >)
          // feedbackRecipientIds: {
          //   original: [`<@${feedbackRequestRecipientId}>`], // Ids are extracted from original variable later on (ids enclosed in <@ >)
          // },
          actionType: BotEventType.ResponseFeedback,
        })
      }
    };
    this.callBotApi(queryInput);
  }
}