import { createSelector, createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromFeedbackTypes from './feedback-types.reducer';


export interface State {
    feedbackTypes: fromFeedbackTypes.State;
}

export const reducers: ActionReducerMap<State> = {
    feedbackTypes: fromFeedbackTypes.reducer,
};

const getState = createFeatureSelector<State>('feedback');


// Feedback types selectors
const getFeedbackTypesState = createSelector(
    getState,
    (state: State) => state.feedbackTypes
);

export const getFeedbackTypes = createSelector(
    getFeedbackTypesState,
    fromFeedbackTypes.selectAll
);

export const isFeedbackTypesLoading = createSelector(
    getFeedbackTypesState,
    fromFeedbackTypes.loading
);


// // User selectors
// const getUserState = createSelector(
//     getState,
//     (state: State) => state.user
// );

// export const getUser = createSelector(
//     getUserState,
//     fromUser.getUser
// );

// export const isUserLoading = createSelector(
//     getUserState,
//     fromUser.loading
// );