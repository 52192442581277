<!-- <div class="bot" ngx-auto-scroll> -->
<div class="bot">

  <div class="row justify-content-center">
    <div class="col">
      <ng-container *ngFor="let message of messages | async">
        <app-bot-message [message]="message"></app-bot-message>
      </ng-container>

      <app-bot-message *ngIf="isMessageLoading | async" [isLoading]="true"></app-bot-message>

      <div class="bot-action-wrapper">
        <ng-container *ngFor="let action of actions | async">
          <app-bot-action [action]="action" (message)="onActionMessage($event)"></app-bot-action>
        </ng-container>
      </div>

    </div>
  </div>
</div>
