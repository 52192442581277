import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../../../../../shared/model/user.interface';
import { map, filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/store/reducers/auth.reducer';
import { UserRole } from '../../../../../../shared/model/user-role.enum';
import { Organization } from '../../../../../../shared/model/organization.interface';

@Component({
  selector: 'app-onboarding-button',
  templateUrl: './onboarding-button.component.html',
  styleUrls: ['./onboarding-button.component.scss']
})
export class OnboardingButtonComponent implements OnInit {

  @Input()
  authUser: User;

  userRole = UserRole;

  sub;
  isOrganizationLoading: Observable<boolean>;

  isTeamsConfigurationCompleted: boolean = false;
  isUsersConfigurationCompleted: boolean = false;
  isFeedbackConfigurationCompleted: boolean = false;
  isAwardsConfigurationCompleted: boolean = false;
  isAllConfigrationStepsCompleted: boolean = false;

  completedConfigurationStepsCount: number;

  constructor(
    private store: Store<fromAuth.State>
  ) { }

  ngOnInit() {

    this.isOrganizationLoading = this.store.select(fromAuth.isOrganizationLoading);

    this.sub = this.store.select(fromAuth.getOrganization).pipe(
      filter(organization => !!organization),
      map((organization: Organization) => {
        this.isFeedbackConfigurationCompleted = organization.stats.feedbackTypesActive > 0 ? true : false;
        this.isTeamsConfigurationCompleted = organization.stats.teamsActive > 0 ? true : false;
        this.isUsersConfigurationCompleted = organization.stats.usersActive > 1 ? true : false; // Don't count admin user
        this.isAwardsConfigurationCompleted = organization.stats.awardTypesActive > 0 ? true : false;

        this.completedConfigurationStepsCount = 0;
        this.completedConfigurationStepsCount = this.isFeedbackConfigurationCompleted ? this.completedConfigurationStepsCount + 1 : this.completedConfigurationStepsCount
        this.completedConfigurationStepsCount = this.isTeamsConfigurationCompleted ? this.completedConfigurationStepsCount + 1 : this.completedConfigurationStepsCount
        this.completedConfigurationStepsCount = this.isUsersConfigurationCompleted ? this.completedConfigurationStepsCount + 1 : this.completedConfigurationStepsCount
        this.completedConfigurationStepsCount = this.isAwardsConfigurationCompleted ? this.completedConfigurationStepsCount + 1 : this.completedConfigurationStepsCount

        this.isAllConfigrationStepsCompleted = this.completedConfigurationStepsCount == 4 ? true : false;
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


}
