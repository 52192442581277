import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'firstchar'})
export class FirstCharPipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (!value) return value;

    // Trim white spaces from both sides of a string
    value = value.trim();

    return value.replace(/.\S*/g, function(txt) {
        return txt.charAt(0);
    });
  }
}