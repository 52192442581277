import { LoginPasswordResetComponent } from './auth/components/login-password-reset/login-password-reset.component';
import { BotPageComponent } from './bot/containers/bot-page/bot-page.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './auth/services/auth-guard.service';
import { RedirectIfLoggedInGuard } from './auth/services/redirect-if-logged-in-guard.service';
import { LoginPageComponent } from './auth/components/login-page/login-page.component';
import { LoginPasswordResetRequestComponent } from './auth/components/login-password-reset-request/login-password-reset-request.component';
import { LayoutComponent } from './layout/containers/layout/layout.component';

export const routes: Routes = [
  {
    // Auth module is not lazy loaded (services form auth module required on app start)
    path: 'login',
    canActivate: [RedirectIfLoggedInGuard],
    children: [
      {
        path: '',
        component: LoginPageComponent
      },
      {
        path: 'password-reset-request',
        component: LoginPasswordResetRequestComponent
      },
      {
        path: 'password-reset',
        component: LoginPasswordResetComponent
      }
    ]
  },
  {
    path: 'signup/:token',
    canActivate: [RedirectIfLoggedInGuard],
    loadChildren: () => import('app/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: '',
    component: LayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/feed',
        pathMatch: 'full'
      },
      {
        path: 'feed',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/feed/feed.module').then(m => m.FeedModule),

      },
      {
        path: 'ideas',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/suggestions/suggestions.module').then(m => m.SuggestionsModule),

      },
      {
        path: 'users/:id',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/user-settings/user-settings.module').then(m => m.UserSettingsModule)
      },
      {
        path: 'teams',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/teams/teams.module').then(m => m.TeamsModule)
      },
      {
        path: 'teams/:id',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/team/team.module').then(m => m.TeamModule)
      },
      {
        path: 'rewards',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/awards/awards.module').then(m => m.AwardsModule)
      },
      {
        path: 'onboarding',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/onboarding/onboarding.module').then(m => m.OnboardingModule),
      },
      {
        path: 'leaderboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/leaderboard/leaderboard.module').then(m => m.LeaderboardModule),
      },
      {
        path: 'network',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'bot',
        canActivate: [AuthGuard],
        component: BotPageComponent, // Problems with routing when bot moule is lazy loaded probably because bot component is included in parent component Layout 
        // loadChildren: 'app/bot/bot.module#BotModule',
      },
    ]
  },
  {
    path: '**',
    // component: PageNotFoundComponent 
    redirectTo: '/feed', //TODO: to powinno być przekierowanie do PageNotFoundComponent
  }
];