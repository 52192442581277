<mat-sidenav-container>
    <!-- Mobile sidenav -->
    <mat-sidenav #sidenav mode="over" position="end">
        <app-sidenav [sidenavRef]="sidenav"></app-sidenav>
    </mat-sidenav>

    <!-- Main app content -->
    <mat-sidenav-content>
        <div class="container-fluid">
            <app-nav-top [sidenavRef]="sidenav"></app-nav-top>
            <div class="main-content">
                <router-outlet></router-outlet>
                <!-- Bot -->
                <!-- <app-bot-dialog *ngIf="showBot"></app-bot-dialog> -->
                <app-feedback-button *ngIf="showBot"></app-feedback-button>
            </div>
            
        </div>
    </mat-sidenav-content>


</mat-sidenav-container>