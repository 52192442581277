import { SuggestionStatus } from './../../../../../shared/model/suggestion-status.enum';
import { MeetingTopicStatus } from './../../../../../shared/model/meeting-topic-status.enum';
import { MeetingTopic } from './../../../../../shared/model/meeting-topic.interface';
import { OrganizationFeedbackReminderSettingsWithTenant } from './../../../../../shared/model/organization-feedback-reminder-settings-with-tenant.interface';
import { OrganizationFeedbackReminderSettings } from './../../../../../shared/model/organization-feedback-reminder-settings.interface';
import { SuggestionVoteType } from './../../../../../shared/model/suggestion-vote-type.enum';
import { SuggestionVote } from './../../../../../shared/model/suggestion-vote.interface';
import { SuggestionComment } from './../../../../../shared/model/suggestion-comment.interface';
import { SuggestionRecipientType } from './../../../../../shared/model/suggestion-recipient-type.enum';
import { Suggestion } from './../../../../../shared/model/suggestion.interface';
import { UserPartial } from './../../../../../shared/model/user-partial.interface';
import { FeedbackKind } from './../../../../../shared/model/feedback-kind.enum';
import { UserFeedbackTagStat } from './../../../../../shared/model/user-feedback-tag-stat.interface';
import { FeedbackTag } from './../../../../../shared/model/feedback-tag.interface';
import { UserWithPoints } from './../../../../../shared/model/user-with-points.interface';
import { RegistrationResponse } from './../../../../../shared/model/registration-response.interface';

import { combineLatest as observableCombineLatest, Observable, of, from, forkJoin, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

import * as _ from 'lodash';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentChangeAction, QuerySnapshot, Query } from '@angular/fire/firestore';
import { Team } from '../../../../../shared/model/team.interface';
import { User } from '../../../../../shared/model/user.interface';

import * as fromAuth from '../../auth/store/reducers/auth.reducer';
import { Store } from '@ngrx/store';
import { switchMap, tap, map, take, flatMap, mergeMap, filter, concatMap, catchError, concatAll } from 'rxjs/operators';
import { Feedback } from '../../../../../shared/model/feedback.interface';
import { FeedbackType } from '../../../../../shared/model/feedback-type.interface';
import { UserFeedbackTypeStat } from '../../../../../shared/model/user-feedback-type-stat.interface';
import { Logger } from '@nsalaun/ng-logger';
import { Organization } from '../../../../../shared/model/organization.interface';
import { AwardType } from '../../../../../shared/model/award-type.interface';
import { Award } from '../../../../../shared/model/award.interface';
import { OrganizationSettings } from '../../../../../shared/model/organization-settings.interface';
import { UserWithTenant } from '../../../../../shared/model/user-with-tenant.interface';
import { OrganizationStats } from '../../../../../shared/model/organization-stats.interface';
import { AwardInput } from '../../../../../shared/model/award-input.interface';
import { RegistrationRequest } from '../../../../../shared/model/registration-request.interface';
import { UserRole } from '../../../../../shared/model/user-role.enum';
import { FeedbackRequest } from '../../../../../shared/model/feedback-request.interface';
import { FeedbackRequestToken } from '../../../../../shared/model/feedback-request-token.interface';
import { FeedbackVisibilityType } from '../../../../../shared/model/feedback-visibility-type.enum';
import { FeedbackRequestTokenStatus } from '../../../../../shared/model/feedback-request-token-status.enum';


@Injectable()
export class DataProviderService {

  private serverTimestamp;

  constructor(
    private db: AngularFirestore,
    private logger: Logger,
    private store: Store<fromAuth.State>
  ) {

    // Server timestamp
    this.serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  }


  getTenantAuth(userId: string): Observable<any> {
    return this.db.doc<string>(`tenantsAuth/${userId}`).valueChanges();
  }


  ////////////////////////////////////////////////////////////////////////
  // Organization
  ////////////////////////////////////////////////////////////////////////

  registerOrganization(registrationRequest: RegistrationRequest): Promise<RegistrationResponse> {
    this.logger.time('[registerOrganization]');
    this.logger.debug('[registerOrganization]', 'send request to register organization');

    const firebaseFunction = firebase.functions().httpsCallable('registerOrganization');
    return firebaseFunction(registrationRequest)
      .then((response: firebase.functions.HttpsCallableResult) => {
        this.logger.debug('[registerOrganization]', 'Organization registered', response);
        this.logger.timeEnd('[registerOrganization]');

        const registrationResponse: RegistrationResponse = response.data;
        return registrationResponse;
      })
      .catch(error => {
        this.logger.error('[registerOrganization]', 'Failed to register organization: ', error.code, error.message);
        this.logger.timeEnd('[registerOrganization]');
        throw error;
      });

  }

  getOrganization(): Observable<Organization> {
    this.logger.time('[getOrganization]');
    this.logger.info('[getOrganization]', 'Getting organization');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Organization>(`tenants/${tenantId}`).valueChanges()
      ),
      map((organization: Organization) => {
        this.logger.info('[getOrganization]:', 'Organization loaded', organization);
        this.logger.timeEnd('[getOrganization]');
        return organization;
      })
    );
  }

  getOrganizationSettings(): Observable<OrganizationSettings> {
    this.logger.time('[getOrganizationSettings]');
    this.logger.info('[getOrganizationSettings]', 'Getting teanant settings');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Organization>(`tenants/${tenantId}`).valueChanges()
      ),
      map((organization: Organization) => {
        this.logger.info('[getOrganizationSettings]:', 'Organization settings loaded', organization.settings);
        this.logger.timeEnd('[getOrganizationSettings]');
        return organization.settings;
      })
    );
  }

  updateOrganizationSettings(organizationSettings: OrganizationSettings): Observable<boolean> {
    this.logger.time('[updateOrganizationSettings]');
    this.logger.info('[updateOrganizationSettings]', 'Updating organization settings', organizationSettings);

    organizationSettings.modifiedAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Organization>(`tenants/${tenantId}`).update({ settings: organizationSettings })
          .then(() => {
            this.logger.info('[updateOrganizationSettings]', 'Organization settings successfully updated');
            this.logger.timeEnd('[updateOrganizationSettings]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateOrganizationSettings]', 'Failed to update organization settings', error)
            return false;
          })
      )
    )
  }

  updateOrganizationFeedbackReminderCronJob(feedbackReminderSettings: OrganizationFeedbackReminderSettings): Observable<boolean> {
    this.logger.time('[updateOrganizationFeedbackReminderCronJob]');
    this.logger.info('[updateOrganizationFeedbackReminderCronJob]', 'Updating organization feedback reminder cron job', feedbackReminderSettings);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) => {

        const feedbackSettingsWithTenant: OrganizationFeedbackReminderSettingsWithTenant = {
          tenantId: tenantId,
          feedbackReminderSettings: feedbackReminderSettings
        }
        this.logger.info('[updateOrganizationFeedbackReminderCronJob]', 'Feedback settings with tenant', feedbackSettingsWithTenant);

        const firebaseFunciton = firebase.functions().httpsCallable('updateOrganizationFeedbackReminderCronJob');
        return firebaseFunciton(feedbackSettingsWithTenant)
          .then(response => {
            this.logger.info('[updateOrganizationFeedbackReminderCronJob]', 'Organization feedback reminder cron job successfully updated');
            this.logger.timeEnd('[updateOrganizationFeedbackReminderCronJob]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateOrganizationFeedbackReminderCronJob]', 'Failed to update organization feedback reminder cron job', error)
            throw error;
          });

      })
    )

  }


  getOrganizationStats(): Observable<OrganizationStats> {
    this.logger.time('[getOrganizationStats]');
    this.logger.info('[getOrganizationStats]', 'Getting organization stats');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Organization>(`tenants/${tenantId}`).valueChanges()
      ),
      map((organization: Organization) => {
        this.logger.info('[getOrganizationStats]:', 'Organization stats loaded', organization.stats);
        this.logger.timeEnd('[getOrganizationStats]');
        return organization.stats;
      })
    );
  }



  ////////////////////////////////////////////////////////////////////////
  // User
  ////////////////////////////////////////////////////////////////////////

  addUser(user: User): Observable<any> {
    this.logger.time('[addUser]');
    this.logger.info('[addUser]', 'Adding user', user);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) => {

        const userWithTenant: UserWithTenant = Object.assign({}, { user: user }, { tenantId: tenantId });
        this.logger.info('[addUser]', 'User with tenant', userWithTenant);

        const firebaseFunciton = firebase.functions().httpsCallable('createUser');
        return firebaseFunciton(userWithTenant)
          .then(response => {
            this.logger.info('[addUser]', 'User successfully added');
            this.logger.timeEnd('[addUser]');
            return response;
          })
          .catch(error => {
            this.logger.error('[addUser]', 'Failed to add user:', error.code, error.message)
            throw error;
          });
      })
    )
  }

  updateUser(user: User): Observable<any> {
    this.logger.time('[updateUser]');
    this.logger.info('[updateUser]', 'Updating user', user);

    user.modifiedAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) => {

        const userWithTenant: UserWithTenant = Object.assign({}, { user: user }, { tenantId: tenantId });
        this.logger.info('[updateUser]', 'User with tenant', userWithTenant);

        const firebaseFunciton = firebase.functions().httpsCallable('updateUser');
        return firebaseFunciton(userWithTenant)
          .then(response => {
            this.logger.info('[updateUser]', 'User successfully updated');
            this.logger.timeEnd('[updateUser]');
            return response;
          })
          .catch(error => {
            this.logger.error('[updateUser]', 'Failed to update user:', error.code, error.message)
            throw error;
          });

      })
    )
  }

  updateUserAvatar(userId: string, avatarUrl: string): Observable<boolean> {
    this.logger.time('[updateUserAvatar]');
    this.logger.info('[updateUserAvatar]', 'Updating user avatar', userId, avatarUrl);

    const changedData = {
      modifiedAt: this.serverTimestamp,
      avatar: avatarUrl
    }

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<User>(`tenants/${tenantId}/users`).doc(userId).update(changedData)
          .then(() => {
            this.logger.info('[updateUserAvatar]', 'User successfully updated');
            this.logger.timeEnd('[updateUserAvatar]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateUserAvatar]', 'Failed to update user', error)
            return false;
          })
      )
    )
  }

  getUser(userId: string): Observable<User> {
    this.logger.time('[getUser]');
    this.logger.info('[getUser]:', `Loading user`, userId);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId), // wait for tenantId to be not null and continue
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<User>(`tenants/${tenantId}/users/${userId}`).valueChanges()
      ),
      map((user: User) => {
        if (user) {
          // default user properties
          return {
            ...user,
            stats: {
              score: 0,
              scoreRedeemed: 0,
              feedbackIn: 0,
              feedbackOut: 0,
              feedbackInTypes: {},
              feedbackInPrivate: 0,
              feedbackOutPrivate: 0,
              feedbackRequestsOut: 0,
              feedbackRequestTokensIn: 0,
              feedbackRequestTokensInResponded: 0,
              feedbackRequestTokensOut: 0,
              feedbackRequestTokensOutResponded: 0,
              awards: 0,
              awardTypes: {},
              ...user.stats
            }
          }
        } else { // User returned only for purpose of logging  with user used for debugging 
          return {
            // id: 'L39l755dXvXo3B9eFa5VVucEUO93',
            // active: false,
            // firstName: 'Admin',
            // lastName: 'Admin',
            // email: '',
            // gender: UserGender.Male,
            // jobTitle: '',
            // orgLevel: '1',
            // orgRole: UserRole.Admin,
            // teamId: '',
            // stats: {
            //   score: 0,
            //   scoreRedeemed: 0,
            //   feedbackIn: 0,
            //   feedbackOut: 0,
            //   feedbackInTypes: {},
            //   awards: 0,
            //   awardTypes: {},
            // }
          };
        }
      }),
      tap((user: User) => {
        this.logger.info('[getUser]', 'User loaded', user);
        this.logger.timeEnd('[getUser]');
      })
    );
  }

  getUsers(limit?: number): Observable<User[]> {
    this.logger.time('[getUsers]');
    this.logger.info('[getUsers]', `Loading users`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<User>(`tenants/${tenantId}/users`, ref =>
          limit ? ref.orderBy('createdAt').limit(limit) : ref.orderBy('createdAt')
        ).valueChanges()
      ),
      tap((users: User[]) => {
        this.logger.info('[getUsers]:', 'Users loaded', users);
        this.logger.timeEnd('[getUsers]');
      })
    );
  }

  getActiveUsers(): Observable<User[]> {
    this.logger.time('[getActiveUsers]');
    this.logger.info('[getActiveUsers]', `Loading active users`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<User>(`tenants/${tenantId}/users`, ref => ref
          .where('active', '==', true)
          .orderBy('lastName', 'asc')
          // .orderBy('createdAt')
        ).valueChanges()
      ),
      tap((users: User[]) => {
        this.logger.info('[getActiveUsers]:', 'Active users loaded', users);
        this.logger.timeEnd('[getActiveUsers]');
      })
    );
  }

  getActiveUsersByTeamId(teamId: string): Observable<User[]> {
    this.logger.time('[getActiveUsersByTeamId]');
    this.logger.info('[getActiveUsersByTeamId]', `Loading users`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<User>(`tenants/${tenantId}/users`, ref => ref
          .where('active', '==', true)
          .where('teamId', '==', teamId)
        ).valueChanges()
      ),
      tap((users: User[]) => {
        this.logger.info('[getActiveUsersByTeamId]:', 'Users loaded', users);
        this.logger.timeEnd('[getActiveUsersByTeamId]');
      })
    );
  }

  // getTeamUsersForFeedbackRequest(teamId: string, feedbackRequestAuthorId: string): Observable<User[]> {
  //   this.logger.time('[getTeamUsersForFeedbackRequest]');
  //   this.logger.info('[getTeamUsersForFeedbackRequest]', `Loading users`);

  //   return this.store.select(fromAuth.getTenantId).pipe(
  //     filter(tenantId => !!tenantId),
  //     take(1),
  //     switchMap((tenantId: string) =>
  //       this.db.collection<User>(`tenants/${tenantId}/users`, ref => ref
  //         .where('active', '==', true)
  //         .where('teamId', '==', teamId)
  //         .where('userId', '!==', feedbackRequestAuthorId)
  //       ).valueChanges().pipe(
  //         map
  //       )
  //     ),
  //     tap((users: User[]) => {
  //       this.logger.info('[getTeamUsersForFeedbackRequest]:', 'Users loaded', users);
  //       this.logger.timeEnd('[getTeamUsersForFeedbackRequest]');
  //     })
  //   );
  // }

  getTopUsers(limit: number): Observable<User[]> {
    this.logger.time('[getTopUsers]');
    this.logger.info('[getTopUsers]:', `Loading top ${limit} users`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<User>(`tenants/${tenantId}/users`, ref => ref
          .where('active', '==', true)
          .where('stats.score', '>', 0)
          .orderBy('stats.score', 'desc')
          .limit(limit)
        )
          .valueChanges()

      ),
      tap((users: User[]) => {
        this.logger.info('[getTopUsers]:', 'Top user stats loaded', users);
        this.logger.timeEnd('[getTopUsers]');
      })
    );
  }

  getTopUsersTimespan(numberOfDays: number): Observable<any> {
    this.logger.time('[getTopUsersTimespan]');
    this.logger.info('[getTopUsersTimespan]:', `Loading top users within ${numberOfDays} days`);

    // Calculate date
    var date = new Date();
    // console.log('Today is: ' + date.toLocaleString());
    date.setDate(date.getDate() - numberOfDays);
    // console.log('days ago was: ' + date.toLocaleString());

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Feedback>(`tenants/${tenantId}/feedback`, ref => ref
          .where('createdAt', '>', date)
          .where('visibility', '==', FeedbackVisibilityType.Public)
        ).valueChanges().pipe(
          map((feedbackList: Feedback[]) => ({ tenantId, feedbackList }))
        )
      ),
      // Show public feedback with points > 0
      map(({ tenantId, feedbackList }) => {
        feedbackList = feedbackList.filter(feedback => feedback.points > 0)
        return ({ tenantId, feedbackList })
      }),
      switchMap(({ tenantId, feedbackList }) => {

        if (feedbackList.length > 0) {

          // Group by users and count points
          let userIdsWithPoints = _(feedbackList)
            .groupBy('userId')
            .map((feedback, id) => ({
              userId: id,
              points: _.sumBy(feedback, 'points')
            }))
            .value();

          // Sort by points
          userIdsWithPoints = _.orderBy(userIdsWithPoints, ['points'], ['desc']);

          // Get users
          return forkJoin(userIdsWithPoints.map(userIdWithPoints =>
            // Get data once
            this.db.doc(`tenants/${tenantId}/users/${userIdWithPoints.userId}`).get().pipe(
              map((docSnapshot) => {
                let user = docSnapshot.data() as User;
                let userWithPoints: UserWithPoints = {
                  user: user,
                  points: userIdWithPoints.points
                }
                return userWithPoints;
              })
            )
          ))
        } else {
          return of([]); //return empty array 
        }

      }),
      tap((usersWithPoints: UserWithPoints[]) => {
        this.logger.info('[getTopUsersTimespan]:', 'Top user stats timespan loaded', usersWithPoints);
        this.logger.timeEnd('[getTopUsersTimespan]');
      }),
    );
  }


  getUserFeedbackTypeStats(userId: string): Observable<UserFeedbackTypeStat[]> {
    this.logger.time('[getUserFeedbackTypeStats]');
    this.logger.info('[getUserFeedbackTypeStats]:', `Loading user feedback type stats`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<User>(`tenants/${tenantId}/users/${userId}`)
          .valueChanges().pipe(
            map((user: User) => ({ tenantId, user })))
      ),
      switchMap(({ tenantId, user }) => {

        return this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`, ref => ref
          .where('positive', '==', true)
          .orderBy('points', 'asc')
        )
          .valueChanges().pipe(
            map((feedbackTypes: FeedbackType[]) => {
              let userFeedbackTypeStats: UserFeedbackTypeStat[] = [];

              // To avoid undefined when accessing a non-existing property
              let userFeedbackInTypesStats = (user.stats && user.stats.feedbackInTypes) ? user.stats.feedbackInTypes : []
              // let userFeedbackInTypesStats = [];
              // if (user.stats && user.stats.feedbackInTypes) {
              //   Object.assign(userFeedbackInTypesStats, user.stats.feedbackInTypes);
              //   // userFeedbackInTypesStats = user.stats.feedbackInTypes;
              // }

              feedbackTypes.map((feedbackType: FeedbackType) => {
                let userFeedbackTypeStat: UserFeedbackTypeStat = {
                  feedbackType: feedbackType,
                  count: userFeedbackInTypesStats[feedbackType.id] ? userFeedbackInTypesStats[feedbackType.id] : 0
                }

                // Add only when more than 0
                if (userFeedbackTypeStat.count > 0) {
                  userFeedbackTypeStats.push(userFeedbackTypeStat);
                }
              });

              this.logger.info('[getUserFeedbackTypeStats]:', 'User feedback type stats loaded', userFeedbackTypeStats);
              this.logger.timeEnd('[getUserFeedbackTypeStats]');
              return userFeedbackTypeStats;
            }))
      })
    );

  }

  getUserFeedbackTagsStats(userId: string): Observable<UserFeedbackTagStat[]> {
    this.logger.time('[getUserFeedbackTagsStats]');
    this.logger.info('[getUserFeedbackTagsStats]:', `Loading user feedback type stats`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<User>(`tenants/${tenantId}/users/${userId}`)
          .valueChanges().pipe(
            map((user: User) => ({ tenantId, user })))
      ),
      switchMap(({ tenantId, user }) => {

        return this.db.collection<FeedbackTag>(`tenants/${tenantId}/feedbackTags`)
          .valueChanges().pipe(
            map((feedbackTags: FeedbackTag[]) => {
              let userFeedbackTagStats: UserFeedbackTagStat[] = [];

              // To avoid undefined when accessing a non-existing property
              let userFeedbackInTags = user.stats.feedbackInTags;

              feedbackTags.map((feedbackTag: FeedbackTag) => {
                let userFeedbackTagStat: UserFeedbackTagStat = {
                  feedbackTag: feedbackTag,
                  count: userFeedbackInTags[feedbackTag.id] ? userFeedbackInTags[feedbackTag.id] : 0
                }

                // Add only when more than 0
                if (userFeedbackTagStat.count > 0) {
                  userFeedbackTagStats.push(userFeedbackTagStat);
                }
              });

              this.logger.info('[getUserFeedbackTagsStats]:', 'User feedback tags stats loaded', userFeedbackTagStats);
              this.logger.timeEnd('[getUserFeedbackTagsStats]');
              return userFeedbackTagStats;
            }))
      })
    );

  }

  checkIfUserExists(email: string): Observable<boolean> {
    this.logger.time('[checkIfUserExists]');
    this.logger.info('[checkIfUserExists]', 'Checkin if user with email exists', email);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<User>(`tenants/${tenantId}/users`, ref => ref
          .where('email', '==', email)
        )
          .valueChanges()

      ),
      map((users: User[]) => {

        if (users.length > 0) {
          this.logger.info('[checkIfUserExists]:', 'User exists');
          this.logger.timeEnd('[checkIfUserExists]');
          return true;
        } else {
          this.logger.info('[checkIfUserExists]:', 'User does not exist');
          this.logger.timeEnd('[checkIfUserExists]');
          return false;
        }

      })
    );
  }

  ////////////////////////////////////////////////////////////////////////
  // Team
  ////////////////////////////////////////////////////////////////////////

  addTeam(team: Team): Observable<boolean> {
    this.logger.time('[addTeam]');
    this.logger.info('[addTeam]', 'Adding team', team);

    // Create doc id and createdAt 
    team.id = this.db.createId();
    team.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Team>(`tenants/${tenantId}/teams`).doc(team.id).set(team)
          .then(() => {
            this.logger.info('[addTeam]', 'Team successfully added');
            this.logger.timeEnd('[addTeam]');
            return true;
          })
          .catch(error => {
            this.logger.error('[addTeam]', 'Failed to add team', error)
            return false;
          })
      )
      // take(1) // used to unsubscribe
    )
  }

  updateTeam(team: Team): Observable<boolean> {
    this.logger.time('[updateTeam]');
    this.logger.info('[updateTeam]', 'Updating team', team);

    team.modifiedAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Team>(`tenants/${tenantId}/teams`).doc(team.id).update(team)
          .then(() => {
            this.logger.info('[updateTeam]', 'Team successfully updated');
            this.logger.timeEnd('[updateTeam]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateTeam]', 'Failed to update team', error)
            return false;
          })
      )
    )
  }

  deleteTeam(teamId: string): Observable<boolean> {
    this.logger.time('[deleteTeam]');
    this.logger.info('[deleteTeam]', 'Deleting team', teamId);


    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Team>(`tenants/${tenantId}/teams`).doc(teamId).delete()
          .then(() => {
            this.logger.info('[deleteTeam]', 'Team successfully deleted');
            this.logger.timeEnd('[deleteTeam]');
            return true;
          })
          .catch(error => {
            this.logger.error('[deleteTeam]', 'Failed to delete team', error)
            return false;
          })
      )
    )
  }

  getTeam(teamId: string): Observable<Team> {
    this.logger.time('[getTeam]');
    this.logger.info('[getTeam]:', `Loading team`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Team>(`tenants/${tenantId}/teams/${teamId}`)
          .valueChanges()
      ),
      tap((team: Team) => {
        this.logger.info('[getTeam]', 'Team loaded');
        this.logger.timeEnd('[getTeam]');
      })
    );
  }

  getTeamWithUsers(teamId: string): Observable<Team> {
    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Team>(`tenants/${tenantId}/teams/${teamId}`)
          .valueChanges().pipe(
            map((team: Team) => ({ tenantId, team })))
      ),
      switchMap(({ tenantId, team }) =>
        this.db.collection<User>(`tenants/${tenantId}/users`, ref => ref
          .where('teamId', '==', team.id)
          .where('active', '==', true)
          .orderBy('createdAt')
        )
          .valueChanges().pipe(
            // take(1), //TODO: czemu tutaj ładuje tylko jednego usera
            map((users: User[]) => Object.assign({}, { ...team, users }))
          )
      )
    );
  }

  getTeams(limit?: number): Observable<Team[]> {
    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Team>(`tenants/${tenantId}/teams`, ref =>
          limit ? ref.orderBy('createdAt').limit(limit) : ref.orderBy('createdAt')
        ).valueChanges()
      )
    );
  }

  getTeamsWithUsers(): Observable<Team[]> {

    this.logger.time('[getTeamsWithUsers]');
    this.logger.info('[getTeamsWithUsers]:', `Loading teams with users`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Team>(`tenants/${tenantId}/teams`, ref => ref
          .orderBy('createdAt')
        ).valueChanges().pipe(
          map((teams: Team[]) => ({ tenantId, teams }))
        )
      ),
      //Get team users info
      switchMap(({ tenantId, teams }) => {
        if (teams.length > 0) {
          return forkJoin(teams.map(team =>
            this.db.collection<User>(`tenants/${tenantId}/users`, ref => ref
              .where('teamId', '==', team.id)
              .orderBy('createdAt')
            ).valueChanges().pipe(
              take(1),
              map((users: User[]) => Object.assign({}, { ...team, users }))
            )
          ))
        } else {
          return of(teams);
        }
      }),
      tap((teams: Team[]) => {
        this.logger.info('[getTeamsWithUsers]:', 'Teams with users loaded', teams);
        this.logger.timeEnd('[getTeamsWithUsers]');
      })
    );
  }


  getActiveTeams(): Observable<Team[]> {
    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Team>(`tenants/${tenantId}/teams`, ref => ref
          .where('active', '==', true)
          .orderBy('createdAt')
        ).valueChanges()
      )
    );
  }

  getActiveTeamsWithUsers(): Observable<Team[]> {

    this.logger.time('[getActiveTeamsWithUsers]');
    this.logger.info('[getActiveTeamsWithUsers]:', `Loading active teams with users`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Team>(`tenants/${tenantId}/teams`, ref => ref
          .where('active', '==', true)
          .orderBy('createdAt')
        ).valueChanges().pipe(
          map((teams: Team[]) => ({ tenantId, teams }))
        )
      ),
      //Get team users info
      switchMap(({ tenantId, teams }) => {
        if (teams.length > 0) {
          return forkJoin(teams.map(team =>

            // Standard method did not work so promise used (get data once)
            from(
              firebase.firestore().collection(`tenants/${tenantId}/users`)
                .where('teamId', '==', team.id)
                .where('active', '==', true)
                .orderBy('createdAt')
                .get()
            ).pipe(
              map((usersQuerySnapshot) => {
                var users: User[] = usersQuerySnapshot.docs.map((documentSnapshot) => documentSnapshot.data() as User);
                return Object.assign({}, { ...team, users })
              })
            )

          ))
        } else {
          return of(teams);
        }
      }),
      tap((teams: Team[]) => {
        this.logger.info('[getActiveTeamsWithUsers]:', 'Active teams with users loaded', teams);
        this.logger.timeEnd('[getActiveTeamsWithUsers]');
      })
    );
  }


  ////////////////////////////////////////////////////////////////////////
  // Feedback
  ////////////////////////////////////////////////////////////////////////
  getFeedbackList(
    limit: number,
    recipientId: string,
    authorId: string,
    visibility: FeedbackVisibilityType,
    kind: FeedbackKind,
    teamId: string,
    feedbackTypeId: string,
    feedbackRequestId: string,
    feedbackRequestTokenId: string,
    feedbackTagId: string
  ): Observable<Feedback[]> {

    this.logger.time('[getFeedbackList]');
    this.logger.info('[getFeedbackList]:', `Loading feedback list with filters:`,
      'limit:', limit,
      'recipientId:', recipientId,
      'authorId:', authorId,
      'visibility:', visibility,
      'kind:', kind,
      'teamId:', teamId,
      'feedbackTypeId:', feedbackTypeId,
      'feedbackRequestId:', feedbackRequestId,
      'feedbackRequestTokenId: ', feedbackRequestTokenId,
      'feedbackTagId: ', feedbackTagId
    );

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Feedback>(`tenants/${tenantId}/feedback`, ref => {
          let query: Query = ref;
          if (limit) {
            query = query.limit(limit);
          }
          if (recipientId) {
            query = query.where('userId', '==', recipientId);
          }
          if (authorId) {
            query = query.where('authorId', '==', authorId);
          }
          if (visibility) {
            query = query.where('visibility', '==', visibility);
          }
          if (kind) {
            query = query.where('kind', '==', kind);
          }
          if (teamId) {
            query = query.where('teamId', '==', teamId);
          }
          if (feedbackTypeId) {
            query = query.where('feedbackTypeId', '==', feedbackTypeId);
          }
          if (feedbackRequestId) {
            query = query.where('feedbackRequestId', '==', feedbackRequestId);
          }
          if (feedbackRequestTokenId) {
            query = query.where('feedbackRequestTokenId', '==', feedbackRequestTokenId);
          }
          if (feedbackTagId) {
            query = query.where('tagsIds', 'array-contains', feedbackTagId);
          }
          query = query.orderBy('createdAt', 'desc');
          return query;
        })
          .valueChanges().pipe(
            map((feedbackList: Feedback[]) => ({ tenantId, feedbackList }))
          )

      ),
      // Get feedback type info
      switchMap(({ tenantId, feedbackList }) => {
        if (feedbackList.length > 0) {
          return forkJoin(feedbackList.map(feedback => {

            if (feedback.feedbackTypeId) { //Feedback types (points) are not required
              return this.db.doc<FeedbackType>(`tenants/${tenantId}/feedbackTypes/${feedback.feedbackTypeId}`).valueChanges().pipe(
                take(1),
                map((feedbackType: FeedbackType) => Object.assign({}, { ...feedback, feedbackType }))
              )
            }
            else {
              return of(feedback);
            }
          }
          )).pipe(
            map((feedbackList: Feedback[]) => ({ tenantId, feedbackList }))
          )
        } else {
          return of({ tenantId, feedbackList });
        }
      }),
      // Get user info
      switchMap(({ tenantId, feedbackList }) => {
        if (feedbackList.length > 0) {
          return forkJoin(feedbackList.map(feedback =>
            this.db.doc<User>(`tenants/${tenantId}/users/${feedback.userId}`).valueChanges().pipe(
              take(1),
              map((user: User) => Object.assign({}, { ...feedback, user }))
            )
          )).pipe(
            map((feedbackList: Feedback[]) => ({ tenantId, feedbackList }))
          )
        } else {
          return of({ tenantId, feedbackList });
        }
      }),
      //Get author info
      switchMap(({ tenantId, feedbackList }) => {
        if (feedbackList.length > 0) {
          return forkJoin(feedbackList.map(feedback =>
            this.db.doc<User>(`tenants/${tenantId}/users/${feedback.authorId}`).valueChanges().pipe(
              take(1),
              map((author: User) => Object.assign({}, { ...feedback, author }))
            )
          ))
        } else {
          return of(feedbackList);
        }
      }),
      tap((feedbackList: Feedback[]) => {
        this.logger.info('[getFeedbackList]:', 'Feedback list loaded', feedbackList);
        this.logger.timeEnd('[getFeedbackList]');
      })

    );
  }

  isDailyFeedbackLimitExceeded(authorId: string, userId: string): Observable<boolean> {
    this.logger.time('[isDailyFeedbackLimitExceeded]');
    this.logger.info('[isDailyFeedbackLimitExceeded]', 'Checking feedback limit for author', authorId);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      // get feedback list for current date
      switchMap((tenantId: string) => {

        const currentDayWithoutTime = new Date(new Date().setHours(0, 0, 0, 0));

        return this.db.collection<Feedback>(`tenants/${tenantId}/feedback`, ref => ref
          .where('authorId', '==', authorId)
          .where('userId', '==', userId)
          .where('createdAt', '>', currentDayWithoutTime)
          .orderBy('createdAt', 'desc')
        )
          .valueChanges().pipe(
            map((feedbackList: Feedback[]) => ({ tenantId, feedbackList }))
          )
      }),
      take(1),
      // get organization settings
      switchMap(({ tenantId, feedbackList }) =>
        this.db.doc<Organization>(`tenants/${tenantId}`)
          .valueChanges().pipe(
            map((organization: Organization) => ({ feedbackList, organization }))
          )
      ),
      take(1),
      map(({ feedbackList, organization }) => {

        let isLimitExceeded: boolean;
        const dailyFeedbackLimitEnabled = organization.settings.dailyFeedbackLimitEnabled;
        const dailyFeedbackLimitCount = organization.settings.dailyFeedbackLimitCount;

        if (dailyFeedbackLimitEnabled && feedbackList.length >= dailyFeedbackLimitCount) {
          isLimitExceeded = true;
        } else {
          isLimitExceeded = false;
        }

        this.logger.info('[isDailyFeedbackLimitExceeded]', 'Daily feedback limit exceeded', isLimitExceeded);
        this.logger.timeEnd('[isDailyFeedbackLimitExceeded]');
        return isLimitExceeded;
      })
    );

  }

  addFeedback(feedback: Feedback): Observable<boolean> {
    this.logger.time('[addFeedback]');
    this.logger.info('[addFeedback]', 'Adding feedback for user', feedback.userId);

    // Create doc id and createdAt 
    feedback.id = this.db.createId();
    feedback.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Feedback>(`tenants/${tenantId}/feedback`).doc(feedback.id).set(feedback)
          .then(() => {

            // Stats are updated using cloud functions
            this.logger.info('[addFeedback]', 'Feedback successfully added');
            this.logger.timeEnd('[addFeedback]');

            return true;

          })
          .catch(error => {
            this.logger.error('[addFeedback]', 'Failed to add to feedback', error)
            return false;
          })
      )
      // take(1) // used to unsubscribe
    )
  }

  ////////////////////////////////////////////////////////////////////////
  // Feedback types
  ////////////////////////////////////////////////////////////////////////

  addFeedbackType(feedbackType: FeedbackType): Observable<boolean> {
    this.logger.time('[addFeedbackType]');
    this.logger.info('[addFeedbackType]', 'Adding feedback type', feedbackType);

    // Create doc id and createdAt
    feedbackType.id = this.db.createId();
    feedbackType.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`).doc(feedbackType.id).set(feedbackType)
          .then(() => {
            this.logger.info('[addFeedbackType]', 'Feedback type successfully added');
            this.logger.timeEnd('[addFeedbackType]');
            return true;
          })
          .catch(error => {
            this.logger.error('[addFeedbackType]', 'Failed to add feedback type', error)
            return false;
          })
      )
    )
  }

  updateFeedbackType(feedbackType: FeedbackType): Observable<boolean> {
    this.logger.time('[updateFeedbackType]');
    this.logger.info('[updateFeedbackType]', 'Updating feedback type', feedbackType);

    feedbackType.modifiedAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`).doc(feedbackType.id).update(feedbackType)
          .then(() => {
            this.logger.info('[updateFeedbackType]', 'Feedback type successfully updated');
            this.logger.timeEnd('[updateFeedbackType]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateFeedbackType]', 'Failed to update feedback type', error)
            return false;
          })
      )
    )
  }

  deleteFeedbackType(feedbackTypeId: string): Observable<boolean> {
    this.logger.time('[deleteFeedbackType]');
    this.logger.info('[deleteFeedbackType]', 'Deleting feedback type', feedbackTypeId);


    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`).doc(feedbackTypeId).delete()
          .then(() => {
            this.logger.info('[deleteFeedbackType]', 'Feedback type successfully deleted');
            this.logger.timeEnd('[deleteFeedbackType]');
            return true;
          })
          .catch(error => {
            this.logger.error('[deleteFeedbackType]', 'Failed to delete feedback type', error)
            return false;
          })
      )
    )
  }

  getFeedbackType(feedbackTypeId: string): Observable<FeedbackType> {
    this.logger.time('[getFeedbackType]');
    this.logger.info('[getFeedbackType]', 'Loading feedback type');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<FeedbackType>(`tenants/${tenantId}/feedbackTypes/${feedbackTypeId}`).valueChanges()
      ),
      tap((feedbackType: FeedbackType) => {
        this.logger.info('[getFeedbackType]', 'Feedback type loaded successfully', feedbackType);
        this.logger.timeEnd('[getFeedbackType]');
      })
    );
  }

  getFeedbackTypes(limit?: number): Observable<FeedbackType[]> {
    this.logger.time('[getFeedbackTypes]');
    this.logger.info('[getFeedbackTypes]', 'Loading feedback types');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`, ref =>
          limit ? ref.orderBy('points', 'asc').limit(limit) : ref.orderBy('points', 'asc')
        ).valueChanges()
      ),
      tap((feedbackTypes: FeedbackType[]) => {
        this.logger.info('[getFeedbackTypes]', 'Feedback types loaded successfully', feedbackTypes);
        this.logger.timeEnd('[getFeedbackTypes]');
      })
    );
  }

  getPositiveFeedbackTypes(limit?: number): Observable<FeedbackType[]> {
    this.logger.time('[getPositiveFeedbackTypes]');
    this.logger.info('[getPositiveFeedbackTypes]', 'Loading feedback types');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`, ref =>
          limit ? ref.where('positive', '==', true).orderBy('points', 'asc').limit(limit) : ref.where('positive', '==', true).orderBy('points', 'asc')
        ).valueChanges()
      ),
      tap((feedbackTypes: FeedbackType[]) => {
        this.logger.info('[getPositiveFeedbackTypes]', 'Feedback types loaded successfully', feedbackTypes);
        this.logger.timeEnd('[getPositiveFeedbackTypes]');
      })
    );
  }

  getActiveFeedbackTypes(): Observable<FeedbackType[]> {
    this.logger.time('[getActiveFeedbackTypes]');
    this.logger.info('[getActiveFeedbackTypes]', 'Loading feedback types');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`, ref => ref
          .where('active', '==', true)
          .orderBy('points', 'asc')
        )
          .valueChanges()
      ),
      tap((feedbackTypes: FeedbackType[]) => {
        this.logger.info('[getActiveFeedbackTypes]', 'Feedback typses loaded successfully', feedbackTypes);
        this.logger.timeEnd('[getActiveFeedbackTypes]');
      })
    );
  }

  getActivePositiveFeedbackTypes(): Observable<FeedbackType[]> {
    this.logger.time('[getActivePositiveFeedbackTypes]');
    this.logger.info('[getActivePositiveFeedbackTypes]', 'Loading feedback types');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`, ref => ref
          .where('active', '==', true)
          .where('positive', '==', true)
          .orderBy('points', 'asc')
        )
          .valueChanges()
      ),
      tap((feedbackTypes: FeedbackType[]) => {
        this.logger.info('[getActivePositiveFeedbackTypes]', 'Feedback typses loaded successfully', feedbackTypes);
        this.logger.timeEnd('[getActivePositiveFeedbackTypes]');
      })
    );
  }

  // getActiveFeedbackTypesAllowedForAuthUser(): Observable<FeedbackType[]> {
  //   this.logger.time('[getActiveFeedbackTypesAllowedForAuthUser]');
  //   this.logger.info('[getActiveFeedbackTypesAllowedForAuthUser]', 'Loading feedback types');

  //   return this.store.select(fromAuth.getTenantId).pipe(
  //     filter(tenantId => !!tenantId),
  //     take(1),
  //     switchMap((tenantId: string) =>
  //       // Get active feedback types
  //       this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`, ref => ref
  //         .where('active', '==', true)
  //         .orderBy('points', 'asc')
  //       )
  //         .valueChanges()
  //     ),
  //     switchMap((feedbackTypes: FeedbackType[]) =>
  //       // Get auth user
  //       this.store.select(fromAuth.getUser).pipe(
  //         map((user: User) => ({ feedbackTypes, user }))
  //       )
  //     ),
  //     map(({ feedbackTypes, user }) => {
  //       // Filter only feedback types allowed for auth user 
  //       const allowedFeedbackTypes: FeedbackType[] = [];
  //       feedbackTypes.map(feedbackType => {
  //         if (feedbackType.orgLevels.indexOf(user.orgLevel) >= 0) {
  //           allowedFeedbackTypes.push(feedbackType);
  //         }
  //       });
  //       return allowedFeedbackTypes;
  //     }),
  //     tap((feedbackTypes: FeedbackType[]) => {
  //       this.logger.info('[getActiveFeedbackTypesAllowedForAuthUser]', 'Feedback types loaded successfully', feedbackTypes);
  //       this.logger.timeEnd('[getActiveFeedbackTypesAllowedForAuthUser]');
  //     })
  //   );
  // }

  // getActivePositiveFeedbackTypesAllowedForAuthUser(): Observable<FeedbackType[]> {
  //   this.logger.time('[getActivePositiveFeedbackTypesAllowedForAuthUser]');
  //   this.logger.info('[getActivePositiveFeedbackTypesAllowedForAuthUser]', 'Loading feedback types');

  //   return this.store.select(fromAuth.getTenantId).pipe(
  //     filter(tenantId => !!tenantId),
  //     take(1),
  //     switchMap((tenantId: string) =>
  //       // Get active feedback types
  //       this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackTypes`, ref => ref
  //         .where('active', '==', true)
  //         .where('positive', '==', true)
  //         .orderBy('points', 'asc')
  //       )
  //         .valueChanges()
  //     ),
  //     switchMap((feedbackTypes: FeedbackType[]) =>
  //       // Get auth user
  //       this.store.select(fromAuth.getUser).pipe(
  //         map((user: User) => ({ feedbackTypes, user }))
  //       )
  //     ),
  //     map(({ feedbackTypes, user }) => {
  //       // Filter only feedback types allowed for auth user 
  //       const allowedFeedbackTypes: FeedbackType[] = [];
  //       feedbackTypes.map(feedbackType => {
  //         if (feedbackType.orgLevels.indexOf(user.orgLevel) >= 0) {
  //           allowedFeedbackTypes.push(feedbackType);
  //         }
  //       });
  //       return allowedFeedbackTypes;
  //     }),
  //     tap((feedbackTypes: FeedbackType[]) => {
  //       this.logger.info('[getActivePositiveFeedbackTypesAllowedForAuthUser]', 'Feedback typses loaded successfully', feedbackTypes);
  //       this.logger.timeEnd('[getActivePositiveFeedbackTypesAllowedForAuthUser]');
  //     })
  //   );
  // }

  isFeedbackTypeUsed(feedbackTypeId: string): Observable<boolean> {
    this.logger.time('isFeedbackTypeUsed');
    this.logger.debug('isFeedbackTypeUsed:', 'checking if feedback type is used', feedbackTypeId);

    // Check based on organization statistics
    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Organization>(`tenants/${tenantId}`).valueChanges()
      ),
      map((organization: Organization) => {
        if (organization.stats && organization.stats.feedbackTypesUsed && organization.stats.feedbackTypesUsed[feedbackTypeId]) {
          this.logger.debug('isFeedbackTypeUsed:', 'feedback type used', feedbackTypeId);
          this.logger.timeEnd('isFeedbackTypeUsed');
          return true;
        }
        else {
          this.logger.debug('isFeedbackTypeUsed:', 'feedback type not used', feedbackTypeId);
          this.logger.timeEnd('isFeedbackTypeUsed');
          return false;
        }
      })
    );
  }

  ////////////////////////////////////////////////////////////////////////
  // Award types
  ////////////////////////////////////////////////////////////////////////

  addAwardType(awardType: AwardType): Observable<boolean> {
    this.logger.time('[addAwardType]');
    this.logger.info('[addAwardType]', 'Adding award type', awardType);

    // Create doc id and createdAt
    awardType.id = this.db.createId();
    awardType.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<AwardType>(`tenants/${tenantId}/awardTypes`).doc(awardType.id).set(awardType)
          .then(() => {
            this.logger.info('[addAwardType]', 'Award type successfully added');
            this.logger.timeEnd('[addAwardType]');
            return true;
          })
          .catch(error => {
            this.logger.error('[addAwardType]', 'Failed to add award type', error)
            return false;
          })
      )
    )
  }

  updateAwardType(awardType: AwardType): Observable<boolean> {
    this.logger.time('[updateAwardType]');
    this.logger.info('[updateAwardType]', 'Updating award type', awardType);

    awardType.modifiedAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<AwardType>(`tenants/${tenantId}/awardTypes`).doc(awardType.id).update(awardType)
          .then(() => {
            this.logger.info('[updateAwardType]', 'Award type successfully updated');
            this.logger.timeEnd('[updateAwardType]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateAwardType]', 'Failed to update award type', error)
            return false;
          })
      )
    )
  }

  deleteAwardType(awardTypeId: string): Observable<boolean> {
    this.logger.time('[deleteAwardType]');
    this.logger.info('[deleteAwardType]', 'Deleting award type', awardTypeId);


    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<AwardType>(`tenants/${tenantId}/awardTypes`).doc(awardTypeId).delete()
          .then(() => {
            this.logger.info('[deleteAwardType]', 'award type successfully deleted');
            this.logger.timeEnd('[deleteAwardType]');
            return true;
          })
          .catch(error => {
            this.logger.error('[deleteAwardType]', 'Failed to delete award type', error)
            return false;
          })
      )
    )
  }

  getAwardType(awardTypeId: string): Observable<AwardType> {
    this.logger.time('[getAwardType]');
    this.logger.info('[getAwardType]', 'Loading award type');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<AwardType>(`tenants/${tenantId}/awardTypes/${awardTypeId}`).valueChanges()
      ),
      tap((awardType: AwardType) => {
        this.logger.info('[getAwardType]', 'Award type loaded successfully', awardType);
        this.logger.timeEnd('[getAwardType]');
      })
    );
  }

  getAwardTypes(limit?: number): Observable<AwardType[]> {
    this.logger.time('[getAwardTypes]');
    this.logger.info('[getAwardTypes]', 'Loading award types');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<AwardType>(`tenants/${tenantId}/awardTypes`, ref =>
          limit ? ref.orderBy('createdAt').limit(limit) : ref.orderBy('createdAt')
        ).valueChanges()
      ),
      tap((awardTypes: AwardType[]) => {
        this.logger.info('[getAwardTypes]', 'Award types loaded successfully', awardTypes);
        this.logger.timeEnd('[getAwardTypes]');
      })
    );
  }

  getActiveAwardTypes(): Observable<AwardType[]> {
    this.logger.time('[getActiveAwardTypes]');
    this.logger.info('[getActiveAwardTypes]', 'Loading active award types');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<AwardType>(`tenants/${tenantId}/awardTypes`, ref => ref
          .where('active', '==', true)
          .orderBy('createdAt')
        )
          .valueChanges()
      ),
      tap((awardTypes: AwardType[]) => {
        this.logger.info('[getActiveAwardTypes]', 'Active award types loaded successfully', awardTypes);
        this.logger.timeEnd('[getActiveAwardTypes]');
      })
    );
  }

  getUserAwards(userId: string): Observable<Award[]> {
    this.logger.time('[getUserAwards]');
    this.logger.info('[getUserAwards]', 'Loading user awards');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Award>(`tenants/${tenantId}/awards`, ref => ref
          .where('userId', '==', userId)
          .orderBy('createdAt')
        )
          .valueChanges()
      ),
      tap((awards: Award[]) => {
        this.logger.info('[getUserAwards]', 'User awards loaded successfully', awards);
        this.logger.timeEnd('[getUserAwards]');
      })
    );
  }

  getUserAwardsWithAwardTypes(userId: string): Observable<Award[]> {
    this.logger.time('[getUserAwardsWithAwardTypes]');
    this.logger.info('[getUserAwardsWithAwardTypes]', 'Loading user awards with award types');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Award>(`tenants/${tenantId}/awards`, ref => ref
          .where('userId', '==', userId)
          .orderBy('createdAt')
        )
          .valueChanges().pipe(
            map((awards: Award[]) => ({ tenantId, awards }))
          )
      ),
      //Get award type info
      switchMap(({ tenantId, awards }) => {
        if (awards.length > 0) {
          return forkJoin(awards.map(award =>
            this.db.doc<AwardType>(`tenants/${tenantId}/awardTypes/${award.awardTypeId}`).valueChanges().pipe(
              take(1),
              map((awardType: AwardType) => Object.assign({}, { ...award, awardType }))
            )
          ))
        } else {
          return of(awards);
        }
      }),
      tap((awards: Award[]) => {
        this.logger.info('[getUserAwardsWithAwardTypes]', 'User awards with award types loaded', awards);
        this.logger.timeEnd('[getUserAwardsWithAwardTypes]');
      })
    );
  }

  isAwardTypeUsed(awardTypeId: string): Observable<boolean> {
    this.logger.time('isAwardTypeUsed');
    this.logger.debug('isAwardTypeUsed:', 'checking if award type is used', awardTypeId);

    // Check based on organization statistics
    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Organization>(`tenants/${tenantId}`).valueChanges()
      ),
      map((organization: Organization) => {
        if (organization.stats && organization.stats.awardTypesUsed && organization.stats.awardTypesUsed[awardTypeId]) {
          this.logger.debug('isAwardTypeUsed:', 'award type used', awardTypeId);
          this.logger.timeEnd('isAwardTypeUsed');
          return true;
        }
        else {
          this.logger.debug('isAwardTypeUsed:', 'award type not used', awardTypeId);
          this.logger.timeEnd('isAwardTypeUsed');
          return false;
        }
      })
    );
  }

  ////////////////////////////////////////////////////////////////////////
  // Awards
  ////////////////////////////////////////////////////////////////////////

  addAward(awardInput: AwardInput): Observable<boolean> {
    this.logger.time('[addAward]');

    const award: Award = {
      id: this.db.createId(),
      createdAt: this.serverTimestamp,
      ...awardInput
    }
    this.logger.info('[addAward]', 'Adding award', award);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Award>(`tenants/${tenantId}/awards`).doc(award.id).set(award)
          .then(() => {
            this.logger.info('[addAward]', 'Award successfully added');
            this.logger.timeEnd('[addAward]');
            return true;
          })
          .catch(error => {
            this.logger.error('[addAward]', 'Failed to add award', error)
            return false;
          })
      )
    )
  }

  ////////////////////////////////////////////////////////////////////////
  // Private feedback
  ////////////////////////////////////////////////////////////////////////

  addFeedbackRequest(feedbackRequest: FeedbackRequest): Observable<boolean> {
    this.logger.time('[addFeedbackRequest]');
    this.logger.info('[addFeedbackRequest]', 'Adding feedback request', feedbackRequest);

    // Create doc id and createdAt
    feedbackRequest.id = this.db.createId();
    feedbackRequest.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackRequests`).doc(feedbackRequest.id).set(feedbackRequest)
          .then(() => {
            this.logger.info('[addFeedbackRequest]', 'Feedback request successfully added');
            this.logger.timeEnd('[addFeedbackRequest]');

            // Add feedback request tokens
            const saveFeedbackRequestTokensPromises = this.addFeedbackRequestTokensByFeedbackRequest(tenantId, feedbackRequest);
            async () => {
              await Promise.all(saveFeedbackRequestTokensPromises);
            }

            // const saveFeedbackRequestTokensPromises: Promise[] = [];
            // feedbackRequest.recipientsPartials.map(async (recipientPartial: UserPartial) => {
            //   const feedbackRequestToken: FeedbackRequestToken = {
            //     id: this.db.createId(),
            //     createdAt: this.serverTimestamp,

            //     feedbackRequestId: feedbackRequest.id,
            //     feedbackRequestPartial: {
            //       id: feedbackRequest.id,
            //       comment: feedbackRequest.comment,
            //       anonymousAuthor: feedbackRequest.anonymousAuthor,
            //       authorId: feedbackRequest.authorId,
            //       anonymousResponse: feedbackRequest.anonymousResponse,
            //       feedbackUserId: feedbackRequest.feedbackUserId,
            //       privateFeedback: feedbackRequest.privateFeedback,
            //       recipientType: feedbackRequest.recipientType,
            //       recipientsIds: feedbackRequest.recipientsIds
            //     },
            //     authorId: feedbackRequest.authorId,
            //     authorPartial: feedbackRequest.authorPartial,
            //     anonymousResponse: feedbackRequest.anonymousResponse,
            //     recipientId: recipientPartial.id,
            //     recipientPartial: recipientPartial,
            //     status: FeedbackRequestTokenStatus.Pending
            //   }

            //   const promise = this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackRequestTokens`).doc(feedbackRequestToken.id).set(feedbackRequestToken)
            //   saveFeedbackRequestTokensPromises.push(promise);
            //   await Promise.all(saveFeedbackRequestTokensPromises);

            // })


            return true;
          })
          .catch(error => {
            this.logger.error('[addFeedbackRequest]', 'Failed to add feedback request', error)
            return false;
          })
      )
    )
  }

  private addFeedbackRequestTokensByFeedbackRequest(tenantId: string, feedbackRequest: FeedbackRequest): Promise<void>[] {
    this.logger.time('[addFeedbackRequestTokensByFeedbackRequest]');
    this.logger.info('[addFeedbackRequestTokensByFeedbackRequest]', 'Adding feedback request tokens for feedback request', feedbackRequest);

    // Add feedback request tokens
    const saveFeedbackRequestTokensPromises = feedbackRequest.recipientsPartials.map((recipientPartial: UserPartial) => {
      const feedbackRequestToken: FeedbackRequestToken = {
        id: this.db.createId(),
        createdAt: this.serverTimestamp,

        feedbackRequestId: feedbackRequest.id,
        feedbackRequestPartial: {
          id: feedbackRequest.id,
          comment: feedbackRequest.comment,
          anonymousAuthor: feedbackRequest.anonymousAuthor,
          authorId: feedbackRequest.authorId,
          anonymousResponse: feedbackRequest.anonymousResponse,
          feedbackUserId: feedbackRequest.feedbackUserId,
          privateFeedback: feedbackRequest.privateFeedback,
          recipientType: feedbackRequest.recipientType,
          recipientsIds: feedbackRequest.recipientsIds
        },
        authorId: feedbackRequest.authorId,
        authorPartial: feedbackRequest.authorPartial,
        anonymousResponse: feedbackRequest.anonymousResponse,
        recipientId: recipientPartial.id,
        recipientPartial: recipientPartial,
        status: FeedbackRequestTokenStatus.Pending
      }

      return this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackRequestTokens`).doc(feedbackRequestToken.id).set(feedbackRequestToken)
        .then(() => {
          this.logger.info('[addFeedbackRequestTokensByFeedbackRequest]', 'Feedback request token successfully added', feedbackRequestToken);
          this.logger.timeEnd('[addFeedbackRequestTokensByFeedbackRequest]');
        })
        .catch(error => {
          this.logger.error('[addFeedbackRequestTokensByFeedbackRequest]', 'Failed to add feedback request token', error)
        })
    });
    return saveFeedbackRequestTokensPromises;
  }

  getFeedbackRequest(feedbackRequestId: string): Observable<FeedbackRequest> {
    this.logger.time('[getFeedbackRequest]');
    this.logger.info('[getFeedbackRequest]', 'Loading feedback request');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<FeedbackRequest>(`tenants/${tenantId}/feedbackRequests/${feedbackRequestId}`).valueChanges()
      ),
      tap((feedbackRequest: FeedbackRequest) => {
        this.logger.info('[getFeedbackRequest]', 'Feedback request loaded successfully', feedbackRequest);
        this.logger.timeEnd('[getFeedbackRequest]');
      })
    );
  }

  getFeedbackRequestByTokenId(feedbackRequestTokenId: string): Observable<FeedbackRequest> {
    this.logger.time('[getFeedbackRequestByTokenId]');
    this.logger.info('[getFeedbackRequestByTokenId]', 'Loading feedback request by token id');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<FeedbackRequestToken>(`tenants/${tenantId}/feedbackRequestTokens/${feedbackRequestTokenId}`)
          .valueChanges().pipe(
            map((feedbackRequestToken: FeedbackRequestToken) => ({ tenantId, feedbackRequestToken }))
          )
      ),
      switchMap(({ tenantId, feedbackRequestToken }) =>
        this.db.doc<FeedbackRequest>(`tenants/${tenantId}/feedbackRequests/${feedbackRequestToken.feedbackRequestPartial.id}`).valueChanges()
      ),
      tap((feedbackRequest: FeedbackRequest) => {
        this.logger.info('[getFeedbackRequestByTokenId]', 'Feedback request token loaded successfully', feedbackRequest);
        this.logger.timeEnd('[getFeedbackRequestByTokenId]');
      })
    );
  }

  getFeedbackRequestToken(feedbackRequestTokenId: string): Observable<FeedbackRequestToken> {
    this.logger.time('[getFeedbackRequestToken]');
    this.logger.info('[getFeedbackRequestToken]', 'Loading feedback request token');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<FeedbackRequestToken>(`tenants/${tenantId}/feedbackRequestTokens/${feedbackRequestTokenId}`).valueChanges()
      ),
      tap((feedbackRequestToken: FeedbackRequestToken) => {
        this.logger.info('[getFeedbackRequestToken]', 'Feedback request token loaded successfully', feedbackRequestToken);
        this.logger.timeEnd('[getFeedbackRequestToken]');
      })
    );
  }

  updateFeedbackRequestToken(feedbackRequestToken: FeedbackRequestToken): Observable<boolean> {
    this.logger.time('[updateFeedbackRequestToken]');
    this.logger.info('[updateFeedbackRequestToken]', 'Updating feedback request token', feedbackRequestToken);

    feedbackRequestToken.modifiedAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackType>(`tenants/${tenantId}/feedbackRequestTokens`).doc(feedbackRequestToken.id).update(feedbackRequestToken)
          .then(() => {
            this.logger.info('[updateFeedbackRequestToken]', 'Feedback request token successfully updated');
            this.logger.timeEnd('[updateFeedbackRequestToken]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateFeedbackRequestToken]', 'Failed to update feedback request token', error)
            return false;
          })
      )
    )
  }

  getFeedbackRequestTokensByRecipientId(recipientId: string): Observable<FeedbackRequestToken[]> {
    this.logger.time('[getFeedbackRequestTokensByRecipientId]');
    this.logger.info('[getFeedbackRequestTokensByRecipientId]', 'Loading feedback request tokens');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackRequestToken>(`tenants/${tenantId}/feedbackRequestTokens`, ref => ref
          .where('recipientId', '==', recipientId)
          .orderBy('createdAt', 'desc')
        ).valueChanges()
      ),
      tap((feedbackRequestTokens: FeedbackRequestToken[]) => {
        this.logger.info('[getFeedbackRequestTokensByRecipientId]', 'Feedback request tokens loaded successfully', feedbackRequestTokens);
        this.logger.timeEnd('[getFeedbackRequestTokensByRecipientId]');
      })
    );
  }

  getFeedbackRequestInList(requestRecipientId: string): Observable<FeedbackRequest[]> {

    this.logger.time('[getFeedbackRequestInList]');
    this.logger.info('[getFeedbackRequestInList]:', `Loading feedback request in list`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackRequest>(`tenants/${tenantId}/feedbackRequests`, ref => ref
          .where('recipientsIds', 'array-contains', requestRecipientId)
          .orderBy('createdAt', 'desc')
        )
          .valueChanges().pipe(
            map((feedbackRequestList: FeedbackRequest[]) => ({ tenantId, feedbackRequestList })))
      ),
      //Get request author
      switchMap(({ tenantId, feedbackRequestList }) => {
        if (feedbackRequestList.length > 0) {
          return forkJoin(feedbackRequestList.map(feedbackRequest =>
            this.db.doc<User>(`tenants/${tenantId}/users/${feedbackRequest.authorId}`).valueChanges().pipe(
              take(1),
              map((author: User) => Object.assign({}, { ...feedbackRequest, author }))
            )
          ))
        } else {
          return of(feedbackRequestList);
        }
      }),
      tap((feedbackRequestList: FeedbackRequest[]) => {
        this.logger.info('[getFeedbackRequestInList]:', 'Feedback request in list loaded', feedbackRequestList);
        this.logger.timeEnd('[getFeedbackRequestInList]');
      })

    );
  }

  getFeedbackRequestOutList(authorId: string): Observable<FeedbackRequest[]> {

    this.logger.time('[getFeedbackRequestOutList]');
    this.logger.info('[getFeedbackRequestOutList]:', `Loading feedback request out list`);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackRequest>(`tenants/${tenantId}/feedbackRequests`, ref => ref
          .where('authorId', '==', authorId)
          .orderBy('createdAt', 'desc')
        )
          .valueChanges().pipe(
            map((feedbackRequestList: FeedbackRequest[]) => ({ tenantId, feedbackRequestList }))
          )
      ),
      //Get request author 
      switchMap(({ tenantId, feedbackRequestList }) => {
        if (feedbackRequestList.length > 0) {
          return forkJoin(feedbackRequestList.map(feedbackRequest =>
            this.db.doc<User>(`tenants/${tenantId}/users/${feedbackRequest.authorId}`).valueChanges().pipe(
              take(1),
              map((author: User) => Object.assign({}, { ...feedbackRequest, author }))
            )
          ))
        } else {
          return of(feedbackRequestList);
        }
      }),

      tap((feedbackRequestList: FeedbackRequest[]) => {
        this.logger.info('[getFeedbackRequestOutList]:', 'Feedback request out list loaded', feedbackRequestList);
        this.logger.timeEnd('[getFeedbackRequestOutList]');
      })
    );
  }


  ////////////////////////////////////////////////////////////////////////
  // Feedback tags
  ////////////////////////////////////////////////////////////////////////

  addFeedbackTag(feedbackTag: FeedbackTag): Observable<boolean> {
    this.logger.time('[addFeedbackTag]');
    this.logger.info('[addFeedbackTag]', 'Adding feedback tag', feedbackTag);

    // Create doc id and createdAt
    feedbackTag.id = this.db.createId();
    feedbackTag.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackTag>(`tenants/${tenantId}/feedbackTags`).doc(feedbackTag.id).set(feedbackTag)
          .then(() => {
            this.logger.info('[addFeedbackTag]', 'Feedback tag successfully added');
            this.logger.timeEnd('[addFeedbackTag]');
            return true;
          })
          .catch(error => {
            this.logger.error('[addFeedbackTag]', 'Failed to add feedback tag', error)
            return false;
          })
      )
    )
  }

  updateFeedbackTag(feedbackTag: FeedbackTag): Observable<boolean> {
    this.logger.time('[updateFeedbackTag]');
    this.logger.info('[updateFeedbackTag]', 'Updating feedback tag', feedbackTag);

    feedbackTag.modifiedAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackTag>(`tenants/${tenantId}/feedbackTags`).doc(feedbackTag.id).update(feedbackTag)
          .then(() => {
            this.logger.info('[updateFeedbackTag]', 'Feedback tag successfully updated');
            this.logger.timeEnd('[updateFeedbackTag]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateFeedbackTag]', 'Failed to update feedback tag', error)
            return false;
          })
      )
    )
  }

  deleteFeedbackTag(feedbackTagId: string): Observable<boolean> {
    this.logger.time('[deleteFeedbackTag]');
    this.logger.info('[deleteFeedbackTag]', 'Deleting feedback tag', feedbackTagId);


    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackTag>(`tenants/${tenantId}/feedbackTags`).doc(feedbackTagId).delete()
          .then(() => {
            this.logger.info('[deleteFeedbackTag]', 'Feedback tag successfully deleted');
            this.logger.timeEnd('[deleteFeedbackTag]');
            return true;
          })
          .catch(error => {
            this.logger.error('[deleteFeedbackTag]', 'Failed to delete feedback tag', error)
            return false;
          })
      )
    )
  }

  getFeedbackTag(feedbackTagId: string): Observable<FeedbackTag> {
    this.logger.time('[getFeedbackTag]');
    this.logger.info('[getFeedbackTag]', 'Loading feedback tag', feedbackTagId);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<FeedbackTag>(`tenants/${tenantId}/feedbackTags/${feedbackTagId}`).valueChanges()
      ),
      tap((feedbackTag: FeedbackTag) => {
        this.logger.info('[getFeedbackTag]', 'Feedback tag loaded successfully', feedbackTag);
        this.logger.timeEnd('[getFeedbackTag]');
      })
    );
  }

  getFeedbackTags(): Observable<FeedbackTag[]> {
    this.logger.time('[getFeedbackTags]');
    this.logger.info('[getFeedbackTags]', 'Loading feedback tags');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackTag>(`tenants/${tenantId}/feedbackTags`, ref => ref
          .orderBy('createdAt')
        ).valueChanges()
      ),
      tap((feedbackTags: FeedbackTag[]) => {
        this.logger.info('[getFeedbackTags]', 'Feedback tags loaded successfully', feedbackTags);
        this.logger.timeEnd('[getFeedbackTags]');
      })
    );
  }

  getActiveFeedbackTags(): Observable<FeedbackTag[]> {
    this.logger.time('[getActiveFeedbackTags]');
    this.logger.info('[getActiveFeedbackTags]', 'Loading active feedback tags');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<FeedbackTag>(`tenants/${tenantId}/feedbackTags`, ref => ref
          .where('active', '==', true)
          .orderBy('createdAt')
        ).valueChanges()
      ),
      tap((feedbackTags: FeedbackTag[]) => {
        this.logger.info('[getActiveFeedbackTags]', 'Active feedback tags loaded successfully', feedbackTags);
        this.logger.timeEnd('[getActiveFeedbackTags]');
      })
    );
  }

  isFeedbackTagUsed(feedbackTagId: string): Observable<boolean> {
    this.logger.time('isFeedbackTagUsed');
    this.logger.debug('isFeedbackTagUsed:', 'checking if feedback tag is used', feedbackTagId);

    // Check based on organization statistics
    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Organization>(`tenants/${tenantId}`).valueChanges()
      ),
      map((organization: Organization) => {
        if (organization.stats && organization.stats.feedbackTagsUsed && organization.stats.feedbackTagsUsed[feedbackTagId]) {
          this.logger.debug('isFeedbackTagUsed:', 'Feedback tag used', feedbackTagId);
          this.logger.timeEnd('isFeedbackTagUsed');
          return true;
        }
        else {
          this.logger.debug('isFeedbackTagUsed:', 'Feedback tag not used', feedbackTagId);
          this.logger.timeEnd('isFeedbackTagUsed');
          return false;
        }
      })
    );
  }

  ////////////////////////////////////////////////////////////////////////
  // Suggestion
  ////////////////////////////////////////////////////////////////////////
  addSuggestion(suggestion: Suggestion): Observable<boolean> {
    this.logger.time('[addSuggestion]');
    this.logger.info('[addSuggestion]', 'Adding suggestion', suggestion);

    // Create doc id and createdAt
    suggestion.id = this.db.createId();
    suggestion.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Suggestion>(`tenants/${tenantId}/suggestions`).doc(suggestion.id).set(suggestion)
          .then(() => {
            this.logger.info('[addSuggestion]', 'Suggestion successfully added');
            this.logger.timeEnd('[addSuggestion]');
            return true;
          })
          .catch(error => {
            this.logger.error('[addSuggestion]', 'Failed to add suggestion', error)
            return false;
          })
      )
    )
  }

  getSuggestion(suggestionId: string): Observable<Suggestion> {
    this.logger.time('[getSuggestion]');
    this.logger.info('[getSuggestion]', 'Loading suggestion', suggestionId);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.doc<Suggestion>(`tenants/${tenantId}/suggestions/${suggestionId}`).valueChanges()
      ),
      tap((suggestion: Suggestion) => {
        this.logger.info('[getSuggestion]', 'Suggestion loaded successfully', suggestion);
        this.logger.timeEnd('[getSuggestion]');
      })
    );
  }


  getSuggestions(
    authUserRole: UserRole,
    authUserId: string,
    authUserTeamId: string
  ): Observable<Suggestion[]> {
    this.logger.time('[getSuggestions]');
    this.logger.info('[getSuggestions]', 'Loading suggestions');
    this.logger.info('[getSuggestions]:', `Loading suggestions with filters:`,
      'authUserRole:', authUserRole,
      'authUserId:', authUserId,
      'authUserTeamId:', authUserTeamId,
    );

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) => {

        // Show all when admin
        if (authUserRole === UserRole.Admin) {
          return this.db.collection(`tenants/${tenantId}/suggestions`, ref => ref
            .orderBy('createdAt', 'desc')
          ).valueChanges();
        }
        // Show selected
        else {

          // Concatenate different queries
          const ownSuggestionsRef = this.db.collection(`tenants/${tenantId}/suggestions`, ref =>
            ref.where('authorId', '==', authUserId)
          );
          const companySuggestionsRef = this.db.collection(`tenants/${tenantId}/suggestions`, ref =>
            ref.where('recipientType', '==', SuggestionRecipientType.Company)
          );
          const teamsSuggestionsRef = this.db.collection(`tenants/${tenantId}/suggestions`, ref =>
            ref.where('recipientType', '==', SuggestionRecipientType.Teams).where('recipientsTeamsIds', 'array-contains', authUserTeamId)
          );
          const usersSuggestionsRef = this.db.collection(`tenants/${tenantId}/suggestions`, ref =>
            ref.where('recipientType', '==', SuggestionRecipientType.Users).where('recipientsUsersIds', 'array-contains', authUserId)
          );

          return combineLatest(ownSuggestionsRef.valueChanges(), companySuggestionsRef.valueChanges(), teamsSuggestionsRef.valueChanges(), usersSuggestionsRef.valueChanges()).pipe(
            switchMap(suggestions => {
              const [ownSuggestions, companySuggestions, teamsSuggestions, usersSuggestions] = suggestions;
              let combined = ownSuggestions.concat(companySuggestions).concat(teamsSuggestions).concat(usersSuggestions);
              // Remove duplicates casued by ownSuggestions
              combined = _.uniqBy(combined, 'id');
              // Sort
              combined = _.orderBy(combined, 'createdAt', 'desc');
              return of(combined);
            })
          );
        }
      }),
      tap((suggestions: Suggestion[]) => {
        this.logger.info('[getSuggestions]', 'Suggestions loaded successfully', suggestions);
        this.logger.timeEnd('[getSuggestions]');
      })
    );
  }

  addSuggestionComment(suggestionComment: SuggestionComment): Observable<boolean> {
    this.logger.time('[addSuggestionComment]');
    this.logger.info('[addSuggestionComment]', 'Adding suggestion comment', suggestionComment);

    // Create doc id and createdAt
    suggestionComment.id = this.db.createId();
    suggestionComment.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<SuggestionComment>(`tenants/${tenantId}/suggestionComments`).doc(suggestionComment.id).set(suggestionComment)
          .then(() => {
            this.logger.info('[addSuggestionComment]', 'Suggestion comment successfully added');
            this.logger.timeEnd('[addSuggestionComment]');
            return true;
          })
          .catch(error => {
            this.logger.error('[addSuggestionComment]', 'Failed to add suggestion comment', error)
            return false;
          })
      )
    )
  }

  getSuggestionComments(suggestionId: string): Observable<SuggestionComment[]> {
    this.logger.time('[getSuggestionComments]');
    this.logger.info('[getSuggestionComments]', 'Loading suggestion comments');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<SuggestionComment>(`tenants/${tenantId}/suggestionComments`, ref => ref
          .where('suggestionId', '==', suggestionId)
          .orderBy('createdAt')
        ).valueChanges()
      ),
      tap((suggestionComments: SuggestionComment[]) => {
        this.logger.info('[getSuggestionComments]', 'Suggestion comments loaded successfully', suggestionComments);
        this.logger.timeEnd('[getSuggestionComments]');
      })
    );
  }

  addSuggestionVote(suggestionVote: SuggestionVote, authUserId: string): Observable<boolean> {
    this.logger.time('[addSuggestionVote]');
    this.logger.info('[addSuggestionVote]', 'Adding suggestion vote', suggestionVote);

    // Create doc id and createdAt
    suggestionVote.id = this.db.createId();
    suggestionVote.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<SuggestionVote>(`tenants/${tenantId}/suggestionVotes`).doc(suggestionVote.id).set(suggestionVote)
          .then(() =>
            // Update voted users on suggestion (used to disable more than one vote)
            this.db.collection<Suggestion>(`tenants/${tenantId}/suggestions`).doc(suggestionVote.suggestionId).update({
              votedUsersIds: firebase.firestore.FieldValue.arrayUnion(authUserId)
            })
              .then(() => {
                this.logger.info('[addSuggestionVote]', 'Suggestion vote successfully added');
                this.logger.timeEnd('[addSuggestionVote]');
                return true;
              })
          )
          .catch(error => {
            this.logger.error('[addSuggestionVote]', 'Failed to add suggestion vote', error)
            return false;
          })
      )
    )
  }

  getSuggestionVotes(suggestionId: string, suggestionVoteType: SuggestionVoteType): Observable<SuggestionVote[]> {
    this.logger.time('[getSuggestionVotes]');
    this.logger.info('[getSuggestionVotes]', 'Loading suggestion votes');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<SuggestionVote>(`tenants/${tenantId}/suggestionVotes`, ref => ref
          .where('suggestionId', '==', suggestionId)
          .where('type', '==', suggestionVoteType)
          .orderBy('createdAt')
        ).valueChanges()
      ),
      tap((suggestionVotes: SuggestionVote[]) => {
        this.logger.info('[getSuggestionVotes]', 'Suggestion votes loaded successfully', suggestionVotes);
        this.logger.timeEnd('[getSuggestionVotes]');
      })
    );
  }

  unsubscribeSuggestion(suggestionId: string, userId: string): Observable<boolean> {
    this.logger.time('[unsubscribeSuggestion]');
    this.logger.info('[unsubscribeSuggestion]', 'Unsubscribing suggestion', suggestionId);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Suggestion>(`tenants/${tenantId}/suggestions`).doc(suggestionId).update({
          "subscribedUsersIds": firebase.firestore.FieldValue.arrayRemove(userId)
        })
          .then(() => {
            this.logger.info('[unsubscribeSuggestion]', 'User unsubscribed from suggestion');
            this.logger.timeEnd('[unsubscribeSuggestion]');
            return true;
          })
          .catch(error => {
            this.logger.error('[unsubscribeSuggestion]', 'Failed to unsubscribe user from suggestion', error)
            return false;
          })
      )
    )
  }

  subscribeSuggestion(suggestionId: string, userId: string): Observable<boolean> {
    this.logger.time('[subscribeSuggestion]');
    this.logger.info('[subscribeSuggestion]', 'Subscribing suggestion', suggestionId);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Suggestion>(`tenants/${tenantId}/suggestions`).doc(suggestionId).update({
          "subscribedUsersIds": firebase.firestore.FieldValue.arrayUnion(userId)
        })
          .then(() => {
            this.logger.info('[subscribeSuggestion]', 'User subscribed to suggestion');
            this.logger.timeEnd('[subscribeSuggestion]');
            return true;
          })
          .catch(error => {
            this.logger.error('[subscribeSuggestion]', 'Failed to subscribe user to suggestion', error)
            return false;
          })
      )
    )
  }

  updateSuggestionStatus(suggestionId: string, status: SuggestionStatus): Observable<boolean> {
    this.logger.time('[updateSuggestionStatus]');
    this.logger.info('[updateSuggestionStatus]', 'Updating suggestion status', suggestionId);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<Suggestion>(`tenants/${tenantId}/suggestions`).doc(suggestionId).update({
          "status": status
        })
          .then(() => {
            this.logger.info('[updateSuggestionStatus]', 'Suggestion status updated');
            this.logger.timeEnd('[updateSuggestionStatus]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateSuggestionStatus]', 'Failed to update suggestion status', error)
            return false;
          })
      )
    )
  }

  ////////////////////////////////////////////////////////////////////////
  // Meeting topics
  ////////////////////////////////////////////////////////////////////////
  addMeetingTopic(meetingTopic: MeetingTopic): Observable<boolean> {
    this.logger.time('[addMeetingTopic]');
    this.logger.info('[addMeetingTopic]', 'Adding meeting topic', meetingTopic);

    // Create doc id and createdAt
    meetingTopic.id = this.db.createId();
    meetingTopic.createdAt = this.serverTimestamp;

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<MeetingTopic>(`tenants/${tenantId}/meetingTopics`).doc(meetingTopic.id).set(meetingTopic)
          .then(() => {
            this.logger.info('[addMeetingTopic]', 'Meeting topic successfully added');
            this.logger.timeEnd('[addMeetingTopic]');
            return true;
          })
          .catch(error => {
            this.logger.error('[addSuggestion]', 'Failed to add meeting topic', error)
            return false;
          })
      )
    )
  }

  getMeetingTopics(userId: string): Observable<MeetingTopic[]> {
    this.logger.time('[getMeetingTopics]');
    this.logger.info('[getMeetingTopics]', 'Loading meeting topics');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>

      //TODO: this query method can't be used as security rules can not check array-contains
      // this.db.collection<MeetingTopic>(`tenants/${tenantId}/meetingTopics`, ref => ref
      //   .where('usersIds', 'array-contains', userId)
      //   .orderBy('createdAt', 'desc')
      // ).valueChanges()

      {
        // Concatenate different queries
        const ownTopicsRef = this.db.collection<MeetingTopic>(`tenants/${tenantId}/meetingTopics`, ref => ref
          .where('authorId', '==', userId)
        );
        const othersTopicsRef = this.db.collection<MeetingTopic>(`tenants/${tenantId}/meetingTopics`, ref => ref
          .where('recipientId', '==', userId)
        );

        return combineLatest(ownTopicsRef.valueChanges(), othersTopicsRef.valueChanges()).pipe(
          switchMap(meetingTopics => {
            const [ownTopics, othersTopics] = meetingTopics;
            let combined = ownTopics.concat(othersTopics);
            // Sort
            combined = _.orderBy(combined, 'createdAt', 'desc');
            return of(combined);
          })
        );
      }

      ),
      tap((meetingTopics: MeetingTopic[]) => {
        this.logger.info('[getMeetingTopics]', 'Meeting topics loaded successfully', meetingTopics);
        this.logger.timeEnd('[getMeetingTopics]');
      })
    );
  }

  getMeetingTopicsByPeer(userId: string, peerId: string): Observable<MeetingTopic[]> {
    this.logger.time('[getMeetingTopicsByPeer]');
    this.logger.info('[getMeetingTopicsByPeer]', 'Loading meeting topics by peer');

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) => {
        // this.db.collection<MeetingTopic>(`tenants/${tenantId}/meetingTopics`, ref => ref
        //   .where('authorId', 'in', [userId, peerId])
        //   // .where('recipientId', 'in', [userId, peerId])
        //   .orderBy('createdAt', 'desc')
        // ).valueChanges()

        // Concatenate different queries
        const userTopicsRef = this.db.collection<MeetingTopic>(`tenants/${tenantId}/meetingTopics`, ref => ref
          .where('authorId', '==', userId)
          .where('recipientId', '==', peerId)
        );
        const peerTopicsRef = this.db.collection<MeetingTopic>(`tenants/${tenantId}/meetingTopics`, ref => ref
          .where('authorId', '==', peerId)
          .where('recipientId', '==', userId)
        );

        return combineLatest(userTopicsRef.valueChanges(), peerTopicsRef.valueChanges()).pipe(
          switchMap(meetingTopics => {
            const [userTopics, peerTopics] = meetingTopics;
            let combined = userTopics.concat(peerTopics);
            // Sort
            combined = _.orderBy(combined, 'createdAt', 'desc');
            return of(combined);
          })
        );

      }),
      tap((meetingTopics: MeetingTopic[]) => {
        this.logger.info('[getMeetingTopicsByPeer]', 'Meeting topics by peer loaded successfully', meetingTopics);
        this.logger.timeEnd('[getMeetingTopicsByPeer]');
      })
    );
  }

  updateMeetingTopicStatus(meetingTopicId: string, status: MeetingTopicStatus): Observable<boolean> {
    this.logger.time('[updateMeetingTopicStatus]');
    this.logger.info('[updateMeetingTopicStatus]', 'Updating meeting topic status', meetingTopicId);

    return this.store.select(fromAuth.getTenantId).pipe(
      filter(tenantId => !!tenantId),
      take(1),
      switchMap((tenantId: string) =>
        this.db.collection<MeetingTopic>(`tenants/${tenantId}/meetingTopics`).doc(meetingTopicId).update({
          "status": status
        })
          .then(() => {
            this.logger.info('[updateMeetingTopicStatus]', 'Meeting topic status updated');
            this.logger.timeEnd('[updateMeetingTopicStatus]');
            return true;
          })
          .catch(error => {
            this.logger.error('[updateMeetingTopicStatus]', 'Failed to update meeting topic status', error)
            return false;
          })
      )
    )
  }
}
