<!-- <div class="search text-muted">

  <mat-form-field class="full-width" appearance="outline">

    <input matInput placeholder="{{ 'search.search_user' | translate }}..." aria-label="szukaj" [matAutocomplete]="auto" [formControl]="searchValue">

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let user of filteredUsers" [value]="user.firstName + ' ' + user.lastName">

        <div class="user-item" (click)="onUserClicked(user.id)">
          <img class="user-avatar" [attr.src]="user.avatar" *ngIf="user.avatar; else avatarPlaceholder">

          <ng-template #avatarPlaceholder>
            <div class="user-avatar-placeholder">{{ user.firstName | firstchar}}{{ user.lastName | firstchar}}</div>
          </ng-template>

          <div>
            <div class="user-name">{{user.firstName}} {{user.lastName}}</div>
            <div class="user-jobTitle text-muted">{{user.jobTitle}}</div>
            <div class="user-jobTitle text-muted">{{user.stats?.score}}</div>
          </div>
        </div>

      </mat-option>
    </mat-autocomplete>

    <mat-icon matSuffix class="search-close" (click)="onCloseSearch()">close</mat-icon>

  </mat-form-field>

</div> -->

<div class="user-select-wrapper">

<mat-form-field class="full-width" appearance="outline">
    <mat-label>{{ 'search.search_user' | translate }}...</mat-label>

    <mat-chip-list #chipList>
      <mat-chip *ngFor="let user of selectedUsers" [selectable]="true"
              [removable]="true" (removed)="removeUserFromSelected(user)">
        {{user.firstName}} {{user.lastName}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>

    <mat-autocomplete #autoComplete>
      <mat-option *ngFor="let user of filteredUsers" [value]="user.firstName + ' ' + user.lastName">

        <!-- <div class="user-item" (click)="userClicked(user.id)" routerLink="/users/{{user.id}}"> -->
        <div class="user-item" (click)="addUserToSelected(user)">
          <img class="user-avatar" [attr.src]="user.avatar" *ngIf="user.avatar; else avatarPlaceholder">

          <ng-template #avatarPlaceholder>
            <div class="user-avatar-placeholder">{{ user.firstName | firstchar}}{{ user.lastName | firstchar}}</div>
          </ng-template>

          <div>
            <div class="user-name">{{user.firstName}} {{user.lastName}}</div>
            <div class="user-jobTitle text-muted">{{user.jobTitle}}</div>
            <div class="user-jobTitle text-muted">{{user.stats?.score}}</div>
          </div>
        </div>

      </mat-option>
    </mat-autocomplete>
  
      
    <input [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="true"
           (matChipInputTokenEnd)="resetInput($event)"
           [matAutocomplete]="autoComplete"
           [formControl]="searchValue"
           >
     <!-- <mat-icon matSuffix (click)="submit()">sentiment_very_satisfied</mat-icon> -->

  </mat-chip-list>
</mat-form-field>

<button mat-icon-button class="submit-button" (click)="submit()">
    <mat-icon>send</mat-icon>
</button>
</div>
