import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TeamSelectFormControlComponent } from './components/team-select-form-control/team-select-form-control.component';
import { UserSelectFormControlComponent } from './components/user-select-form-control/user-select-form-control.component';
import { FeedbackKindIconComponent } from './components/feedback-kind-icon/feedback-kind-icon.component';
import { UserAnonymousAvatarComponent } from './components/user-anonymous-avatar/user-anonymous-avatar.component';
import { UserAvatarWithInfoComponent } from './components/user-avatar-with-info/user-avatar-with-info.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackListComponent } from './components/feedback-list/feedback-list.component';
import { FeedbackListItemComponent } from './components/feedback-list-item/feedback-list-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReversePipe } from './pipes/reverse.pipe';
import { RouterModule } from '@angular/router';
import { FirstCharPipe } from './pipes/first-char.pipe';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { TranslateModule } from '@ngx-translate/core';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { FeedbackTypeIconComponent } from './components/feedback-type-icon/feedback-type-icon.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    MatTooltipModule,
    MatRadioModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatBadgeModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    TranslateModule.forChild()
  ],
  declarations: [
    FeedbackListComponent,
    FeedbackListItemComponent,
    ReversePipe,
    FirstCharPipe,
    PageNotFoundComponent,
    PageHeaderComponent,
    SvgIconComponent,
    FeedbackTypeIconComponent,
    UserAvatarComponent,
    UserAvatarWithInfoComponent,
    UserAnonymousAvatarComponent,
    FeedbackKindIconComponent,
    UserSelectFormControlComponent,
    TeamSelectFormControlComponent
  ],
  providers: [
  ],
  exports: [
    FeedbackListComponent,
    FirstCharPipe,
    ReversePipe,
    PageHeaderComponent,
    SvgIconComponent,
    FeedbackTypeIconComponent,
    UserAvatarComponent,
    UserAvatarWithInfoComponent,
    UserAnonymousAvatarComponent,
    UserSelectFormControlComponent,
    TeamSelectFormControlComponent
  ]
})
export class SharedModule { }