import { SuggestionStatus } from '../../../../../../shared/model/suggestion-status.enum';
import { UserRole } from '../../../../../../shared/model/user-role.enum';
import { TeamPartial } from '../../../../../../shared/model/team-partial.interface';
import { SuggestionStats } from '../../../../../../shared/model/suggestion-stats.interface';
import { Suggestion } from '../../../../../../shared/model/suggestion.interface';
import { SuggestionRecipientType } from '../../../../../../shared/model/suggestion-recipient-type.enum';
import { Team } from '../../../../../../shared/model/team.interface';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { User } from '../../../../../../shared/model/user.interface';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { take, map } from 'rxjs/operators';
import { UserPartial } from '../../../../../../shared/model/user-partial.interface';


@Component({
  selector: 'app-suggestion-form',
  templateUrl: './suggestion-form.component.html',
  styleUrls: ['./suggestion-form.component.scss'],
})
export class SuggestionFormComponent implements OnInit {

  @Input()
  author: User;

  @Output()
  cancel = new EventEmitter();

  @Output()
  submit = new EventEmitter();

  submitDisabled: boolean = false;


  suggestionAuthorGroup: FormGroup;
  suggestionDescriptionGroup: FormGroup;
  suggestionRecipientTypeEnum = SuggestionRecipientType;
  suggestionRecipientsGroup: FormGroup;
  // suggestionRecipientUsersGroup: FormGroup;
  // suggestionRecipientTeamsGroup: FormGroup;

  dynamicValidationSub: Subscription;

  isSugestionVisibleForAuthor: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private dataProvider: DataProviderService
  ) { }


  ngOnInit() {
    this.createSuggestionForm();
  }
  ngOnDestroy() {
    this.dynamicValidationSub.unsubscribe();
  }


  createSuggestionForm() {
    this.suggestionAuthorGroup = this.formBuilder.group({
      anonymousAuthor: [false, Validators.required],
    });

    this.suggestionDescriptionGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.suggestionRecipientsGroup = this.formBuilder.group({
      suggestionRecipientType: [SuggestionRecipientType.Company, Validators.required],
      suggestionRecipientUsers: [''],
      suggestionRecipientTeams: [''],
    });

    // Dynamic validations
    const suggestionRecipientUsersControl = this.suggestionRecipientsGroup.get('suggestionRecipientUsers');
    const suggestionRecipientTeamsControl = this.suggestionRecipientsGroup.get('suggestionRecipientTeams');
    this.dynamicValidationSub = this.suggestionRecipientsGroup.get('suggestionRecipientType').valueChanges
      .subscribe(suggestionRecipientType => {
        switch (suggestionRecipientType) {
          case SuggestionRecipientType.Company:
            suggestionRecipientUsersControl.setValidators(null);
            suggestionRecipientTeamsControl.setValidators(null);
            suggestionRecipientUsersControl.reset();
            suggestionRecipientTeamsControl.reset();
            suggestionRecipientUsersControl.updateValueAndValidity();
            suggestionRecipientTeamsControl.updateValueAndValidity();
            break;
          case SuggestionRecipientType.Teams:
            suggestionRecipientUsersControl.setValidators(null);
            suggestionRecipientTeamsControl.setValidators([Validators.required]);
            suggestionRecipientUsersControl.reset();
            suggestionRecipientTeamsControl.reset();
            suggestionRecipientUsersControl.updateValueAndValidity();
            suggestionRecipientTeamsControl.updateValueAndValidity();
            break;
          case SuggestionRecipientType.Users:
            suggestionRecipientUsersControl.setValidators([Validators.required]);
            suggestionRecipientTeamsControl.setValidators(null);
            suggestionRecipientUsersControl.reset();
            suggestionRecipientTeamsControl.reset();
            suggestionRecipientUsersControl.updateValueAndValidity();
            suggestionRecipientTeamsControl.updateValueAndValidity();
            break;
        }
      });
  }

  onSubmit() {
    this.submitDisabled = true;

    const anonymousAuthor = this.suggestionAuthorGroup.value.anonymousAuthor;
    const authorId = anonymousAuthor ? '' : this.author.id;
    const authorPartial: UserPartial = anonymousAuthor ? null : {
      id: this.author.id,
      firstName: this.author.firstName,
      lastName: this.author.lastName,
      email: this.author.email,
      teamId: this.author.teamId,
      jobTitle: this.author.jobTitle,
      avatar: this.author.avatar,
    }

    const title = this.suggestionDescriptionGroup.value.title;
    const description = this.suggestionDescriptionGroup.value.description;

    const recipientType = this.suggestionRecipientsGroup.value.suggestionRecipientType;
    const recipientsUsersIds: string[] = [];
    const recipientsUsersPartials: UserPartial[] = [];
    const recipientsTeamsIds: string[] = [];
    const recipientsTeamsPartials: TeamPartial[] = [];

    switch (recipientType) {
      case SuggestionRecipientType.Users:
        this.suggestionRecipientsGroup.value.suggestionRecipientUsers.map((suggestionRecipientUser: User) => {
          recipientsUsersIds.push(suggestionRecipientUser.id);
          recipientsUsersPartials.push({
            id: suggestionRecipientUser.id,
            firstName: suggestionRecipientUser.firstName,
            lastName: suggestionRecipientUser.lastName,
            email: suggestionRecipientUser.email,
            teamId: suggestionRecipientUser.teamId,
            jobTitle: suggestionRecipientUser.jobTitle,
            avatar: suggestionRecipientUser.avatar,
          });
        });
        break;
      case SuggestionRecipientType.Teams:
        this.suggestionRecipientsGroup.value.suggestionRecipientTeams.map((suggestionRecipientTeam: Team) => {
          recipientsTeamsIds.push(suggestionRecipientTeam.id);
          recipientsTeamsPartials.push({
            id: suggestionRecipientTeam.id,
            name: suggestionRecipientTeam.name,
            description: suggestionRecipientTeam.description,
          })
        })
        break;
    }

    const stats: SuggestionStats = {
      upvotes: 0,
      downvotes: 0,
      comments: 0
    }

    const suggestion: Suggestion = {
      anonymousAuthor: anonymousAuthor,
      authorId: authorId,
      authorPartial: authorPartial,
      title: title,
      description: description,
      recipientType: recipientType,
      recipientsUsersIds: recipientsUsersIds,
      recipientsUsersPartials: recipientsUsersPartials,
      recipientsTeamsIds: recipientsTeamsIds,
      recipientsTeamsPartials: recipientsTeamsPartials,
      stats: stats,
      votedUsersIds: [],
      subscribedUsersIds: anonymousAuthor ? [] : [this.author.id],
      status: SuggestionStatus.Open
    }

    this.saveSuggestion(suggestion);
  }

  onCancel() {
    this.cancel.emit();
  }

  saveSuggestion(suggestion: Suggestion) {
    // console.log('!!!!suggestion', suggestion)
    this.dataProvider.addSuggestion(suggestion).pipe(
      take(1),
      map((result: boolean) => {
        if (result) {
          this.submit.emit();
        }
      })
    ).subscribe();
  }

  // Show warning if suggestion won't be visible for anonymous author (author not in selected teams or users)
  checkIfSugestionVisibleForAuthor() {
    if (this.suggestionAuthorGroup.value.anonymousAuthor && this.author.orgRole !== UserRole.Admin) {

      let isVisible = false;

      switch (this.suggestionRecipientsGroup.value.suggestionRecipientType) {
        case SuggestionRecipientType.Company:
          isVisible = true;
          break;
        case SuggestionRecipientType.Teams:
          this.suggestionRecipientsGroup.value.suggestionRecipientTeams.map((suggestionRecipientTeam: Team) => {
            if (suggestionRecipientTeam.id === this.author.teamId) {
              isVisible = true;
            }
          })
          break;
        case SuggestionRecipientType.Users:
          this.suggestionRecipientsGroup.value.suggestionRecipientUsers.map((suggestionRecipientUser: User) => {
            if (suggestionRecipientUser.id === this.author.id) {
              isVisible = true;
            }
          });
          break;
        }
        this.isSugestionVisibleForAuthor = isVisible;
    }
    else {
      this.isSugestionVisibleForAuthor = true;
    }
  }

}
