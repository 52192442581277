import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {

  @Input()
  icon: string;

  svg: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.svg = this.sanitizer.bypassSecurityTrustHtml(this.icon);
  }

}
