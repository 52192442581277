import { Observable, Subscription, fromEvent } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-notification-pwa-offline',
  templateUrl: './notification-pwa-offline.component.html',
  styleUrls: ['./notification-pwa-offline.component.scss']
})
export class NotificationPwaOfflineComponent implements OnInit {

  showOfflineNotification = false;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit() {

    // Get the online/offline status from browser window
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.showOfflineNotification = false;
    }));
    
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.showOfflineNotification = true;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
