<div class="row">
  <div class="col">

    <div class="feedback card-panel">
      <!--Feedback header-->
      <div class="row">
        <div class="col">
          <div class="feedback-header">

            <!--Feedback type-->
            <app-feedback-type-icon [feedbackType]="feedback.feedbackType" *ngIf="!isLoading && feedback?.feedbackType">
            </app-feedback-type-icon>
            <ng-template #feedbackTypePlaceholder>
              <div class="feedback-type-placeholder placeholder"></div>
            </ng-template>

            <div class="arrow" *ngIf="!isLoading && feedback?.feedbackType">
              <i class="material-icons">chevron_right</i>
            </div>

            <div class="feedback-kind-with-user" *ngIf="!isLoading; else userPlaceholder">
              <!-- Feedback kind icon -->
              <app-feedback-kind-icon [feedbackKind]="feedback.kind" class="feedback-kind-icon">
              </app-feedback-kind-icon>

              <!-- User avatar with info -->
              <app-user-avatar-with-info [user]="feedback.user"></app-user-avatar-with-info>
            </div>
            <ng-template #userPlaceholder>
              <app-user-avatar-with-info [isLoading]="isLoading"></app-user-avatar-with-info>
            </ng-template>

            <!-- Private info icon -->
            <i class="material-icons feedback-private text-muted"
              matTooltip="{{ 'shared.feedback.private_info' | translate }}"
              *ngIf="!isLoading && feedback.visibility===feedbackVisibilityType.Private">lock</i>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="feedback-author" *ngIf="!isLoading; else authorPlaceholder">
            {{ 'shared.feedback.from' | translate }}
            <a routerLink="/users/{{feedback.authorId}}">{{ feedback.author.firstName }}
              {{ feedback.author.lastName }}</a>:
          </div>
          <ng-template #authorPlaceholder>
            <!-- <div class="author-name-placeholder placeholder"></div> -->
          </ng-template>
        </div>
      </div>

      <!--Feedback comment-->
      <div class="row">
        <div class="col">
          <div class="feedback-comment" *ngIf="!isLoading; else commentPlaceholder">
            {{ feedback.comment }}
          </div>
          <ng-template #commentPlaceholder>
            <div class="comment-placeholder placeholder"></div>
          </ng-template>
        </div>
      </div>

      <!--Feedback tags-->
      <div class="row">
        <div class="col">
          <div class="feedback-tags" *ngIf="!isLoading;">
            <mat-chip-list>
              <mat-chip *ngFor="let feedbackTag of feedback.tagsPartials">
                <app-svg-icon [icon]="feedbackTag.icon"></app-svg-icon>
                {{ feedbackTag.name }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>

      <!--Feedback footer-->
      <div class="row">
        <div class="col">
          <div class="feedback-footer">

            <!-- Time -->
            <div class="feedback-time" *ngIf="!isLoading; else timePlaceholder">
              <span *ngIf="feedback.createdAt" class="text-muted">
                {{ feedback.createdAt.toDate() | date:'short':'':dateLocale }}
              </span>
            </div>
            <ng-template #timePlaceholder>
              <div class="time-placeholder placeholder"></div>
            </ng-template>


            <!-- Action buttons -->
            <div class="feedback-actions" *ngIf="!isLoading && (feedback.userId === authUser.id || feedback.authorId === authUser.id)">
                <button mat-button matTooltip="{{ 'meetings.lets_meet_button.tooltip' | translate }}"
                  matTooltipPosition="above" (click)="onLetsMeetClick()" [disabled]="isLetsMeetButtonDisabled">
                  <i class="material-icons">local_cafe</i> {{ 'meetings.lets_meet_button.label' | translate }}
                </button>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>