import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DataProviderService } from '../../../shared/services/data-provider.service';
import { User } from '../../../../../../shared/model/user.interface';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { searchUsers } from 'app/shared/helpers/search-users';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Output()
  closeSearch: EventEmitter<any> = new EventEmitter();

  users: User[] = [];
  filteredUsers: User[] = [];
  searchValue = new FormControl();


  constructor(
    private router: Router,
    private dataProvider: DataProviderService,

  ) { }

  ngOnInit() {

    // Load users
    this.dataProvider.getActiveUsers().pipe(
      map((users: User[]) => {
        this.users = users;
        this.filteredUsers = users;
      })
    ).subscribe();

    // Listen for search value changes
    this.searchValue.valueChanges.pipe(
      // tap(value => console.log('value', value)),
      // startWith(''),
      map(value => {
        if (value) {
          // this.filteredUsers = search(this.filteredUsers, value.trim().toLowerCase());;
          this.filteredUsers = searchUsers(this.users, value);
        } else {
          this.filteredUsers = this.users;
        }
      })
    ).subscribe();
  }

  onCloseSearch() {
    this.closeSearch.emit();
  }

  onUserClicked(userId: string){
    this.closeSearch.emit();
    this.router.navigate(['/users', userId]);
  }

}

