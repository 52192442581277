
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { DataProviderService } from '../../../shared/services/data-provider.service';
import { FeedbackType } from '../../../../../../shared/model/feedback-type.interface';

import {
    FeedbackTypesActionTypes,
    LoadFeedbackTypes,
    LoadFeedbackTypesSuccess,
    LoadFeedbackTypesFailure
} from './../actions/feedback-types.actions';

import { switchMap, mergeMap, map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class FeedbackTypesEffects {

    constructor(
        private actions: Actions,
        private dataProvider: DataProviderService
    ) { }

    @Effect()
    loadFeedbackTypes: Observable<Action> = this.actions.pipe(
        ofType(FeedbackTypesActionTypes.LoadFeedbackTypes),
        switchMap((action: LoadFeedbackTypes) =>
            this.dataProvider.getActivePositiveFeedbackTypes().pipe(
                // tap((feedbackTypes: FeedbackType[]) => console.log(feedbackTypes)),
                map((feedbackTypes: FeedbackType[]) => new LoadFeedbackTypesSuccess(feedbackTypes)),
                // catchError(error => observableOf(new LoadFeedbackTypesFailure(error)))
                catchError(error => {
                    console.error(error);
                    return observableOf(new LoadFeedbackTypesFailure(error));
                })
            )
        )
    );



}