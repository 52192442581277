import { NotificationPwaModule } from './notification-pwa/notification-pwa.module';
import { BotModule } from './bot/bot.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { routes } from './app.routing';

import { environment } from '../environments/environment';
const firebaseConfig = environment.firebaseConfig;

import { NgLoggerModule, Level } from '@nsalaun/ng-logger';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, routerReducer, RouterStateSerializer } from '@ngrx/router-store';


// import { AuthService } from './shared/auth.service';
import { AuthService } from './auth/services/auth.service';
import { AuthGuard } from './auth/services/auth-guard.service';


import { MessageBarService } from './shared/message-bar.service';

import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { CustomRouterSerializer } from './shared/helpers/custom-router-serializer.class';
import { NotificationModule } from './notification/notification.module';
import { DataProviderService } from './shared/services/data-provider.service';
import { RedirectIfLoggedInGuard } from './auth/services/redirect-if-logged-in-guard.service';
import { NotificationService } from './notification/services/notification.service';
import { LayoutModule } from './layout/layout.module';
import { MatSidenavModule } from '@angular/material/sidenav';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';


// Import locale required for DatePipe
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { ServiceWorkerModule } from '@angular/service-worker';
registerLocaleData(localePl, 'pl');


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(
      routes,
      // { enableTracing: true } // <-- debugging purposes only
    ),
    MatSidenavModule,

    AngularFireModule.initializeApp(firebaseConfig),
    // AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,

    // SharedModule, 
    AuthModule,
    LayoutModule,
    BotModule,

    NotificationModule,
    NotificationPwaModule,

    NgLoggerModule.forRoot(environment.loggingLevel), //ustawianie poziomu loggera w zmiennych environment

    StoreModule.forRoot({
      router: routerReducer
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router' // name of reducer key
    }),
    // !environment.production ? StoreDevtoolsModule.instrument() : [],
    environment.enableReduxDevTools ? StoreDevtoolsModule.instrument() : [],

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorker })
    
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    DataProviderService,
    AuthService,
    AuthGuard,
    RedirectIfLoggedInGuard,
    NotificationService,
    MessageBarService,
    // Ngrx router serializer
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterSerializer
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
