<div class="row justify-content-center align-items-center login-password-reset-wrapper">
  <div class="col-md-8 col-lg-5 text-center">

    <img src="/assets/img/icon.svg" alt="" class="logo-icon">

    <div class="login-panel card-panel">
      <div class="login-title">{{ 'auth.enter_new_password' | translate }}</div>

      <ng-container *ngIf="showLoader">
        <div class="row">
          <div class="col">
            <div class="loader">
              <mat-spinner diameter="50" ></mat-spinner>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showForm">
        <form [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()" novalidate>
          <div class="row">
            <div class="col">
              <div class="password-policy">
                {{ 'auth.password_policy' | translate }}
              </div>

              <mat-form-field class="full-width">
                <input matInput type="password" formControlName="newPassword"
                  placeholder="{{ 'auth.new_password' | translate }}" required>
                <!--Required error-->
                <mat-hint class="error"
                  *ngIf="newPasswordForm.get('newPassword').hasError('required') && newPasswordForm.get('newPassword').touched"
                  align="start">
                  {{ 'auth.password_required' | translate }}
                </mat-hint>
                <!--Password policy-->
                <mat-hint class="error"
                  *ngIf="!newPasswordForm.get('newPassword').hasError('required') && newPasswordForm.get('newPassword').hasError('pattern') && newPasswordForm.get('newPassword').touched"
                  align="start">
                  {{ 'auth.password_invalid' | translate }}
                </mat-hint>
              </mat-form-field>

            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <button type="submit" mat-flat-button color="primary" class="login-button"
                [disabled]="!newPasswordForm.valid">
                {{ 'auth.set_password_button' | translate }}
              </button>
            </div>
          </div>

        </form>
      </ng-container>

      <ng-container *ngIf="showSuccessMessage">
        <div>{{ 'auth.set_password_success' | translate }}</div>
        <!-- <button mat-flat-button color="primary" routerLink="/login" class="login-button">
          {{ 'auth.go_to_login_page' | translate }}
        </button> -->
      </ng-container>

      <ng-container *ngIf="showInvalidCodeMessage">
        <div class="row">
          <div class="col text-center">
            <div class="error">
              {{ 'auth.invalid_password_reset_code' | translate }}
            </div>
            <button mat-flat-button color="primary" routerLink="/login/password-reset-request" class="login-button">
              {{ 'auth.go_to_password_reset_request' | translate }}
            </button>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>