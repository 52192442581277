<button mat-fab color="primary" (click)="openDialog()" class="bot-open-dialog-button" *ngIf="!isDialogOpen">
  <div class="bot-button-icon">
    <img src="/assets/img/icon.svg">
  </div>
</button>


<div class="bot-dialog-wrapper" *ngIf="isDialogOpen">

  <div class="bot-close-dialog-header">
    <button mat-icon-button (click)="closeDialog()" *ngIf="isDialogOpen">
      <i class="material-icons">close</i>
    </button>
  </div>

  <app-bot [eventType]="eventType"> </app-bot>
</div>