import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';

// import { Store, select } from '@ngrx/store';
// import * as fromAuth from '../../../auth/store/reducers/auth.reducer';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  showBot: boolean;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    // On first page load
    this.checkIfHideBot(this.router.url);

    // On url change
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkIfHideBot(this.router.url);
      }
    })
  }

  checkIfHideBot(url: string) {
    // Don't show bot on admin pages or bot page
    if (url.includes('admin') || url.includes('bot')) {
      this.showBot = false;
    } else {
      this.showBot = true;
    }
  }

}
