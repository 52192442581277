import { FeedbackRequestToken } from './../../../../../../shared/model/feedback-request-token.interface';
import { FeedbackRequest } from './../../../../../../shared/model/feedback-request.interface';
import { DataProviderService } from './../../../shared/services/data-provider.service';
import { BotEventType } from '../../../../../../shared/model/bot-event-type.enum';
import { BotService } from './../../services/bot.service';
import { BotActionType } from './../../../../../../shared/model/bot-action-type.enum';
import { BotAction } from './../../../../../../shared/model/bot-action.interface';
import { BotMessage } from './../../../../../../shared/model/bot-message.interface';
import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { take, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss']
})
export class BotComponent implements OnInit {

  @Input()
  eventType?: BotEventType;

  @Input()
  requestToken?: string;

  messages: Observable<BotMessage[]>;
  actions: Observable<BotAction[]>;
  isMessageLoading: Observable<boolean>;

  botActionType = BotActionType;

  constructor(
    private botService: BotService,
    private dataProvider: DataProviderService
  ) { }

  ngOnInit() {
    // Clear previous messages and actions
    this.botService.clearMesages();
    this.botService.clearActions();

    // Show messages and actions
    this.messages = this.botService.messages;
    this.actions = this.botService.actions;
    this.isMessageLoading = this.botService.isMessageLoading;

    switch (this.eventType) {
      case BotEventType.ResponseFeedback:
        // Check if proper parameters are set
        if (this.eventType && this.requestToken) {
          this.dataProvider.getFeedbackRequestToken(this.requestToken).pipe(
            take(1),
            map((feedbackRequestToken: FeedbackRequestToken) => this.botService.triggerResponseFeedbackIntent(feedbackRequestToken.id, feedbackRequestToken.authorPartial.id))
          ).subscribe();
        } else {
          this.botService.triggerWelcomeIntent();
        }
        break;
      default:
        this.botService.triggerWelcomeIntent();
        break;
    }

  }

  onActionMessage(message: BotMessage) {
    this.botService.converse(message);
    this.botService.clearActions();
  }



}
