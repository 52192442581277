import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-notification-pwa-update',
  templateUrl: './notification-pwa-update.component.html',
  styleUrls: ['./notification-pwa-update.component.scss']
})
export class NotificationPwaUpdateComponent implements OnInit {

  showUpdateNotification = false;
  subscriptions: Subscription[] = [];

  constructor(
    private swUpdate: SwUpdate,
  ) { }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.subscriptions.push(this.swUpdate.available.subscribe(event => {
        this.showUpdateNotification = true;
      }));

      // Periodic update check every 5 min
      setInterval(() => {
        this.swUpdate.checkForUpdate();
      }, 5000);

    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onUpdate() {
    window.location.reload();
  }
}
