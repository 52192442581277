<div class="cm-notification-list">

    <div class="cm-notification mat-elevation-z8" *ngFor="let notification of notifications | async">
        <!-- <mat-progress-bar *ngIf="notification.autoClose" [value]="notification.autoCloseProgress"></mat-progress-bar> -->

        <div class="cm-notification-body">
            <mat-icon class="cm-notification-icon cm-notification-success" color="primary" *ngIf="notification.type === 'success'">check_circle</mat-icon>
            <mat-icon class="cm-notification-icon cm-notification-error" color="warn" *ngIf="notification.type === 'error'">error</mat-icon>
            <mat-icon class="cm-notification-icon cm-notification-info" color="accent" *ngIf="notification.type === 'info'">info</mat-icon>
            <div class="cm-notification-msg">
                {{ notification.message | upperfirst }}
            </div>
            <!-- {{ notification | json }} -->
            <button *ngIf="!notification.autoClose" mat-icon-button class="cm-notification-close" (click)="onDismissNotification(notification.id)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>