import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, from } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Logger } from '@nsalaun/ng-logger';
import { User } from '../../../../../shared/model/user.interface';


@Injectable()
export class AuthService {

    // Store the URL so we can redirect after logging in
    redirectUrl: string;

    // Password policy: Should be at least 9 characters long and should have 1 lowercase letter, 1 uppercase letter, 1 number or 1 special character (e.g. !, $, #, %)  
    // passwordPolicyRegex: RegExp = /^.*(?=.{9,})((?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/ ;
    // passwordPolicyRegex: RegExp = /^.*(?=.{9,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]).*$/ ;
    passwordPolicyRegex: RegExp = /^.*(?=.{9,})(?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])).*$/;


    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private logger: Logger
    ) { }


    loginWithEmail(email: string, password: string): Observable<firebase.auth.UserCredential> {
        return from(this.afAuth.auth.signInWithEmailAndPassword(email, password));
    }

    logout(): Promise<any> {
        // Clear local persistance if enabled in app.module.ts
        // localStorage.clear();
        // const dbs = await window.indexedDB.databases()
        // dbs.forEach(db => { window.indexedDB.deleteDatabase(db.name) })
        return this.afAuth.auth.signOut();
    };

    // clearLocalPersistance(): Promise<any> {
    //     return firebase.firestore().clearPersistence().catch(error => {
    //         console.error('Could not enable persistence:', error.code);
    //     })
    // }

    changePassword(firebaseUser: firebase.User, currentPassword: string, newPassword: string): Promise<boolean> {
        this.logger.debug('changePassword:', 'changing password');

        var res: Promise<boolean> = new Promise((resolve, reject) => {

            // User credential
            const credential = firebase.auth.EmailAuthProvider.credential(
                firebaseUser.email,
                currentPassword
            );

            // Reauthenticate user
            firebaseUser.reauthenticateAndRetrieveDataWithCredential(credential)
                .then(result => {
                    this.logger.debug('User reauthenticated');

                    // Change password
                    firebaseUser.updatePassword(newPassword)
                        .then(result => this.logger.debug('Password changed'))
                        .catch(error => this.logger.error('Failed to change password', error));

                    // resolve(result);
                    resolve(true);
                })
                .catch(error => {
                    this.logger.error('Failed to reauthenticate user', error);
                    reject(error);
                });

        });
        return res;
    }

    sendPasswordResetEmail(email: string): Promise<boolean> {
        this.logger.debug('Sending password reset email');

        //Send email
        // firebase.auth().sendPasswordResetEmail(email)
        //     .then(success => {
        //         this.logger.debug('Password reset email sent');
        //     }, error => {
        //         this.logger.debug('Failed to send password reset email');
        //     });


        const firebaseFunction = firebase.functions().httpsCallable('sendPasswordResetLink');
        return firebaseFunction(email)
            .then((response: firebase.functions.HttpsCallableResult) => {
                this.logger.debug('Password reset email sent', response);
                return true;
            }, error => {
                this.logger.debug('Failed to send password reset email', error);
                return false;
            })
    }

    isPasswordResetCodeValid(code: string): Promise<string> { //returns email if success
        return firebase.auth().verifyPasswordResetCode(code)
            .then(success => {
                this.logger.debug('Password reset code is valid', success);
                return success;
            }, error => {
                this.logger.debug('Password reset code is not valid', error);
                return '';
            })
    }

    resetPassword(actionCode: string, newPassword: string): Promise<boolean> {
        return firebase.auth().confirmPasswordReset(actionCode, newPassword)
            .then(success => {
                this.logger.debug('New password set');
                return true;
            }, error => {
                this.logger.debug('Failed to set new password', error);
                return false;
            })
    }

    // reauthenticate(email: string, password: string) {
    //     // User credential
    //     const credential = firebase.auth.EmailAuthProvider.credential(
    //         email,
    //         password
    //     );

    //     firebase.auth().signInWithCredential()

    //     // Reauthenticate user
    //     firebaseUser.reauthenticateAndRetrieveDataWithCredential(credential)
    //         .then(result => {
    //             this.logger.debug('User reauthenticated');

    //             // Change password
    //             firebaseUser.updatePassword(newPassword)
    //                 .then(result => this.logger.debug('Password changed'))
    //                 .catch(error => this.logger.error('Failed to change password', error));

    //             // resolve(result);
    //             resolve(true);
    //         })
    //         .catch(error => {
    //             this.logger.error('Failed to reauthenticate user', error);
    //             reject(error);
    //         });
    // }

}
