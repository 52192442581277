<div class="row justify-content-center align-items-center login-page">
  <div class="col-md-8 col-lg-5 text-center">
    
    <img src="/assets/img/icon.svg" alt="" class="logo-icon">
  
    <div class="login-panel card-panel">
      <div class="login-title">{{ 'auth.login_title' | translate }}</div>
      <app-login-form (submit)="onSubmit($event)"></app-login-form>

      <div class="row" *ngIf="loginError">
        <div class="col text-center login-failed">
          {{ loginError }}
        </div>
      </div>

      <!-- <mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar> -->
    </div>

    <div class="login-pass-reset">
      <a routerLink="/login/password-reset-request">{{ 'auth.forgot_password' | translate }}</a>
    </div>
  </div>
</div>