import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from '../model/notification.model';
import { Subject } from 'rxjs';



@Injectable()
export class NotificationService {

    //TODO:
    // show only x notification at once

    notifications: Subject<Notification[]> = new Subject<Notification[]>();
    notificationsArray: Notification[] = [];
    id: number = 1;
    autoCloseTime: number = 2500;

    constructor(
    ) { }

    public showNotification(message: string, type?: 'info' | 'success' | 'error', autoClose?: boolean, autoCloseTime?: number) {
        
        let id = this.id;
        
        let notification = new Notification(id, message, type, autoClose, autoCloseTime);
        this.notificationsArray.unshift(notification);
        this.notifications.next(this.notificationsArray);
        this.id++;
        
        if (autoClose){
            setTimeout(() => {this.dismissNotification(id)}, this.autoCloseTime);
            // setTimeout(() => {this.startCounter(id)}, 0);
        }
        
    }


    getNotifications(): Observable<Notification[]> {
        return this.notifications;
    }


    dismissNotification(notificationId: number) {
        this.notificationsArray = this.notificationsArray.filter(item => item.id !== notificationId); 
        this.notifications.next(this.notificationsArray);
    }

    // startCounter(notificationId: number){
    //     let index = this.notificationsArray.findIndex(item => item.id == notificationId);
    //     this.updateAutoCloseProgress(index);
    // }
    
    // updateAutoCloseProgress(index: number){

    //     let updateInterval = 1 //ms
    //     while (this.notificationsArray[index].autoCloseProgress < 100) {
    //         console.log(this.notificationsArray[index].autoCloseProgress);
    //         this.notificationsArray[index].autoCloseProgress = this.notificationsArray[index].autoCloseProgress + updateInterval/this.notificationsArray[index].autoCloseTime*100;
    //         setTimeout(() => {this.updateAutoCloseProgress(index)}, updateInterval);
    //     }
    // }
        

}
