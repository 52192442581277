import { Component, OnInit } from '@angular/core';
import { Credentials } from '../../models/auth.models';
import { Observable, of } from 'rxjs';

import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../store/reducers/auth.reducer';
// import * as AuthActions from '../../store/actions/auth.actions';

import {
  LoginFailure,
  LoginSuccess,
  LoginRedirect,
  LoginWithEmail
} from '../../store/actions/auth.actions';

import { AuthService } from '../../services/auth.service';
import { catchError, map, take, switchMap, filter, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  showProgress: boolean = false;
  loginError: string;

  constructor(
    private store: Store<fromAuth.State>,
    private authService: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    // Get login error message from store
    this.store.select(fromAuth.getLoginError).pipe(
      filter(error => !!error),
      map(error => {
        switch (error.code) {
          case 'auth/wrong-password':
            this.loginError = this.translate.instant('auth.wrong_email_password');
            break;
            case 'auth/user-disabled':
            this.loginError = this.translate.instant('auth.user_locked');
            break;
            case 'auth/too-many-requests':
            this.loginError = this.translate.instant('auth.too_many_requests');
            break;
            default:
            this.loginError = this.translate.instant('auth.wrong_email_password');
            break;
        }
      }),
    ).subscribe();
  }

  onSubmit(credentials: Credentials) {
    // this.store.dispatch(new LoginRedirect('/feed'));
    this.store.dispatch(new LoginWithEmail(credentials.email, credentials.password));
  }
}
