<div class="row justify-content-center align-items-center login-password-reset-wrapper">
  <div class="col-md-8 col-lg-5 text-center">

    <img src="/assets/img/icon.svg" alt="" class="logo-icon">

    <div class="login-panel card-panel">
      <div class="login-title">{{ 'auth.enter_email_to_reset' | translate }}</div>

      <ng-container *ngIf="showForm">
        <form [formGroup]="resetForm" (ngSubmit)="onSubmit()" novalidate>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput type="email" formControlName="email" placeholder="{{ 'auth.email' | translate }}"
                  required>
                <!--Required error-->
                <mat-hint class="error"
                  *ngIf="resetForm.get('email').hasError('required') && resetForm.get('email').touched" align="start">
                  {{ 'auth.email_required' | translate }}</mat-hint>
                <!--Wrong email error-->
                <mat-hint class="error"
                  *ngIf="!resetForm.get('email').hasError('required') && resetForm.get('email').hasError('email') && resetForm.get('email').touched"
                  align="start">{{ 'auth.email_invalid' | translate }}</mat-hint>
              </mat-form-field>
            </div>
          </div>


          <div class="row">
            <div class="col text-center">
              <button type="submit" mat-flat-button color="primary" class="login-button" [disabled]="!resetForm.valid">
                {{ 'auth.reset_button' | translate }}
              </button>
            </div>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="showLoader">
        <div class="row">
          <div class="col">
            <div class="loader">
              <mat-spinner diameter="50"></mat-spinner>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showSuccessMessage">
        <div class="row">
          <div class="col text-center">
            <div class="msg">
              {{ 'auth.reset_email_sent' | translate }}
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showErrorMessage">
        <div class="row">
          <div class="col text-center">
            <div class="msg">
              {{ 'auth.reset_email_error' | translate }}
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>