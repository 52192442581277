export function searchUsers(objectArray, query) {
    query = query.trim().toLowerCase();
    // let quickResult = objectArray.filter(obj => (<any>Object).values(obj).some(val => val ? val.toString().toLowerCase().includes(quickCriteria) : false));
    // let quickResult = objectArray.filter(obj => Object.keys(obj).some(key => obj[key] ? obj[key].toString().toLowerCase().includes(quickCriteria) : false));
    let quickResult = objectArray.filter(obj => Object.keys(obj).some(key => {
      if(key === 'firstName' || key === 'lastName' || key === 'jobTitle'){
        return obj[key] ? obj[key].toString().toLowerCase().includes(query) : false
      }
      return false;
    }));
  
    return quickResult;
  }