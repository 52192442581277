<div class="request-feedback-form">

  <div class="form-header">
    <div>{{ 'feedback.request_feedback_form.title' | translate }}</div>
    <button mat-icon-button class="form-close" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-horizontal-stepper linear="true" #stepper>

    <mat-step [stepControl]="feedbackRequestRecipientsGroup">
      <form [formGroup]="feedbackRequestRecipientsGroup" class="form-step">
        <!-- <ng-template matStepLabel>{{ 'feedback.request_feedback_form.recipients_step_titile' | translate }}
        </ng-template> -->

        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
            {{ 'feedback.request_feedback_form.choose_recipients_hint' | translate }}
          </div>
        </div>

        <div class="form-step-body">
          <!-- <div class="feedback-recipient-type-input text-center">
            <mat-button-toggle-group formControlName="feedbackRequestRecipientType">
              <mat-button-toggle [value]="feedbackRequestRecipientTypeEnum.Users">
                {{ 'feedback.request_feedback_form.recipients_step_users_button' | translate }}
              </mat-button-toggle>
              <mat-button-toggle [value]="feedbackRequestRecipientTypeEnum.Teams">
                {{ 'feedback.request_feedback_form.recipients_step_teams_button' | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div> -->

          <app-user-select-form-control formControlName="feedbackRequestRecipientUsers"
            *ngIf="feedbackRequestRecipientsGroup.value.feedbackRequestRecipientType === feedbackRequestRecipientTypeEnum.Users"
            placeholder="{{ 'feedback.request_feedback_form.recipients_step_users_placeholder' | translate }} ">
          </app-user-select-form-control>

          <app-team-select-form-control formControlName="feedbackRequestRecipientTeams"
            *ngIf="feedbackRequestRecipientsGroup.value.feedbackRequestRecipientType === feedbackRequestRecipientTypeEnum.Teams"
            placeholder="{{ 'feedback.request_feedback_form.recipients_step_teams_placeholder' | translate }} ">
          </app-team-select-form-control>
        </div>

        <div class="form-step-nav text-center">
          <button mat-flat-button color="primary" matStepperNext type="button"
            [disabled]="!feedbackRequestRecipientsGroup.valid">
            {{ 'feedback.next_button' | translate }}
            <!-- <mat-icon>arrow_forward</mat-icon> -->
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="feedbackRequestCommentGroup">
      <form [formGroup]="feedbackRequestCommentGroup" class="form-step">
        <!-- <ng-template matStepLabel>{{ 'feedback.request_feedback_form.comment_step_title' | translate }}</ng-template> -->
        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
            {{ 'feedback.request_feedback_form.comment_hint' | translate }}
          </div>
        </div>

        <div class="form-step-body">
          <div class="feedback-comment-wrapper">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{ 'feedback.request_feedback_form.comment_step_placeholder' | translate }}</mat-label>
              <textarea matInput formControlName="comment" class="full-width" #comments rows="5"
                maxlength="200"></textarea>
            </mat-form-field>
          </div>
          <div class="feedback-comment-hint text-muted">{{comments.value.length}} / 200</div>

        </div>
        <div class="form-step-nav text-center">
          <button mat-stroked-button matStepperPrevious type="button">
            {{ 'feedback.previous_button' | translate }}
            <!-- <mat-icon>arrow_back</mat-icon> -->
          </button>
          <button mat-flat-button color="primary" type="button"
            [disabled]="!feedbackRequestRecipientsGroup.valid || !feedbackRequestCommentGroup.valid || submitDisabled"
            (click)="onSubmit()">{{ 'feedback.save_button' | translate }}</button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>


<!-- <p>Form value: {{ feedbackRequestRecipientsGroup.value | json }}</p>
<p>Form status: {{ feedbackRequestRecipientsGroup.status | json }}</p> -->