import { FeedbackTag } from './../../../../../../shared/model/feedback-tag.interface';
import { BotActionFeedbackTagSelect } from './../../../../../../shared/model/bot-action-feedback-tag-select.interface';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { tap, startWith, map, take, filter, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import * as fromAuth from '../../../auth/store/reducers/auth.reducer';
import { searchTeams } from 'app/shared/helpers/search-teams';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bot-action-feedback-tag-select',
  templateUrl: './bot-action-feedback-tag-select.component.html',
  styleUrls: ['./bot-action-feedback-tag-select.component.scss']
})
export class BotActionFeedbackTagSelectComponent implements OnInit {

  @Input()
  botActionFeedbackTagSelect: BotActionFeedbackTagSelect;

  @Output()
  selectedItemsChange: EventEmitter<FeedbackTag[]> = new EventEmitter();

  @Output()
  submit: EventEmitter<FeedbackTag[]> = new EventEmitter();

  itemsSub: Subscription;
  items: FeedbackTag[] = [];
  selectedItems: FeedbackTag[] = [];
  filteredItems: FeedbackTag[] = [];
  searchSub: Subscription;
  searchValue = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dataProvider: DataProviderService,
    // private store: Store<fromAuth.State>,
  ) { }

  ngOnInit() {

    this.itemsSub = this.dataProvider.getActiveFeedbackTags().pipe(
      take(1),
      map((feedbackTags: FeedbackTag[]) => {
        this.items = feedbackTags;
        this.filteredItems = this.items;
      })
    ).subscribe();

    // Listen for search value changes
    this.searchSub = this.searchValue.valueChanges.pipe(
      map(value => {
        if (value) {
          this.filteredItems = searchTeams(this.items, value);
        } else {
          this.filteredItems = this.items;
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.itemsSub.unsubscribe();
    this.searchSub.unsubscribe();
  }

  addItemToSelected(item: FeedbackTag) {
    this.selectedItems.push(item);

    // Prevents from selecting the same user twice
    this.removeTeamFromAvailable(item);

    // Clear filtered users 
    this.filteredItems = this.items;

    this.selectedItemsChange.emit(this.selectedItems);
  }

  removeItemFromSelected(item: FeedbackTag) {
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.addTeamToAvailable(item);
      this.selectedItemsChange.emit(this.selectedItems);
    }
  }

  addTeamToAvailable(item: FeedbackTag) {
    this.items.push(item);

    // sort ascending by name
    this.items.sort((a: FeedbackTag, b: FeedbackTag) => b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1);
  }

  removeTeamFromAvailable(item: FeedbackTag) {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  resetInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onSubmit() {
    this.submit.emit(this.selectedItems);
  }

}






