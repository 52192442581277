<div class="feedback-wrapper">
  <mat-dialog-content>
    <!-- <div *ngIf="!(isDailyFeedbackLimitExceeded | async); else limitMsg"> -->
    <app-feedback-form [preselectedRecipient]="preselectedRecipient" [feedbackKind]="feedbackKind"
      [author]="author | async" (cancel)="onCancel()" (submit)="onSubmit()" *ngIf="isGiveFeedbackForm">
    </app-feedback-form>
    <!-- </div> -->

    <app-request-feedback-form [preselectedRecipient]="preselectedRecipient" [author]="author | async"
      (cancel)="onCancel()" (submit)="onSubmit($event)" *ngIf="isRequestFeedbackForm">
    </app-request-feedback-form>

    <app-feedback-form [feedbackRequestToken]="feedbackRequestToken" [feedbackKind]="feedbackKind"
      [author]="author | async" (cancel)="onCancel()" (submit)="onSubmit()" *ngIf="isResponseFeedbackForm">
    </app-feedback-form>

    <app-suggestion-form [author]="author | async" (cancel)="onCancel()" (submit)="onSubmit()" *ngIf="isSuggestionForm">
    </app-suggestion-form>

    <!-- <app-response-feedback-form [feedbackRequestToken]="feedbackRequestToken" (cancel)="onCancel()"
      (submit)="onSubmit($event)" *ngIf="isResponseFeedbackForm">
    </app-response-feedback-form> -->

  </mat-dialog-content>
</div>

<!-- <ng-template #limitMsg>
  <div class="row">
    <div class="col text-center">
      <div class="feedback-limit-msg">
        {{ 'feedback.limit_message' | translate }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col text-right">
      <button mat-button (click)="onCancel()">{{ 'feedback.close_button' | translate }}</button>
    </div>
  </div>
</ng-template> -->