<div class="suggestion-form">

  <div class="form-header">
    <div>
      {{ 'feedback.suggestion_form.title' | translate }}
    </div>
    <button mat-icon-button class="form-close" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-horizontal-stepper linear="true" #stepper>
    <mat-step [stepControl]="suggestionAuthorGroup">
      <form [formGroup]="suggestionAuthorGroup" class="form-step">
        <!-- <ng-template matStepLabel>
          _author
        </ng-template> -->

        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
            {{ 'feedback.suggestion_form.choose_author_hint' | translate }}
          </div>
        </div>

        <div class="form-step-body">
          <div class="form-button-toggle-group text-center">
            <mat-button-toggle-group formControlName="anonymousAuthor">
              <mat-button-toggle [value]="false">
                <div class="suggestion-author">
                  <app-user-avatar [user]="author"></app-user-avatar>
                  <div>
                    {{ 'feedback.suggestion_form.share_as' | translate }} {{ author.firstName }} {{ author.lastName }}
                  </div>
                </div>
              </mat-button-toggle>
              <mat-button-toggle [value]="true">
                <div class="suggestion-author">
                  <app-user-anonymous-avatar></app-user-anonymous-avatar>
                  <div>
                      {{ 'feedback.suggestion_form.share_as_anonymous' | translate }}
                  </div>
                </div>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="form-step-nav text-center">
          <button mat-flat-button color="primary" matStepperNext type="button"
            [disabled]="!suggestionAuthorGroup.valid">
            {{ 'feedback.next_button' | translate }}
            <!-- <mat-icon>arrow_forward</mat-icon> -->
          </button>
        </div>
      </form>
    </mat-step>


    <mat-step [stepControl]="suggestionDescriptionGroup">
      <form [formGroup]="suggestionDescriptionGroup" class="form-step">
        <!-- <ng-template matStepLabel>
          _opis
        </ng-template> -->

        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
            {{ 'feedback.suggestion_form.description_hint' | translate }}
          </div>
        </div>

        <div class="form-step-body">
          <div class="feedback-comment-wrapper">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>
                {{ 'feedback.suggestion_form.input_title' | translate }}
              </mat-label>
              <input matInput formControlName="title" class="full-width" #title maxlength="50">
              <div class="title-comment-hint text-muted">{{title.value.length}} / 50</div>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>
                {{ 'feedback.suggestion_form.input_description' | translate }}
              </mat-label>
              <textarea matInput formControlName="description" rows="5" class="full-width"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="form-step-nav text-center">
          <button mat-stroked-button matStepperPrevious type="button">
            <!-- <mat-icon>arrow_back</mat-icon> -->
            {{ 'feedback.previous_button' | translate }}
          </button>
          <button mat-flat-button color="primary" matStepperNext type="button"
            [disabled]="!suggestionDescriptionGroup.valid"
            (click)="checkIfSugestionVisibleForAuthor()">
            <!-- <mat-icon>arrow_forward</mat-icon> -->
            {{ 'feedback.next_button' | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="suggestionRecipientsGroup">
      <form [formGroup]="suggestionRecipientsGroup" class="form-step">
        <!-- <ng-template matStepLabel>
          _widoczność
        </ng-template> -->

        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
              {{ 'feedback.suggestion_form.choose_visibility_hint' | translate }}
          </div>
        </div>

        <div class="form-step-body">
          <div class="form-button-toggle-group text-center">
            <mat-button-toggle-group formControlName="suggestionRecipientType">
              <mat-button-toggle [value]="suggestionRecipientTypeEnum.Company">
                <i class="material-icons">apartment</i>
                {{ 'feedback.suggestion_form.share_with_company' | translate }}
              </mat-button-toggle>
              <mat-button-toggle [value]="suggestionRecipientTypeEnum.Teams">
                <i class="material-icons">people</i>
                {{ 'feedback.suggestion_form.share_with_teams' | translate }}
              </mat-button-toggle>
              <mat-button-toggle [value]="suggestionRecipientTypeEnum.Users">
                <i class="material-icons">person</i>
                {{ 'feedback.suggestion_form.share_with_users' | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <app-user-select-form-control formControlName="suggestionRecipientUsers"
            *ngIf="suggestionRecipientsGroup.value.suggestionRecipientType === suggestionRecipientTypeEnum.Users"
            placeholder="{{ 'feedback.suggestion_form.share_with_users_input_placeholder' | translate }}"
            [showAuthUser]="suggestionAuthorGroup.value.anonymousAuthor"
            (valueChange)="checkIfSugestionVisibleForAuthor()">
          </app-user-select-form-control>

          <app-team-select-form-control formControlName="suggestionRecipientTeams"
            *ngIf="suggestionRecipientsGroup.value.suggestionRecipientType === suggestionRecipientTypeEnum.Teams"
            placeholder="{{ 'feedback.suggestion_form.share_with_teams_input_placeholder' | translate }}"
            (valueChange)="checkIfSugestionVisibleForAuthor()">
          </app-team-select-form-control>
        </div>

        <div class="suggestion-not-visible-warning" *ngIf="!isSugestionVisibleForAuthor">
            {{ 'feedback.suggestion_form.not_visible_for_author_warning' | translate }}
        </div>

        <div class="form-step-nav text-center">
          <button mat-stroked-button matStepperPrevious type="button">
            <!-- <mat-icon>arrow_back</mat-icon> -->
            {{ 'feedback.previous_button' | translate }}
          </button>
          <button mat-flat-button color="primary" type="button"
            [disabled]="!suggestionAuthorGroup.valid || !suggestionDescriptionGroup.valid || !suggestionRecipientsGroup.valid || submitDisabled || !isSugestionVisibleForAuthor"
            (click)="onSubmit()">{{ 'feedback.save_button' | translate }}</button>
        </div>
      </form>
    </mat-step>

  </mat-horizontal-stepper>
</div>

<!-- 
<p>Form value: {{ suggestionRecipientsGroup.value | json }}</p>
<p>Form status: {{ suggestionRecipientsGroup.status | json }}</p> -->