<div *ngIf="action" class="bot-action">
  <ng-container *ngIf="action.type === botActionType.Button">
    <app-bot-action-button [botActionButton]="action.data" (submit)="onButtonSubmit($event)"></app-bot-action-button> 
  </ng-container>
  
  <ng-container *ngIf="action.type === botActionType.TextInput">
    <app-bot-action-text-input [botActionTextInput]="action.data" (submit)="onTextInputSubmit($event)"></app-bot-action-text-input>
  </ng-container>

  <ng-container *ngIf="action.type === botActionType.UserSelect">
    <app-bot-action-user-select [botActionUserSelect]="action.data" (submit)="onUserSelectSubmit($event)" class="full-width"></app-bot-action-user-select>
  </ng-container>

  <ng-container *ngIf="action.type === botActionType.TeamSelect">
    <app-bot-action-team-select [botActionTeamSelect]="action.data" (submit)="onTeamSelectSubmit($event)" class="full-width"></app-bot-action-team-select>
  </ng-container>

  <ng-container *ngIf="action.type === botActionType.FeedbackTypeSelect">
    <app-bot-action-feedback-type-select [botActionFeedbackTypeSelect]="action.data" (submit)="onFeedbackTypeSelectSubmit($event)"></app-bot-action-feedback-type-select>
  </ng-container>

  <ng-container *ngIf="action.type === botActionType.FeedbackTagSelect">
    <app-bot-action-feedback-tag-select [botActionFeedbackTagSelect]="action.data" (submit)="onFeedbackTagSelectSubmit($event)"></app-bot-action-feedback-tag-select>
  </ng-container>

</div>