import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/store/reducers/auth.reducer';
import * as authActions from '../../../auth/store/actions/auth.actions';
import { Observable } from 'rxjs';
import { User } from '../../../../../../shared/model/user.interface';
import { NavigationService } from '../../services/navigation.service';
import { Organization } from '../../../../../../shared/model/organization.interface';
import { UserRole } from '../../../../../../shared/model/user-role.enum';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input()
  private sidenavRef: MatSidenav;

  authUser: Observable<User>;
  organization: Observable<Organization>;

  userRoleEnum = UserRole;

  constructor(
    private store: Store<fromAuth.State>,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.authUser = this.store.select(fromAuth.getUser);
    this.organization = this.store.select(fromAuth.getOrganization);
  }

  logout() {
    this.store.dispatch(new authActions.Logout());
  }

  toggleSidenav() {
    this.sidenavRef.toggle();
  }

  openSearch() {
    this.navigationService.openSearch();
    this.sidenavRef.toggle();
  }
}
