export class FeedbackType {
  id?: string;
  createdAt?: any;
  modifiedAt?: any;
  description: string;
  active: boolean;
  points: number;
  icon: string;
  iconColor: string;
  positive: boolean;

  // notify?: FeedbackNotificationType;
  // orgLevels?: string[];
}


