<div id="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>
<div class="fab-container">
  <button mat-fab color="primary" class="fab-toggler" (click)="onToggleFab()">
    <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i>
    <!-- <div [@fabToggler]="{value: fabTogglerState}">
        <img src="/assets/img/icon.svg">
    </div> -->
  </button>
  <div [@speedDialStagger]="buttons.length">
    <button *ngFor="let button of buttons" mat-raised-button class="fab-secondary" color="secondary"
      (click)="openFeedbackDialog(button)">
      <i class="material-icons">{{button.icon}}</i>
      {{button.text}}
    </button>
  </div>
</div>