import { UserPartial } from './../../../../../../shared/model/user-partial.interface';
import { MeetingTopicStatus } from './../../../../../../shared/model/meeting-topic-status.enum';
import { FeedbackKind } from './../../../../../../shared/model/feedback-kind.enum';
import { MeetingTopicType } from './../../../../../../shared/model/meeting-topic-type.enum';
import { take, map } from 'rxjs/operators';
import { MeetingTopic } from './../../../../../../shared/model/meeting-topic.interface';
import { NotificationService } from 'app/notification/services/notification.service';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { User } from './../../../../../../shared/model/user.interface';
import { FeedbackVisibilityType } from './../../../../../../shared/model/feedback-visibility-type.enum';
import { Component, OnInit, Input } from '@angular/core';

import { Feedback } from '../../../../../../shared/model/feedback.interface';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-feedback-list-item',
  templateUrl: './feedback-list-item.component.html',
  styleUrls: ['./feedback-list-item.component.scss']
})
export class FeedbackListItemComponent implements OnInit {

  @Input()
  feedback: Feedback;

  @Input()
  isLoading: boolean;

  @Input()
  authUser: User;

  isLetsMeetButtonDisabled: boolean;

  dateLocale: string;

  feedbackVisibilityType = FeedbackVisibilityType;

  constructor(
    private translate: TranslateService,
    private dataProvider: DataProviderService,
    private notificationService: NotificationService,
  ) {
    this.dateLocale = translate.currentLang;
  }

  ngOnInit() {
  }



  onLetsMeetClick() {

    this.isLetsMeetButtonDisabled = true;

    let meetingTopicType: MeetingTopicType;
    if (this.feedback.kind === FeedbackKind.Praise) {
      meetingTopicType = MeetingTopicType.Praise;
    }
    else if (this.feedback.kind === FeedbackKind.Tip) {
      meetingTopicType = MeetingTopicType.Tip;
    }

    let recipientId: string;
    let recipientPartial: UserPartial;
    if (this.authUser.id === this.feedback.authorId) {
      recipientId = this.feedback.user.id;
      recipientPartial = {
        id: this.feedback.user.id,
        firstName: this.feedback.user.firstName,
        lastName: this.feedback.user.lastName,
        email: this.feedback.user.email,
        teamId: this.feedback.user.teamId,
        jobTitle: this.feedback.user.jobTitle,
        avatar: this.feedback.user.avatar
      };
    }
    else {
      recipientId = this.feedback.author.id;
      recipientPartial = {
        id: this.feedback.author.id,
        firstName: this.feedback.author.firstName,
        lastName: this.feedback.author.lastName,
        email: this.feedback.author.email,
        teamId: this.feedback.author.teamId,
        jobTitle: this.feedback.author.jobTitle,
        avatar: this.feedback.author.avatar
      };
    }

    const meetingTopic: MeetingTopic = {
      authorId: this.authUser.id,
      authorPartial: {
        id: this.authUser.id,
        firstName: this.authUser.firstName,
        lastName: this.authUser.lastName,
        email: this.authUser.email,
        teamId: this.authUser.teamId,
        jobTitle: this.authUser.jobTitle,
        avatar: this.authUser.avatar
      },

      recipientId: recipientId,
      recipientPartial: recipientPartial,

      usersIds: [
        this.authUser.id,
        recipientId
      ],

      type: meetingTopicType,
      feedbackId: this.feedback.id,
      feedbackPartial: {
        id: this.feedback.id,
        createdAt: this.feedback.createdAt,

        comment: this.feedback.comment,
        visibility: this.feedback.visibility,

        authorId: this.feedback.authorId,
        userId: this.feedback.userId,
        teamId: this.feedback.teamId,

        points: this.feedback.points,
        feedbackTypeId: this.feedback.feedbackTypeId,

        tagsIds: this.feedback.tagsIds,
        tagsPartials: this.feedback.tagsPartials,

        feedbackRequestId: this.feedback.feedbackRequestId,
        feedbackRequestTokenId: this.feedback.feedbackRequestTokenId,

        kind: this.feedback.kind

      },

      status: MeetingTopicStatus.New
    }

    this.dataProvider.addMeetingTopic(meetingTopic).pipe(
      take(1),
      map((result: boolean) => {
        if (result) {
          this.notificationService.showNotification(this.translate.instant('meetings.lets_meet_button.success_notification'), 'success', true);
        } else {
          this.notificationService.showNotification(this.translate.instant('meetings.lets_meet_button.failure_notification'), 'error', true);
        }
      })
    ).subscribe();
  }

}
