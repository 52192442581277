import { Team } from '../../../../../../shared/model/team.interface';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { tap, startWith, map, take, filter, switchMap } from 'rxjs/operators';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, forwardRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { searchTeams } from 'app/shared/helpers/search-teams';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-team-select-form-control',
  templateUrl: './team-select-form-control.component.html',
  styleUrls: ['./team-select-form-control.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TeamSelectFormControlComponent),
      multi: true
    }
  ]
})
export class TeamSelectFormControlComponent implements OnInit, ControlValueAccessor {


  @Input()
  placeholder: string;

  @Output()
  valueChange: EventEmitter<Team[]> = new EventEmitter();

  teams: Team[] = [];
  selectedTeams: Team[] = [];
  filteredTeams: Team[] = [];
  searchSub: Subscription;
  searchValue = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dataProvider: DataProviderService,
  ) { }

  ngOnInit() {

    this.dataProvider.getActiveTeams().pipe(
      take(1),
      map((teams: Team[]) => {
        this.teams = teams;
        this.filteredTeams = this.teams;
      })
    ).subscribe();

    // Listen for search value changes
    this.searchSub = this.searchValue.valueChanges.pipe(
      map(value => {
        if (value) {
          this.filteredTeams = searchTeams(this.teams, value);
        } else {
          this.filteredTeams = this.teams;
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.searchSub.unsubscribe();
  }

  /**   
   * ControlValueAccessor interface
   * Writes a new value from the form model into the view or (if needed) DOM property
   */
  writeValue(value: Team[]) {
    // if (value !== undefined) {
    //   value.map((user: User) => this.addUserToSelected(user));
    // }
  }

  /**
   * ControlValueAccessor interface
   * Set the function to be called when the control receives a change event.
   */
  propagateChange = (_: any) => { };
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**    
   * ControlValueAccessor interface
  * Set the function to be called when the control receives a touch event.
  */
  registerOnTouched() { }

  addTeamToSelected(team: Team) {
    this.selectedTeams.push(team);

    // Prevents from selecting the same user twice
    this.removeTeamFromAvailable(team);

    // Clear filtered users 
    this.filteredTeams = this.teams;

    this.propagateChange(this.selectedTeams);
    this.valueChange.emit(this.selectedTeams);
  }

  removeTeamFromSelected(team: Team) {
    const index = this.selectedTeams.indexOf(team);

    if (index >= 0) {
      this.selectedTeams.splice(index, 1);
      this.addTeamToAvailable(team);
      this.propagateChange(this.selectedTeams);
      this.valueChange.emit(this.selectedTeams);
    }
  }

  addTeamToAvailable(team: Team) {
    this.teams.push(team);

    // sort ascending by nam
    this.teams.sort((a: Team, b: Team) => b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1);
  }

  removeTeamFromAvailable(team: Team) {
    const index = this.teams.indexOf(team);

    if (index >= 0) {
      this.teams.splice(index, 1);
    }
  }

  resetInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }


}






