import { Team } from './../../../../../../shared/model/team.interface';
import { BotActionTeamSelect } from './../../../../../../shared/model/bot-action-team-select.interface';
import { Store } from '@ngrx/store';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { tap, startWith, map, take, filter, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import * as fromAuth from '../../../auth/store/reducers/auth.reducer';
import { searchTeams } from 'app/shared/helpers/search-teams';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bot-action-team-select',
  templateUrl: './bot-action-team-select.component.html',
  styleUrls: ['./bot-action-team-select.component.scss']
})
export class BotActionTeamSelectComponent implements OnInit {

  @Input()
  botActionTeamSelect: BotActionTeamSelect;

  @Output()
  selectedTeamsChange: EventEmitter<Team[]> = new EventEmitter();

  @Output()
  submit: EventEmitter<Team[]> = new EventEmitter();

  teamsSub: Subscription;
  teams: Team[] = [];
  selectedTeams: Team[] = [];
  filteredTeams: Team[] = [];
  searchSub: Subscription;
  searchValue = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dataProvider: DataProviderService,
    // private store: Store<fromAuth.State>,
  ) { }

  ngOnInit() {

    this.teamsSub = this.dataProvider.getActiveTeams().pipe(
      take(1),
      map((teams: Team[]) => {
        this.teams = teams;
        this.filteredTeams = this.teams;
      })
    ).subscribe();

    // Listen for search value changes
    this.searchSub = this.searchValue.valueChanges.pipe(
      map(value => {
        if (value) {
          this.filteredTeams = searchTeams(this.teams, value);
        } else {
          this.filteredTeams = this.teams;
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.teamsSub.unsubscribe();
    this.searchSub.unsubscribe();
  }

  addTeamToSelected(team: Team) {
    this.selectedTeams.push(team);

    // Prevents from selecting the same user twice
    this.removeTeamFromAvailable(team);

    // Clear filtered users 
    this.filteredTeams = this.teams;

    this.selectedTeamsChange.emit(this.selectedTeams);
  }

  removeTeamFromSelected(team: Team) {
    const index = this.selectedTeams.indexOf(team);

    if (index >= 0) {
      this.selectedTeams.splice(index, 1);
      this.addTeamToAvailable(team);
      this.selectedTeamsChange.emit(this.selectedTeams);
    }
  }

  addTeamToAvailable(team: Team) {
    this.teams.push(team);

    // sort ascending by nam
    this.teams.sort((a: Team, b: Team) => b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1);
  }

  removeTeamFromAvailable(team: Team) {
    const index = this.teams.indexOf(team);

    if (index >= 0) {
      this.teams.splice(index, 1);
    }
  }

  resetInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onSubmit() {
    this.submit.emit(this.selectedTeams);
  }

}






