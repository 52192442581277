<mat-nav-list>
  <ng-container *ngIf="organization | async as organization">
    <ng-container *ngIf="authUser | async as authUser">

      <button mat-menu-item (click)="openSearch()" *ngIf="organization.settings.publicUserProfilePageEnabled">
        <mat-icon>search</mat-icon>
        <span>{{ 'navigation.search' | translate }}</span>
      </button>
      
      <mat-divider></mat-divider>

      <a mat-menu-item routerLink="/feed" (click)="toggleSidenav()"
        *ngIf="organization.settings.publicPraisesPageEnabled">
        <mat-icon>chat</mat-icon>
        <!-- <mat-icon>thumb_up</mat-icon> -->
        <span>{{ 'navigation.feed' | translate }}</span>
      </a>
      <a mat-menu-item routerLink="/ideas" (click)="toggleSidenav()"
        *ngIf="organization.settings.suggestionsEnabled">
        <mat-icon>inbox</mat-icon>
        <span>{{ 'navigation.suggestions' | translate }}</span>
      </a>
      <a mat-menu-item routerLink="/teams" (click)="toggleSidenav()" *ngIf="organization.settings.teamsPageEnabled">
        <mat-icon>supervisor_account</mat-icon>
        <span>{{ 'navigation.teams' | translate }}</span>
      </a>
      <a mat-menu-item routerLink="/rewards" (click)="toggleSidenav()" *ngIf="organization.settings.awardsEnabled">
        <mat-icon>card_giftcard</mat-icon>
        <span>{{ 'navigation.awards' | translate }}</span>
      </a>
      <a mat-menu-item routerLink="/leaderboard" (click)="toggleSidenav()"
        *ngIf="organization.settings.leaderboardPageEnabled">
        <mat-icon>insert_chart_outlined</mat-icon>
        <span>{{ 'navigation.leaderboard' | translate }}</span>
      </a>

      <!-- Profile -->
      <mat-divider></mat-divider>
      <a mat-menu-item [routerLink]="['/users', authUser.id, 'recognitions']" (click)="toggleSidenav()"
        *ngIf="organization.settings.privateUserProfilePageEnabled">
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'navigation.my_profile' | translate }}</span>
      </a>
      <a mat-menu-item routerLink="/settings" (click)="toggleSidenav()">
        <mat-icon>tune</mat-icon>
        <span>{{ 'navigation.my_settings' | translate }}</span>
      </a>
      <a mat-menu-item routerLink="/" (click)="logout()" (click)="toggleSidenav()">
        <mat-icon>power_settings_new</mat-icon>
        <span>{{ 'navigation.logout' | translate }}</span>
      </a>


      <!-- Admin -->
      <div *ngIf="authUser.orgRole === userRoleEnum.Admin">

        <mat-divider></mat-divider>

        <div class="nav-title">
          {{ 'navigation.admin_teams_and_employees' | translate }}
        </div>
        <a mat-menu-item routerLink="/admin/team/list" (click)="toggleSidenav()">
          <mat-icon>list</mat-icon>
          {{ 'navigation.admin_team_list' | translate }}
        </a>
        <a mat-menu-item routerLink="/admin/user/list" (click)="toggleSidenav()">
          <mat-icon>list</mat-icon>
          {{ 'navigation.admin_user_list' | translate }}
        </a>


        <div class="nav-title">
          {{ 'navigation.admin_feedback' | translate }}
        </div>
        <a mat-menu-item routerLink="/admin/feedback-settings" (click)="toggleSidenav()">
          <mat-icon>settings</mat-icon>
          {{ 'navigation.admin_feedback_settings' | translate }}
        </a>
        <a mat-menu-item routerLink="/admin/feedback-tag/list" (click)="toggleSidenav()">
          <mat-icon>list</mat-icon>
          {{ 'navigation.admin_feedback_tag_list' | translate }}
        </a>
        <a mat-menu-item routerLink="/admin/feedback-type/list" (click)="toggleSidenav()">
          <mat-icon>list</mat-icon>
          {{ 'navigation.admin_feedback_list' | translate }}
        </a>
        <a mat-menu-item routerLink="/admin/award-type/list" (click)="toggleSidenav()">
          <mat-icon>list</mat-icon>
          {{ 'navigation.admin_award_list' | translate }}
        </a>
        <a mat-menu-item routerLinkActive="active" routerLink="/admin/reports/feedback">
          <mat-icon>timeline</mat-icon>
          {{ 'navigation.admin_reports_feedback' | translate }}
        </a>

        <div class="nav-title">
          {{ 'navigation.admin_idea_box' | translate }}
        </div>
        <a mat-menu-item routerLink="/admin/ideas/settings" (click)="toggleSidenav()">
          <mat-icon>settings</mat-icon>
          {{ 'navigation.admin_idea_box_settings' | translate }}
        </a>
      </div>

    </ng-container>
  </ng-container>
</mat-nav-list>