<div class="update-notification-wrapper" *ngIf="showUpdateNotification">
  <div class="update-notification mat-elevation-z8">
    <div class="update-message">
      {{ 'pwa.update_notification.message' | translate }}
    </div>
    <div class="update-button">
      <button mat-flat-button color="primary" (click)="onUpdate()">
        {{ 'pwa.update_notification.button' | translate }}
      </button>
    </div>
  </div>
</div>