<div class="feedback-form">

  <div class="form-header">
    <div *ngIf="feedbackKind === feedbackKindEnum.Praise">
      {{ 'feedback.feedback_form.praise_form_title' | translate }}
    </div>
    <div *ngIf="feedbackKind === feedbackKindEnum.Tip">
      {{ 'feedback.feedback_form.tip_form_title' | translate }}
    </div>
    <button mat-icon-button class="form-close" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>


  <mat-horizontal-stepper linear="true" #stepper *ngIf="!showLoader; else loader">
    <mat-step [stepControl]="feedbackRecipientsGroup" *ngIf="showFeedbackRecipientsStep">
      <form [formGroup]="feedbackRecipientsGroup" class="form-step">
        <!-- <ng-template matStepLabel>{{ 'feedback.choose_recipients' | translate }}</ng-template> -->

        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
            <div *ngIf="feedbackKind === feedbackKindEnum.Praise">
              {{ 'feedback.feedback_form.praise_choose_recipients_hint' | translate }}
            </div>
            <div *ngIf="feedbackKind === feedbackKindEnum.Tip">
              {{ 'feedback.feedback_form.tip_choose_recipients_hint' | translate }}
            </div>
          </div>
        </div>

        <div class="form-step-body">
          <app-user-select-form-control *ngIf="feedbackKind === feedbackKindEnum.Praise"
            formControlName="feedbackRecipients"
            placeholder="{{ 'feedback.feedback_form.praise_choose_recipients_placeholder' | translate }} ">
          </app-user-select-form-control>
          <app-user-select-form-control *ngIf="feedbackKind === feedbackKindEnum.Tip"
            formControlName="feedbackRecipients"
            placeholder="{{ 'feedback.feedback_form.tip_choose_recipients_placeholder' | translate }}">
          </app-user-select-form-control>
        </div>

        <div class="form-step-nav text-center">
          <button mat-flat-button color="primary" matStepperNext type="button"
            [disabled]="!feedbackRecipientsGroup.valid">
            {{ 'feedback.next_button' | translate }}
            <!-- <mat-icon>arrow_forward</mat-icon> -->
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="feedbackTypeGroup" *ngIf="showFeedbackTypeStep">
      <form [formGroup]="feedbackTypeGroup" class="form-step">
        <!-- <ng-template matStepLabel>{{ 'feedback.choose_points' | translate }}</ng-template> -->

        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
            <div *ngIf="feedbackKind === feedbackKindEnum.Praise">
              {{ 'feedback.feedback_form.praise_choose_badge_hint' | translate }}
            </div>
            <div *ngIf="feedbackKind === feedbackKindEnum.Tip">
              <!-- not applicable -->
            </div>
          </div>
        </div>

        <div class="form-step-body">
          <div class="feedback-type-input" *ngIf="feedbackTypes | async as feedbackTypes">
            <mat-button-toggle-group formControlName="feedbackType">
              <mat-button-toggle [value]="feedbackType" *ngFor="let feedbackType of feedbackTypes; let i = index">
                <app-feedback-type-icon [feedbackType]="feedbackType"></app-feedback-type-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="form-step-nav text-center">
          <button mat-stroked-button matStepperPrevious type="button">
            {{ 'feedback.previous_button' | translate }}
            <!-- <mat-icon>arrow_back</mat-icon> -->
          </button>

          <button mat-flat-button color="primary" matStepperNext type="button" [disabled]="!feedbackTypeGroup.valid">
            {{ 'feedback.next_button' | translate }}
            <!-- <mat-icon>arrow_forward</mat-icon> -->
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="feedbackTagsGroup" *ngIf="showFeedbackTagsStep">
      <form [formGroup]="feedbackTagsGroup" class="form-step">
        <!-- <ng-template matStepLabel>{{ 'feedback.choose_categories' | translate }}</ng-template> -->

        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
            <div *ngIf="feedbackKind === feedbackKindEnum.Praise">
              {{ 'feedback.feedback_form.praise_choose_categories_hint' | translate }}
            </div>
            <div *ngIf="feedbackKind === feedbackKindEnum.Tip">
              {{ 'feedback.feedback_form.tip_choose_categories_hint' | translate }}
            </div>
          </div>
        </div>

        <div class="form-step-body">
          <div class="feedback-tags-input" *ngIf="feedbackTags | async as feedbackTags">
            <mat-button-toggle-group formControlName="feedbackTags" multiple>
              <mat-button-toggle class="feedback-tag-button" [value]="feedbackTag"
                *ngFor="let feedbackTag of feedbackTags; let i = index">
                <app-svg-icon [icon]="feedbackTag.icon"></app-svg-icon>
                {{ feedbackTag.name }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="form-step-nav text-center">
          <button mat-stroked-button matStepperPrevious type="button">
            {{ 'feedback.previous_button' | translate }}
            <!-- <mat-icon>arrow_back</mat-icon> -->
          </button>
          <button mat-flat-button color="primary" matStepperNext type="button" [disabled]="!feedbackTagsGroup.valid">
            {{ 'feedback.next_button' | translate }}
            <!-- <mat-icon>arrow_forward</mat-icon> -->
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="feedbackCommentGroup" *ngIf="showFeedbackCommentStep">
      <form [formGroup]="feedbackCommentGroup" class="form-step">
        <!-- <ng-template matStepLabel>{{ 'feedback.add_comment' | translate }}</ng-template> -->

        <div class="help">
          <div class="help-icon">
            <img src="/assets/img/help-tip-icon.svg">
          </div>
          <div class="help-bubble help-bubble-triangle help-border left-top">
            <div *ngIf="feedbackKind === feedbackKindEnum.Praise">
              {{ 'feedback.feedback_form.praise_comment_hint' | translate }}
            </div>
            <div *ngIf="feedbackKind === feedbackKindEnum.Tip">
              {{ 'feedback.feedback_form.tip_comment_hint' | translate }}
            </div>
          </div>
        </div>

        <div class="form-step-body">
          <div class="feedback-comment-wrapper">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label *ngIf="feedbackKind === feedbackKindEnum.Praise">
                {{ 'feedback.feedback_form.praise_comment_placeholder' | translate }}
              </mat-label>
              <mat-label *ngIf="feedbackKind === feedbackKindEnum.Tip">
                {{ 'feedback.feedback_form.tip_comment_placeholder' | translate }}
              </mat-label>
              <textarea matInput formControlName="comment" class="full-width" #comments rows="5"
                maxlength="200"></textarea>
            </mat-form-field>
          </div>
          <div class="feedback-comment-hint text-muted">{{comments.value.length}} / 200</div>
        </div>

        <div class="form-step-nav text-center">
          <button mat-stroked-button matStepperPrevious type="button">
            {{ 'feedback.previous_button' | translate }}
            <!-- <mat-icon>arrow_back</mat-icon> -->
          </button>

          <button mat-flat-button color="primary" type="button" *ngIf="feedbackKind === feedbackKindEnum.Praise"
            [disabled]="!isFeedackFormValid() || submitDisabled"
            (click)="onSubmit()">{{ 'feedback.save_button' | translate }}</button>
          <button mat-flat-button color="primary" type="button" *ngIf="feedbackKind === feedbackKindEnum.Tip"
            [disabled]="!isFeedackFormValid() || submitDisabled"
            (click)="onSubmit()">{{ 'feedback.save_button' | translate }}</button>

        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<ng-template #loader>
  <div class="form-loader">
    <mat-spinner strokeWidth="2" diameter="50"></mat-spinner>
  </div>
</ng-template>

<!-- <p>Form value: {{ feedbackRecipientsGroup.value | json }}</p> -->
<!-- <p>Form status: {{ feedbackRecipientsGroup.status | json }}</p> -->
<!-- <p>Form status: {{ isFeedackFormValid() }}</p> -->