import { Action } from '@ngrx/store';
import { User } from '../../../../../../shared/model/user.interface'; 
import * as firebase from 'firebase/app';
import { Organization } from '../../../../../../shared/model/organization.interface';
import { AwardType } from '../../../../../../shared/model/award-type.interface';

export enum AuthActionTypes {
    LoginWithEmail = '[Auth] Login with email',
    LoginSuccess = '[Auth] Login success',
    LoginFailure = '[Auth] Login failure',
    Logout = '[Auth] Logout',
    LogoutSuccess = '[Auth] Logout success',
    LogoutFailure = '[Auth] Logout failure',
    SaveLoginRedirectUrl = '[Auth] Save login redirect url',
    LoginRedirect = '[Auth] Login redirect',
    LoadUser = '[Auth] Load user',
    LoadUserSuccess = '[Auth] Load user success',
    LoadUserFailure = '[Auth] Load user failure',
    LoadTenantId = '[Auth] Load tenant id',
    LoadTenantIdSuccess = '[Auth] Load tenant id success',
    LoadTenantIdFailure = '[Auth] Load tenant id failure',
    LoadOrganization = '[Auth] Load organization',
    LoadOrganizationSuccess = '[Auth] Load organization success',
    LoadOrganizationFailure = '[Auth] Load organization failure',
    UpdateUserAwardStats = '[Auth] Update user award stats',
}


export class LoginWithEmail implements Action {
    readonly type = AuthActionTypes.LoginWithEmail;
    constructor(public email: string, public password: string) { }
}

export class LoginSuccess implements Action {
    readonly type = AuthActionTypes.LoginSuccess;
    constructor(public firebaseUser: firebase.User) { }
}

export class LoginFailure implements Action {
    readonly type = AuthActionTypes.LoginFailure;
    constructor(public error: string) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class LogoutSuccess implements Action {
    readonly type = AuthActionTypes.LogoutSuccess;
}

export class LogoutFailure implements Action {
    readonly type = AuthActionTypes.LogoutFailure;
    constructor(public error: any) { }
}

export class LoadUser implements Action {
    readonly type = AuthActionTypes.LoadUser;
    constructor(public userId: string) { }
}

export class LoadUserSuccess implements Action {
    readonly type = AuthActionTypes.LoadUserSuccess;
    constructor(public user: User) { }
}

export class LoadUserFailure implements Action {
    readonly type = AuthActionTypes.LoadUserFailure;
    constructor(public error: any) { }
}

export class LoadTenantId implements Action {
    readonly type = AuthActionTypes.LoadTenantId;
    constructor(public userId: string) { }
}

export class LoadTenantIdSuccess implements Action {
    readonly type = AuthActionTypes.LoadTenantIdSuccess;
    constructor(public tenantId: string) { }
}

export class LoadTenantIdFailure implements Action {
    readonly type = AuthActionTypes.LoadTenantIdFailure;
    constructor(public error: any) { }
}

export class LoadOrganization implements Action {
    readonly type = AuthActionTypes.LoadOrganization;
    constructor() { }
}

export class LoadOrganizationSuccess implements Action {
    readonly type = AuthActionTypes.LoadOrganizationSuccess;
    constructor(public organization: Organization) { }
}

export class LoadOrganizationFailure implements Action {
    readonly type = AuthActionTypes.LoadOrganizationFailure;
    constructor(public error: any) { }
}

export class LoginRedirect implements Action {
    readonly type = AuthActionTypes.LoginRedirect;
    constructor(public url: string) { }
}

export class SaveLoginRedirectUrl implements Action {
    readonly type = AuthActionTypes.SaveLoginRedirectUrl;
    constructor(public url: string) { }
}

export class UpdateUserAwardStats implements Action {
    readonly type = AuthActionTypes.UpdateUserAwardStats;
    constructor(public awardType: AwardType) { }
}

export type AuthActions =
    | LoginWithEmail
    | LoginSuccess
    | LoginFailure
    | SaveLoginRedirectUrl
    | LoginRedirect
    | Logout
    | LogoutSuccess
    | LogoutFailure
    | LoadUser
    | LoadUserSuccess
    | LoadUserFailure
    | LoadTenantId
    | LoadTenantIdSuccess
    | LoadTenantIdFailure
    | LoadOrganization
    | LoadOrganizationSuccess
    | LoadOrganizationFailure
    | UpdateUserAwardStats
    ;
