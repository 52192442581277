import { AuthActions, AuthActionTypes } from './../actions/auth.actions';
import { User } from '../../../../../../shared/model/user.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as firebase from 'firebase/app';
import { Organization } from '../../../../../../shared/model/organization.interface';
import { AwardType } from '../../../../../../shared/model/award-type.interface';

import * as lodash from "lodash";


export interface State {
    // loggedIn: boolean;
    firebaseUser: firebase.User;
    loginRedirectUrl: string;
    loginError: any;
    user: User;
    userError: any;
    userLoaded: boolean;
    isUserLoading: boolean;
    tenantId: string;
    tenantError: any;
    organization: Organization;
    isOrganizationLoading: boolean;
    organizationError: any;
}

export const initialState: State = {
    // loggedIn: false,
    firebaseUser: null,
    loginRedirectUrl: '/',
    loginError: null,
    user: null,
    userLoaded: false,
    isUserLoading: true,
    tenantId: null,
    tenantError: null,
    userError: null,
    organization: null,
    isOrganizationLoading: true,
    organizationError: null
}

export function reducer(
    state = initialState,
    action: AuthActions
): State {

    switch (action.type) {
        case AuthActionTypes.LoginSuccess: {
            return {
                ...state,
                // loggedIn: true,
                firebaseUser: action.firebaseUser,
                loginError: null,
            };
        }

        case AuthActionTypes.LoginFailure: {
            return {
                ...state,
                // loggedIn: false,
                loginError: action.error,
            };
        }

        case AuthActionTypes.SaveLoginRedirectUrl: {
            return {
                ...state,
                loginRedirectUrl: action.url,
            };
        }

        case AuthActionTypes.LoadUserSuccess: {
            return {
                ...state,
                userLoaded: true,
                isUserLoading: false,
                user: action.user,
                // tenantId: action.user.tenantId 
            };
        }

        case AuthActionTypes.LoadUserFailure: {
            return {
                ...state,
                userError: action.error,
            };
        }

        case AuthActionTypes.LoadTenantIdSuccess: {
            return {
                ...state,
                tenantId: action.tenantId,
                tenantError: null
            };
        }

        case AuthActionTypes.LoadTenantIdFailure: {
            return {
                ...state,
                tenantError: action.error,
            };
        }

        case AuthActionTypes.LoadOrganizationSuccess: {
            return {
                ...state,
                organization: action.organization,
                isOrganizationLoading: false,
                organizationError: null
            };
        }

        case AuthActionTypes.LoadOrganizationFailure: {
            return {
                ...state,
                organizationError: action.error,
            };
        }

        case AuthActionTypes.LogoutSuccess: {
            return initialState;
        }

        case AuthActionTypes.UpdateUserAwardStats: {
            return handleUpdateUserAwardStats(action.awardType, state);
        }

        default: {
            return state;
        }
    }
}

function handleUpdateUserAwardStats(awardType: AwardType, state: State) {

    // To avoid undefined when accessing a non-existing property
    const defaultStats = {
        scoreRedeemed: 0,
        awards: 0,
        awardTypes: {}
    }
    const userStats = Object.assign({}, defaultStats, state.user.stats);

    const newStats = {
        scoreRedeemed: userStats.scoreRedeemed + awardType.triggerCount,
        awards: userStats.awards + 1,
        awardTypes: {
            [awardType.id]: userStats.awardTypes[awardType.id] ? userStats.awardTypes[awardType.id] + 1 : 1
        }
    }
    
    // deep merge
    const updatedUser: User = lodash.merge(state.user, { stats: newStats })
    
    const updatedState: State = Object.assign({}, state, { user: updatedUser });

    return updatedState;

}



export const getState = createFeatureSelector<State>('auth');

// export const getLoggedIn = createSelector(
//     getState,
//     (state: State) => state.loggedIn
// );
export const getLoginError = createSelector(
    getState,
    (state: State) => state.loginError
);
export const getUser = createSelector(
    getState,
    (state: State) => state.user
);
export const isUserLoaded = createSelector(
    getState,
    (state: State) => state.userLoaded
);
export const isUserLoading = createSelector(
    getState,
    (state: State) => state.isUserLoading
);
export const getTenantId = createSelector(
    getState,
    (state: State) => state.tenantId
);
export const getFirebaseUser = createSelector(
    getState,
    (state: State) => state.firebaseUser
);
export const getLoginRedirectUrl = createSelector(
    getState,
    (state: State) => state.loginRedirectUrl
);
export const getOrganization = createSelector(
    getState,
    (state: State) => state.organization
);
export const isOrganizationLoading = createSelector(
    getState,
    (state: State) => state.isOrganizationLoading
);
// export const getOrganizationAwardSettings = createSelector(
//     getState,
//     (state: State) => state.organization.awardSettings
// );