import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
  // pure: false
})
export class ReversePipe implements PipeTransform {

  transform(value) {
    if (value) {
      return value.slice().reverse();
    }
    return value;

  }
  // constructor(private differs:IterableDiffers) {
  //   this.differ = this._differs.find(value).create(null);
  // }

  // transform(value) {
  //   const changes = this.differ.diff(value);
  //   if(changes) {
  //     this.cached = value.slice().reverse();
  //   }
  //   return this.cached;    
  // }

}


// @Pipe({
//   name: 'reverse',
//   pure: false
// })
// export class ReversePipe {
//   constructor(private differs:IterableDiffers) {
//     this.differ = this._differs.find(value).create(null);
//   }

//   transform(value) {
//     const changes = this.differ.diff(value);
//     if(changes) {
//       this.cached = value.slice().reverse();
//     }
//     return this.cached;    
//   }
// }