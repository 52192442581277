
import { of, from as observableFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { tap, map, exhaustMap, catchError, switchMap, take } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { DataProviderService } from '../../../shared/services/data-provider.service';
import { User } from '../../../../../../shared/model/user.interface';
import { Credentials } from '../../models/auth.models';
import * as firebase from 'firebase/app';

import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../store/reducers/auth.reducer';

import {
    AuthActionTypes,
    LoginWithEmail,
    LoginFailure,
    LogoutFailure,
    Logout,
    LoginSuccess,
    LogoutSuccess,
    LoadUser,
    LoadUserSuccess,
    LoadUserFailure,
    LoadTenantIdSuccess,
    LoadTenantIdFailure,
    LoadTenantId,
    LoadOrganizationSuccess,
    LoadOrganizationFailure,
    LoadOrganization,
    LoginRedirect
} from '../actions/auth.actions';
import { Organization } from '../../../../../../shared/model/organization.interface';

@Injectable()
export class AuthEffects {

    constructor(
        private actions: Actions,
        private authService: AuthService,
        private router: Router,
        private dataProvider: DataProviderService,
        private store: Store<fromAuth.State>,
    ) { }

    @Effect()
    loginWithEmail: Observable<Action> = this.actions.pipe(
        ofType(AuthActionTypes.LoginWithEmail),
        switchMap((action: LoginWithEmail) =>
            this.authService.loginWithEmail(action.email, action.password).pipe(
                map((firebaseUserCredential: firebase.auth.UserCredential) => new LoginSuccess(firebaseUserCredential.user)),
                switchMap(() =>
                    this.store.select(fromAuth.getLoginRedirectUrl).pipe(
                        take(1),
                        map((redirectUrl: string) => new LoginRedirect(redirectUrl))
                    )
                ),
                // catchError(error => of(new LoginFailure(error)))
                catchError(error => {
                    console.error(error);
                    return of(new LoginFailure(error));
                })
            )
        )
    );

    @Effect()
    loginSuccess: Observable<Action> = this.actions.pipe(
        ofType(AuthActionTypes.LoginSuccess),
        switchMap((action: LoginSuccess) => [
            new LoadTenantId(action.firebaseUser.uid),
            new LoadUser(action.firebaseUser.uid),
            new LoadOrganization()
        ]),
    );

    @Effect({ dispatch: false })
    loginRedirect: Observable<Action> = this.actions.pipe(
        ofType(AuthActionTypes.LoginRedirect),
        tap((action: LoginRedirect) => this.router.navigate([action.url]))
    );

    @Effect()
    loadUser: Observable<Action> = this.actions.pipe(
        ofType(AuthActionTypes.LoadUser),
        switchMap((action: LoadUser) => {
            return this.dataProvider.getUser(action.userId).pipe(
                // tap((user: User) => console.log('auth', user)),
                map((user: User) => new LoadUserSuccess(user)),
                // catchError(error => of(new LoadUserFailure(error)))
                catchError(error => {
                    console.error(error);
                    return of(new LoadUserFailure(error));
                })
            )
        })
    );

    @Effect()
    loadTenant: Observable<Action> = this.actions.pipe(
        ofType(AuthActionTypes.LoadTenantId),
        switchMap((action: LoadTenantId) => {
            return this.dataProvider.getTenantAuth(action.userId).pipe(
                map((tenantAuth: any) => new LoadTenantIdSuccess(tenantAuth.tenantId)),
                // catchError(error => of(new LoadTenantIdFailure(error)))
                catchError(error => {
                    console.error(error);
                    return of(new LoadTenantIdFailure(error));
                })
            )
        })
    );

    @Effect()
    loadOrganization: Observable<Action> = this.actions.pipe(
        ofType(AuthActionTypes.LoadOrganization),
        switchMap((action: LoadOrganization) => {
            return this.dataProvider.getOrganization().pipe(
                map((organization: Organization) => new LoadOrganizationSuccess(organization)),
                catchError(error => {
                    console.error(error);
                    return of(new LoadOrganizationFailure(error));
                })
            )
        })
    );

    @Effect()
    logout: Observable<Action> = this.actions.pipe(
        ofType(AuthActionTypes.Logout),
        switchMap((action: Logout) => {
            return observableFrom(this.authService.logout()).pipe(
                map(() => new LogoutSuccess()),
                // catchError(error => of(new LogoutFailure(error)))
                catchError(error => {
                    console.error(error);
                    return of(new LogoutFailure(error));
                })
            )
        })
    );

    @Effect({ dispatch: false })
    logoutSuccess: Observable<Action> = this.actions.pipe(
        ofType(AuthActionTypes.LogoutSuccess),
        tap(() => this.router.navigate(['/login']))
    );

}
