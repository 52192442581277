import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavTopComponent } from './containers/nav-top/nav-top.component';
import { SearchModule } from '../search/search.module';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SidenavComponent } from './components/sidenav/sidenav.component';

import { TranslateModule } from '@ngx-translate/core';
import { OnboardingButtonComponent } from './components/onboarding-button/onboarding-button.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SearchModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatExpansionModule,
    TranslateModule.forChild()
  ],
  declarations: [
    NavTopComponent,
    SidenavComponent,
    OnboardingButtonComponent
  ],
  exports: [
    NavTopComponent,
    SidenavComponent
  ]
})
export class NavigationModule { }
