import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-anonymous-avatar',
  templateUrl: './user-anonymous-avatar.component.html',
  styleUrls: ['./user-anonymous-avatar.component.scss']
})
export class UserAnonymousAvatarComponent implements OnInit {
  
  @Input()
  isLoading: boolean;

  constructor() { }

  ngOnInit() {
  }

}
