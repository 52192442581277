import { FeedbackRequestTokenStatus } from './../../../../../../shared/model/feedback-request-token-status.enum';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { FeedbackRequestToken } from './../../../../../../shared/model/feedback-request-token.interface';
import { BotEventType } from '../../../../../../shared/model/bot-event-type.enum';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { take, filter, switchMap, tap, map } from 'rxjs/operators';
import { User } from '../../../../../../shared/model/user.interface';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/store/reducers/auth.reducer';

@Component({
  selector: 'app-bot-page',
  templateUrl: './bot-page.component.html',
  styleUrls: ['./bot-page.component.scss']
})
export class BotPageComponent implements OnInit {

  // Url parameters
  eventType: BotEventType;
  requestToken: string;
  isRequestTokenValid: boolean = true;

  feedbackRequestToken: FeedbackRequestToken;
  authUser: User;

  feedbackRequestTokenStatus = FeedbackRequestTokenStatus;

  constructor(
    private authStore: Store<fromAuth.State>,
    private route: ActivatedRoute,
    private dataProvider: DataProviderService
  ) { }

  ngOnInit() {
    // console.log('params:', this.route.snapshot.params);
    // this.eventType = this.route.snapshot.params['eventType'];
    // this.requestToken = this.route.snapshot.params['requestToken'];

    // console.log('parames:', this.route.snapshot.queryParams);
    this.eventType = this.route.snapshot.queryParams['eventType'];
    this.requestToken = this.route.snapshot.queryParams['requestToken'];

    // If request token id is empty
    if (!this.requestToken) {
      this.isRequestTokenValid = false;
    }
    else {
      this.dataProvider.getFeedbackRequestToken(this.requestToken).pipe(
        take(1),
        map(feedbackRequestToken => {
          if (feedbackRequestToken) {
            this.feedbackRequestToken = feedbackRequestToken
          } else {
            this.isRequestTokenValid = false;
          }
        })
      ).subscribe();

      this.authStore.select(fromAuth.getUser).pipe(
        filter(authUser => !!authUser), // as first value can be null
        take(1),
        map(authUser => this.authUser = authUser)
      ).subscribe();
    }

  }



}
