<div class="nav-top-wrapper">

  <nav class="row justify-content-center nav-top">
    <div class="col align-self-center">

      <ng-container *ngIf="organization | async as organization">
        <ng-container *ngIf="authUser | async as authUser">

          <div class="nav-links-wrapper">
            <div class="nav-links">

              <!--Feed-->
              <a mat-flat-button color="primary" routerLink="/feed" routerLinkActive="active"
                *ngIf="organization.settings.publicPraisesPageEnabled">
                <i class="material-icons">chat</i>
                <!-- <i class="material-icons">thumb_up</i> -->
                {{ 'navigation.feed' | translate }}
              </a>

              <!--Suggestions-->
              <a mat-flat-button color="primary" routerLink="/ideas" routerLinkActive="active"
                *ngIf="organization.settings.suggestionsEnabled">
                <i class="material-icons">inbox</i>
                {{ 'navigation.suggestions' | translate }}
              </a>

              <!--Teams-->
              <a mat-flat-button color="primary" routerLink="/teams" routerLinkActive="active"
                *ngIf="organization.settings.teamsPageEnabled">
                <i class="material-icons">supervisor_account</i>
                {{ 'navigation.teams' | translate }}
              </a>

              <!--Awards-->
              <a mat-flat-button color="primary" routerLink="/rewards" routerLinkActive="active"
                *ngIf="organization.settings.awardsEnabled">
                <i class="material-icons">card_giftcard</i>
                {{ 'navigation.awards' | translate }}
              </a>

              <!--Leaderboard-->
              <a mat-flat-button color="primary" routerLink="/leaderboard" routerLinkActive="active"
                *ngIf="organization.settings.leaderboardPageEnabled">
                <i class="material-icons">insert_chart_outlined</i>
                {{ 'navigation.leaderboard' | translate }}
              </a>

              <!--Network-->
              <!-- <a mat-flat-button color="primary" routerLink="/network">
                <i class="material-icons">bubble_chart</i>
                {{ 'navigation.network' | translate }}
              </a> -->

              <!--Search-->
              <a mat-flat-button color="primary" (click)="openSearch()" routerLinkActive="active"
                *ngIf="organization.settings.publicUserProfilePageEnabled">
                <i class="material-icons">search</i>
                {{ 'navigation.search' | translate }}
              </a>
            </div>

            <div class="text-right">
              <!-- <app-onboarding-button [authUser]="authUser" class="nav-onboarding"></app-onboarding-button> -->

              <a mat-flat-button color="primary" class="nav-admin" routerLink="/admin" routerLinkActive="active"
                *ngIf="authUser.orgRole === 'admin'">
                <i class="material-icons">settings</i>
                {{ 'navigation.admin' | translate }}
              </a>

              <button mat-button [matMenuTriggerFor]="menu" class="nav-dropdown" *ngIf="authUser">
                <img class="user-avatar" [attr.src]="authUser.avatar" *ngIf="authUser.avatar; else avatarPlaceholder">
                <ng-template #avatarPlaceholder>
                  <mat-icon>account_circle</mat-icon>
                </ng-template>
                <mat-icon>arrow_drop_down</mat-icon>
              </button>

              <button mat-icon-button (click)="toggleSidenav()" class="nav-mobile">
                <mat-icon>menu</mat-icon>
              </button>

            </div>

          </div>

          <app-search *ngIf="(isSearchShown | async)" (closeSearch)="closeSearch()"></app-search>

          <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/users/{{ authUser.id }}"
              *ngIf="organization.settings.privateUserProfilePageEnabled">
              <mat-icon>account_circle</mat-icon>
              {{ 'navigation.my_profile' | translate }}
            </button>
            <button mat-menu-item routerLink="/settings">
              <mat-icon>tune</mat-icon>
              {{ 'navigation.my_settings' | translate }}
            </button>
            <button mat-menu-item routerLink="/" (click)="logout()">
              <mat-icon>power_settings_new</mat-icon>
              <span>{{ 'navigation.logout' | translate }}</span>
            </button>
          </mat-menu>

        </ng-container>
      </ng-container>

    </div>
  </nav>
</div>