import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from './../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../../auth/store/reducers/auth.reducer';
import * as AuthActions from '../../../auth/store/actions/auth.actions';

@Component({
  selector: 'app-login-password-reset',
  templateUrl: './login-password-reset.component.html',
  styleUrls: ['./login-password-reset.component.scss']
})
export class LoginPasswordResetComponent implements OnInit {

  newPasswordForm: FormGroup;
  sub: Subscription;

  // Just a code Firebase uses to prove that this is a real password reset.
  // https://medium.com/@c_innovative/implementing-password-reset-can-be-a-tricky-but-inevitable-task-737badfb7bab
  actionCode: string;

  showForm: boolean;
  showSuccessMessage: boolean;
  showInvalidCodeMessage: boolean;
  showLoader: boolean;
  email: string;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private store: Store<fromAuth.State>,
  ) { }

  ngOnInit() {
    this.sub = this.activatedRoute.queryParams.pipe(
      map(params => {

        this.showForm = false;
        this.showSuccessMessage = false;
        this.showInvalidCodeMessage = false;
        this.showLoader = true;

        this.actionCode = params['oobCode'];

        if (this.actionCode) {
          this.authService.isPasswordResetCodeValid(this.actionCode).then(email => {
            if (email) {
              this.email = email;
              this.createNewPasswordForm();
              this.showLoader = false;
              this.showForm = true;
            }
            else {
              this.showLoader = false;
              this.showInvalidCodeMessage = true;
            }
          })
          // .catch(error => {
          //   console.log('!!catch', error);
          // });

        }
        else {
          this.showLoader = false;
          this.showInvalidCodeMessage = true;
        }

      })

    ).subscribe();

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  createNewPasswordForm() {
    this.newPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern(this.authService.passwordPolicyRegex)]],
    });
  }

  onSubmit() {
    this.showForm = false;
    this.showLoader = true;

    const newPassword = this.newPasswordForm.value.newPassword;

    this.authService.resetPassword(this.actionCode, newPassword).then(result => {
      if (result) {
        this.showLoader = false;
        this.showSuccessMessage = true;

        // Login with new password
        this.store.dispatch(new AuthActions.LoginWithEmail(this.email, newPassword));
      }
      else {
        this.showLoader = false;
        this.showInvalidCodeMessage = true;
      }
    });
  }

}
