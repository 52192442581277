import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login-password-reset-request',
  templateUrl: './login-password-reset-request.component.html',
  styleUrls: ['./login-password-reset-request.component.scss']
})
export class LoginPasswordResetRequestComponent implements OnInit {

  resetForm: FormGroup;
  showForm: boolean;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  showLoader: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

 ngOnInit() {
    this.createResetForm();
    this.showForm = true;
    this.showLoader = false;
    this.showSuccessMessage = false;
    this.showErrorMessage = false;
  }

  createResetForm() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    this.showForm = false;
    this.showLoader = true;

    this.authService.sendPasswordResetEmail(this.resetForm.value.email).then(result => {
      if (result) {
        this.showLoader = false;
        this.showSuccessMessage = true;
      }
      else {
        this.showLoader = false;
        this.showErrorMessage = true;
      }
    });

  }


}
