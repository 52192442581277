<div class="tag-select-wrapper card-panel">
  <mat-form-field class="full-width tag-select-input" appearance="outline">
    <mat-label>{{ botActionFeedbackTagSelect.placeholder }}</mat-label>

    <mat-chip-list #chipList>
      <mat-chip *ngFor="let item of selectedItems" [selectable]="true" [removable]="true"
        (removed)="removeItemFromSelected(item)" class="selected-item">
        <app-svg-icon [icon]="item.icon"></app-svg-icon>
        {{item.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <mat-autocomplete #autoComplete>
        <mat-option class="tag-select-item" *ngFor="let item of filteredItems" [value]="item.name">

          <div class="tag-item" (click)="addItemToSelected(item)">
              <div class="tag-name">
                <app-svg-icon [icon]="item.icon"></app-svg-icon>
                {{item.name}}
              </div>
          </div>

        </mat-option>
      </mat-autocomplete>


      <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="resetInput($event)" [matAutocomplete]="autoComplete"
        [formControl]="searchValue">

    </mat-chip-list>
  </mat-form-field>

  <button mat-icon-button class="submit-button" (click)="onSubmit()" [disabled]="selectedItems.length < 1">
    <mat-icon class="submit-button-icon">send</mat-icon>
  </button>
</div>