export function searchTeams(objectArray, query) {
    query = query.trim().toLowerCase();
    let quickResult = objectArray.filter(obj => Object.keys(obj).some(key => {
      if(key === 'name' || key === 'description'){
        return obj[key] ? obj[key].toString().toLowerCase().includes(query) : false
      }
      return false;
    }));
  
    return quickResult;
  }