import { Store } from '@ngrx/store';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { tap, startWith, map, take, filter, switchMap } from 'rxjs/operators';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, forwardRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { User } from '../../../../../../shared/model/user.interface';
import * as fromAuth from '../../../auth/store/reducers/auth.reducer';
import { searchUsers } from 'app/shared/helpers/search-users';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-select-form-control',
  templateUrl: './user-select-form-control.component.html',
  styleUrls: ['./user-select-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectFormControlComponent),
      multi: true
    }
  ]
})
export class UserSelectFormControlComponent implements OnInit, ControlValueAccessor {

  @Input()
  placeholder: string;

  @Input()
  showAuthUser?: boolean = false;

  @Output()
  valueChange: EventEmitter<User[]> = new EventEmitter();

  usersSub: Subscription;
  users: User[] = [];
  selectedUsers: User[] = [];
  filteredUsers: User[] = [];
  searchSub: Subscription;
  searchValue = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dataProvider: DataProviderService,
    private store: Store<fromAuth.State>,
  ) { }

  ngOnInit() {

    // Take auth user
    this.usersSub = this.store.select(fromAuth.getUser).pipe(
      filter(authUser => !!authUser),
      take(1),
      switchMap((authUser: User) =>
        // Get all active users and filter auth user (don't allow to recognize himself)
        this.dataProvider.getActiveUsers().pipe(
          // take(1), 
          map((users: User[]) => {
            // this.users = users;
            if (this.showAuthUser) {
              this.users = users;
            }
            else {
              // Filter auth user
              this.users = users.filter(user => user.id !== authUser.id);
            }
            this.filteredUsers = this.users;
          })
        )
      )).subscribe();

    // Listen for search value changes
    this.searchSub = this.searchValue.valueChanges.pipe(
      map(value => {
        if (value) {
          this.filteredUsers = searchUsers(this.users, value);
        } else {
          this.filteredUsers = this.users;
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.usersSub.unsubscribe();
    this.searchSub.unsubscribe();
  }

  /**   
   * ControlValueAccessor interface
   * Writes a new value from the form model into the view or (if needed) DOM property
   */
  writeValue(value: User[]) {
    // if (value !== undefined) {
    //   value.map((user: User) => this.addUserToSelected(user));
    // }
  }

  /**
   * ControlValueAccessor interface
   * Set the function to be called when the control receives a change event.
   */
  propagateChange = (_: any) => { };
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**    
   * ControlValueAccessor interface
  * Set the function to be called when the control receives a touch event.
  */
  registerOnTouched() { }


  addUserToSelected(user: User) {
    this.selectedUsers.push(user);

    // Prevents from selecting the same user twice
    this.removeUserFromAvailable(user);

    // Clear filtered users 
    this.filteredUsers = this.users;

    // this.selectedUsersChange.emit(this.selectedUsers);
    this.propagateChange(this.selectedUsers);
    this.valueChange.emit(this.selectedUsers);
  }
  removeUserFromSelected(user: User) {
    const index = this.selectedUsers.indexOf(user);

    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
      this.addUserToAvailable(user);

      // this.selectedUsersChange.emit(this.selectedUsers);
      this.propagateChange(this.selectedUsers);
      this.valueChange.emit(this.selectedUsers);
    }
  }

  addUserToAvailable(user: User) {
    this.users.push(user);
    // this.users.sort( (a,b) => b.lastName.toLowerCase() < a.lastName.toLowerCase() );

    // sort ascending by last name
    this.users.sort((a: User, b: User) => b.lastName.toLowerCase() < a.lastName.toLowerCase() ? 1 : -1);
  }
  removeUserFromAvailable(user: User) {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  resetInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

}






