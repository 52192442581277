import { User } from './../../../../../../shared/model/user.interface';
import { Component, OnInit, OnChanges, Input, ChangeDetectionStrategy } from '@angular/core';
import { Feedback } from '../../../../../../shared/model/feedback.interface';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss'],
})
export class FeedbackListComponent implements OnInit {

  @Input()
  feedbackList: Feedback[];
  
  @Input()
  isLoading: boolean;

  @Input()
  authUser: User;

  constructor() { }

  ngOnInit() { }


}
