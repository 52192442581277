import { Level } from '@nsalaun/ng-logger';

// Configuration = development
export const environment = {
  production: false,
  local: false,
  loggingLevel: Level.DEBUG,
  enableReduxDevTools: true,
  enableServiceWorker: true,
  apiUrl: 'https://us-central1-getkudos-33f7c.cloudfunctions.net',
  firebaseConfig: {
    apiKey: "AIzaSyCHaf3grTclxDyl3TvjY4DZVqwK-hpiLbk",
    authDomain: "getkudos-33f7c.firebaseapp.com",
    databaseURL: "https://getkudos-33f7c.firebaseio.com",
    projectId: 'getkudos-33f7c',
    storageBucket: "getkudos-33f7c.appspot.com",
    messagingSenderId: "806204046910"
  }
};
