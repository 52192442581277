import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class MessageBarService {

    constructor(
        private messageBar: MatSnackBar
    ) { }

    showMessageBar(message: string) {
        this.messageBar.open(message, '', {
            duration: 1000,
        });
    }

}