<div class="row justify-content-center">
  <div class="col-lg-8 col-xl-6">
    <div class="bot-page-wrapper">
      
      <ng-container *ngIf="!isRequestTokenValid">
        <div class="text-muted text-center">
          <i class="material-icons error-icon">error_outline</i>
          <p>
            {{ 'bot.feedback_request_invalid' | translate }}
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="authUser && feedbackRequestToken">
        <div class="text-muted text-center" *ngIf="authUser.id !== feedbackRequestToken.recipientPartial.id">
          <i class="material-icons error-icon">error_outline</i>
          <p>
            {{ 'bot.feedback_request_not_sent_to_you' | translate }}
          </p>
        </div>

        <div class="text-muted text-center"
          *ngIf="authUser.id === feedbackRequestToken.recipientPartial.id && feedbackRequestToken.status === feedbackRequestTokenStatus.Responded">
          <i class="material-icons error-icon">error_outline</i>
          <p>
            {{ 'bot.feedback_request_already_replied' | translate }}
          </p>
        </div>

        <app-bot [eventType]="eventType" [requestToken]="requestToken"
          *ngIf="authUser.id === feedbackRequestToken.recipientPartial.id && feedbackRequestToken.status === feedbackRequestTokenStatus.Pending">
        </app-bot>
      </ng-container>


    </div>
  </div>
</div>