import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginPasswordResetComponent } from './components/login-password-reset/login-password-reset.component';
import { LoginPasswordResetRequestComponent } from './components/login-password-reset-request/login-password-reset-request.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import { Routes, RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './store/reducers/auth.reducer';
import { AuthEffects } from './store/effects/auth.effects';
// import { routes } from './auth.routing';
import { NotificationService } from '../notification/services/notification.service';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    // RouterModule.forChild(routes),
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature('auth', reducer),
    EffectsModule.forFeature([AuthEffects]),
    TranslateModule.forChild()
  ],
  declarations: [
    LoginPageComponent,
    LoginFormComponent,
    LoginPasswordResetComponent,
    LoginPasswordResetRequestComponent
  ],
  providers: [
    NotificationService,
  ]

})
export class AuthModule { }