import { Action } from '@ngrx/store';
import { FeedbackType } from '../../../../../../shared/model/feedback-type.interface';


export enum FeedbackTypesActionTypes {
    LoadFeedbackTypes = '[Feedback] Load feedback types',
    LoadFeedbackTypesSuccess = '[Feedback] Load feedback types success',
    LoadFeedbackTypesFailure = '[Feedback] Load feedback types failure',
}

export class LoadFeedbackTypes implements Action {
    readonly type = FeedbackTypesActionTypes.LoadFeedbackTypes;
}

export class LoadFeedbackTypesSuccess implements Action {
    readonly type = FeedbackTypesActionTypes.LoadFeedbackTypesSuccess;
    constructor(public feedbackTypes: FeedbackType[]) { }
}

export class LoadFeedbackTypesFailure implements Action {
    readonly type = FeedbackTypesActionTypes.LoadFeedbackTypesFailure;
    constructor(public error: any) { }
}

export type FeedbackTypesActions 
    = LoadFeedbackTypes
    | LoadFeedbackTypesSuccess 
    | LoadFeedbackTypesFailure
    ;
