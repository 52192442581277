<div class="search-wrapper">
  <div class="search text-muted">

    <mat-form-field class="full-width" appearance="outline">

      <input matInput placeholder="{{ 'search.search_user' | translate }}..." aria-label="szukaj"
        [matAutocomplete]="auto" [formControl]="searchValue">

      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let user of filteredUsers" [value]="user.firstName + ' ' + user.lastName">

          <!-- <div class="user-item" (click)="userClicked(user.id)" routerLink="/users/{{user.id}}"> -->
          <div class="user-item" (click)="onUserClicked(user.id)">
            <img class="user-avatar" [attr.src]="user.avatar" *ngIf="user.avatar; else avatarPlaceholder">

            <ng-template #avatarPlaceholder>
              <div class="user-avatar-placeholder">{{ user.firstName | firstchar}}{{ user.lastName | firstchar}}</div>
            </ng-template>

            <div>
              <div class="user-name">{{user.firstName}} {{user.lastName}}</div>
              <div class="user-jobTitle text-muted">{{user.jobTitle}}</div>
              <!-- <div class="user-jobTitle text-muted">{{user.stats?.score}}</div> -->
            </div>
          </div>

        </mat-option>
      </mat-autocomplete>

      <mat-icon matSuffix class="search-close" (click)="onCloseSearch()">close</mat-icon>

    </mat-form-field>

  </div>
</div>