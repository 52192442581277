<div class="message-wrapper">
  <ng-container *ngIf="message">
    <div class="bot-icon" *ngIf="message.sentBy === botMessageAuthor.Bot">
      <img src="/assets/img/icon.svg" alt="">
    </div>
    <div class="message"
      [ngClass]="{ 'from': message.sentBy === botMessageAuthor.Bot, 'to':   message.sentBy === botMessageAuthor.User }">

      <ng-container [ngSwitch]="message.originActionType">
        <div *ngSwitchCase="botActionType.TextInput">
          {{ message.text }}
        </div>

        <div *ngSwitchCase="botActionType.Button">
          {{ message.text }}
        </div>

        <div *ngSwitchCase="botActionType.UserSelect">
          <ng-container *ngFor="let user of message.originActionParameters.users, index as i">
            {{ user.firstName }} {{ user.lastName }}<ng-container
              *ngIf="i < message.originActionParameters.users.length-1">, </ng-container>
          </ng-container>
        </div>

        <div *ngSwitchCase="botActionType.TeamSelect">
          <ng-container *ngFor="let team of message.originActionParameters.teams, index as i">
            {{ team.name }}
            <ng-container *ngIf="i < message.originActionParameters.teams.length-1">, </ng-container>
          </ng-container>
        </div>

        <div *ngSwitchCase="botActionType.FeedbackTypeSelect" class="feedback-type">
          <app-svg-icon [icon]="message.originActionParameters.feedbackType.icon"></app-svg-icon>
          {{ message.originActionParameters.feedbackType.points }}
        </div>

        <div *ngSwitchDefault>
          {{ message.text }}
        </div>
      </ng-container>

    </div>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="bot-icon">
      <img src="/assets/img/icon.svg" alt="">
    </div>
    <div class="message from">
      <div class="loader">
        <div class="dot-pulse"></div>
      </div>
    </div>
  </ng-container>
</div>