import { Team } from './../../../../../../shared/model/team.interface';
import { FeedbackRequestStats } from './../../../../../../shared/model/feedback-request-stats.interface';
import { FeedbackRequest } from './../../../../../../shared/model/feedback-request.interface';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { User } from '../../../../../../shared/model/user.interface';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { take, map } from 'rxjs/operators';
import { UserPartial } from '../../../../../../shared/model/user-partial.interface';
import { FeedbackRequestRecipientType } from '../../../../../../shared/model/feedback-request-recipient-type.enum';


@Component({
  selector: 'app-request-feedback-form',
  templateUrl: './request-feedback-form.component.html',
  styleUrls: ['./request-feedback-form.component.scss'],
})
export class RequestFeedbackFormComponent implements OnInit {

  @Input()
  preselectedRecipient: User;

  @Input()
  author: User;

  @Output()
  cancel = new EventEmitter();

  @Output()
  submit = new EventEmitter();

  submitDisabled: boolean = false;

  feedbackRequestRecipientTypeEnum = FeedbackRequestRecipientType;

  feedbackRequestRecipientTypeGroup: FormGroup;
  feedbackRequestRecipientsGroup: FormGroup;
  feedbackRequestRecipientTeamsGroup: FormGroup;
  feedbackRequestCommentGroup: FormGroup;

  dynamicValidationSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private dataProvider: DataProviderService
  ) { }


  ngOnInit() {
    this.createFeedbackForm();
  }
  ngOnDestroy() {
    this.dynamicValidationSub.unsubscribe();
  }


  createFeedbackForm() {
    this.feedbackRequestRecipientsGroup = this.formBuilder.group({
      feedbackRequestRecipientType: [FeedbackRequestRecipientType.Users, Validators.required],
      feedbackRequestRecipientUsers: ['', Validators.required],
      feedbackRequestRecipientTeams: [''],
    });

    // Dynamic validations
    const feedbackRequestRecipientUsersControl = this.feedbackRequestRecipientsGroup.get('feedbackRequestRecipientUsers');
    const feedbackRequestRecipientTeamsControl = this.feedbackRequestRecipientsGroup.get('feedbackRequestRecipientTeams');
    this.dynamicValidationSub = this.feedbackRequestRecipientsGroup.get('feedbackRequestRecipientType').valueChanges
      .subscribe(feedbackRequestRecipientType => {
        switch (feedbackRequestRecipientType) {
          case FeedbackRequestRecipientType.Users:
            feedbackRequestRecipientUsersControl.setValidators([Validators.required]);
            feedbackRequestRecipientTeamsControl.setValidators(null);
            feedbackRequestRecipientUsersControl.reset();
            feedbackRequestRecipientTeamsControl.reset();
            feedbackRequestRecipientUsersControl.updateValueAndValidity();
            feedbackRequestRecipientTeamsControl.updateValueAndValidity();
            break;
          case FeedbackRequestRecipientType.Teams:
            feedbackRequestRecipientUsersControl.setValidators(null);
            feedbackRequestRecipientTeamsControl.setValidators([Validators.required]);
            feedbackRequestRecipientUsersControl.reset();
            feedbackRequestRecipientTeamsControl.reset();
            feedbackRequestRecipientUsersControl.updateValueAndValidity();
            feedbackRequestRecipientTeamsControl.updateValueAndValidity();
            break;
        }
      });

    this.feedbackRequestCommentGroup = this.formBuilder.group({
      comment: ['', Validators.required],
    });
  }

  onSubmit() {
    this.submitDisabled = true;

    // Different way of saving feedback request based on recipients (users vs teams)
    const recipientsIds: string[] = [];
    const recipientsPartials: UserPartial[] = [];
    switch (this.feedbackRequestRecipientsGroup.value.feedbackRequestRecipientType) {
      case FeedbackRequestRecipientType.Users:
        this.feedbackRequestRecipientsGroup.value.feedbackRequestRecipientUsers.map((feedbackRequestRecipientUser: User) => {
          recipientsIds.push(feedbackRequestRecipientUser.id);
          recipientsPartials.push({
            id: feedbackRequestRecipientUser.id,
            firstName: feedbackRequestRecipientUser.firstName,
            lastName: feedbackRequestRecipientUser.lastName,
            email: feedbackRequestRecipientUser.email,
            teamId: feedbackRequestRecipientUser.teamId,
            jobTitle: feedbackRequestRecipientUser.jobTitle,
            avatar: feedbackRequestRecipientUser.avatar,
          })
        });

        // console.log('!!!!!!!!!!!! author id', this.author.id)
        // console.log('!!!!!!!!!!!! recipient count', recipientsIds.length)
        this.saveFeedbackRequest(this.prepareFeedbackRequest(recipientsIds, recipientsPartials));

        break;
      case FeedbackRequestRecipientType.Teams:
        // Get users from seleted teams
        this.feedbackRequestRecipientsGroup.value.feedbackRequestRecipientTeams.map((feedbackRequestRecipientTeam: Team) => {
          this.dataProvider.getActiveUsersByTeamId(feedbackRequestRecipientTeam.id).pipe(
            take(1),
            map((users: User[]) => {
              users.map((user: User) => {
                if (user.id !== this.author.id) { // Skip feedback request author
                  recipientsIds.push(user.id);
                  recipientsPartials.push({
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    teamId: user.teamId,
                    jobTitle: user.jobTitle,
                    avatar: user.avatar,
                  })
                }
              });

              //TODO: dokończyć
              // console.log('!!!!!!!!!!!! author id', this.author.id)
              // console.log('!!!!!!!!!!!! recipient count', recipientsIds.length)
              // this.saveFeedbackRequest(this.prepareFeedbackRequest(recipientsIds, recipientsPartials));

            })
          ).subscribe()
        })
        break;
    }
  }

  prepareFeedbackRequest(recipientsIds: string[], recipientsPartials: UserPartial[]): FeedbackRequest{
    const authorPartial: UserPartial = {
      id: this.author.id,
      firstName: this.author.firstName,
      lastName: this.author.lastName,
      email: this.author.email,
      teamId: this.author.teamId,
      jobTitle: this.author.jobTitle,
      avatar: this.author.avatar,
    }

    const stats: FeedbackRequestStats = {
      tokens: recipientsPartials.length,
      tokensResponded: 0
    }

    const feedbackRequest: FeedbackRequest = {
      comment: this.feedbackRequestCommentGroup.value.comment,
      anonymousAuthor: false,
      authorId: this.author.id,
      authorPartial: authorPartial,
      feedbackUserId: this.author.id,
      privateFeedback: true,

      anonymousResponse: false,
      recipientType: FeedbackRequestRecipientType.Users,
      recipientsIds: recipientsIds,
      recipientsPartials: recipientsPartials,

      stats: stats
    }

    return feedbackRequest;
  }

  onCancel() {
    this.cancel.emit();
  }

  getActiveUsersByTeamId(teamId: string) {
    this.dataProvider.getActiveUsersByTeamId(teamId).pipe(
      take(1)
    ).subscribe()
  }

  saveFeedbackRequest(feedbackRequest: FeedbackRequest) {
    this.dataProvider.addFeedbackRequest(feedbackRequest).pipe(
      take(1),
      map((result: boolean) => {
        if (result) {
          this.submit.emit();
        }
      })
    ).subscribe();
  }



}
