import { tap, startWith, map, take } from 'rxjs/operators';
import { DataProviderService } from './../shared/services/data-provider.service';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '../../../../shared/model/user.interface';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {

  @Output()
  selectedUsersChange: EventEmitter<User[]> = new EventEmitter();

  users: User[] = [];
  selectedUsers: User[] = [];
  filteredUsers: User[] = [];
  searchValue = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dataProvider: DataProviderService,

  ) { }

  ngOnInit() {

    // Load users
    this.dataProvider.getActiveUsers().pipe(
      take(1),
      map((users: User[]) => {
        this.users = users;
        this.filteredUsers = users;
      })
    )
    .subscribe();

    // Listen for search value changes
    this.searchValue.valueChanges.pipe(
      tap(value => console.log('value', value)),
      startWith(''),
      map(value => {
        if (value) {
          this.filteredUsers = search(this.filteredUsers, value.trim().toLowerCase());;
        } else {
          this.filteredUsers = this.users;
        }
      })
    ).subscribe();
  }

  onCloseSearch() {
    // this.closeSearch.emit();
  }

  onUserClicked(userId: string){
    // this.closeSearch.emit();
    // this.router.navigate(['/users', userId]);
  }
  

  addUserToSelected(user: User) {
    this.selectedUsers.push(user);
    this.removeUserFromAvailable(user); // prevents from selecting the same user twice
    this.selectedUsersChange.emit(this.selectedUsers);
  }
  removeUserFromSelected(user: User) {
    const index = this.selectedUsers.indexOf(user);
    
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
      this.addUserToAvailable(user);
      this.selectedUsersChange.emit(this.selectedUsers);
    }
  }
  
  addUserToAvailable(user: User) {
    this.users.push(user);
    // this.users.sort( (a,b) => b.lastName.toLowerCase() < a.lastName.toLowerCase() );

    // sort ascending by last name
    this.users.sort( (a: User,b: User) => b.lastName.toLowerCase() < a.lastName.toLowerCase() ? 1 : -1 );
  }
  removeUserFromAvailable(user: User) {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  resetInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  submit(){
    console.log('submit');
  }

}



/**
  * Helper function used for searching
  * @param arr 
  * @param val 
  */
 function search(arr, val): any[] {
  return val.split(' ').reduce((previousValue, currentValue) => {
    return previousValue.filter(el => searchValueInPropertiesObj(el, currentValue))
  }, arr);
}

/**
 * Helper fuction used for seraching
 */
function searchValueInPropertiesObj(obj, val) {
  return Object.keys(obj)
    // .some(key => obj[key].toString().toLowerCase().indexOf(val) !== -1)
    .some(key => obj[key] && obj[key].toString().toLowerCase().indexOf(val) !== -1)
}
