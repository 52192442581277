<div class="user-select-wrapper card-panel">
  <mat-form-field class="full-width user-select-input" appearance="outline">
    <mat-label>{{ botActionUserSelect.placeholder }}</mat-label>

    <mat-chip-list #chipList>
      <mat-chip *ngFor="let user of selectedUsers" [selectable]="true" [removable]="true"
        (removed)="removeUserFromSelected(user)">
        {{user.firstName}} {{user.lastName}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <mat-autocomplete #autoComplete>
        <mat-option class="user-select-item" *ngFor="let user of filteredUsers" [value]="user.firstName + ' ' + user.lastName">

          <!-- <app-user-avatar-with-info [user]="user" (click)="addUserToSelected(user)"></app-user-avatar-with-info> -->
          <!-- <div class="user-item" (click)="userClicked(user.id)" routerLink="/users/{{user.id}}"> -->
         
            <div class="user-item" (click)="addUserToSelected(user)">
            <img class="user-avatar" [attr.src]="user.avatar" *ngIf="user.avatar; else avatarPlaceholder">

            <ng-template #avatarPlaceholder>
              <div class="user-avatar-placeholder">{{ user.firstName | firstchar}}{{ user.lastName | firstchar}}</div>
            </ng-template>

            <div>
              <div class="user-name">{{user.firstName}} {{user.lastName}}</div>
              <div class="user-jobTitle text-muted">{{user.jobTitle}}</div>
            </div>
          </div>

        </mat-option>
      </mat-autocomplete>


      <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="resetInput($event)" [matAutocomplete]="autoComplete"
        [formControl]="searchValue">
      <!-- <mat-icon matSuffix (click)="submit()">sentiment_very_satisfied</mat-icon> -->

    </mat-chip-list>
  </mat-form-field>

  <button mat-icon-button class="submit-button" (click)="onSubmit()" [disabled]="selectedUsers.length < 1">
    <mat-icon class="submit-button-icon">send</mat-icon>
  </button>
</div>