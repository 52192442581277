import { SuggestionFormComponent } from './components/suggestion-form/suggestion-form.component';
import { RequestFeedbackFormComponent } from './components/request-feedback-form/request-feedback-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './containers/feedback/feedback.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';


import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeedbackTypesEffects } from './store/effects/feedback-types.effects';
import { reducers } from './store/reducers';

import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/shared/shared.module';
import { FeedbackButtonComponent } from './components/feedback-button/feedback-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatRadioModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    SharedModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature('feedback', reducers),
    EffectsModule.forFeature([FeedbackTypesEffects]),
    TranslateModule.forChild(),
  ],
  declarations: [
    FeedbackComponent,
    FeedbackFormComponent,
    RequestFeedbackFormComponent,
    FeedbackButtonComponent,
    SuggestionFormComponent
  ],
  exports: [
    // FeedbackComponent,
    // FeedbackFormComponent,
    FeedbackButtonComponent
  ]
})
export class FeedbackModule { }
