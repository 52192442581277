import { map, filter } from 'rxjs/operators';
import { FeedbackKind } from './../../../../../../shared/model/feedback-kind.enum';
import { FeedbackComponent } from './../../containers/feedback/feedback.component';
import { Component, OnInit } from '@angular/core';
import { feedbackButtonAnimations } from './feedback-button.animation';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as fromAuthStore from '../../../auth/store/reducers/auth.reducer';
import { Observable, Subscription } from 'rxjs';
import { Organization } from '../../../../../../shared/model/organization.interface';

@Component({
  selector: 'app-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss'],
  animations: feedbackButtonAnimations
})
export class FeedbackButtonComponent implements OnInit {

  subscriptions: Subscription[] = [];
  organization: Observable<Organization>;

  // Based on https://medium.com/@aphlps/fab-speed-dial-with-angular-5-2-angular-material-be696fc14967
  fabButtons = [
    // {
    //   icon: 'move_to_inbox',
    //   text: this.translate.instant('feedback.feedback_button_suggestion'),
    //   type: 'suggestion'
    // },
    // {
    //   icon: 'question_answer',
    //   text: this.translate.instant('feedback.feedback_button_request_feedback'),
    //   type: 'request_feedback'
    // },
    // {
    //   icon: 'lightbulb_outline',
    //   // icon: 'sentiment_dissatisfied',
    //   text: this.translate.instant('feedback.feedback_button_tip'),
    //   type: 'tip'
    // },
    // {
    //   icon: 'thumb_up',
    //   text: this.translate.instant('feedback.feedback_button_praise'),
    //   type: 'praise'
    // }
  ];
  buttons = [];
  fabTogglerState = 'inactive';

  feedbackKind = FeedbackKind;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private authStore: Store<fromAuthStore.State>
  ) { }

  ngOnInit() {

    this.subscriptions.push(

      this.authStore.select(fromAuthStore.getOrganization).pipe(
        // take(1)
        filter(organization => !!organization),
        map((organization: Organization) => {
          this.fabButtons = [];
          
          if (organization.settings.suggestionsEnabled) {
            this.fabButtons.push(
              {
                icon: 'move_to_inbox',
                text: this.translate.instant('feedback.feedback_button_suggestion'),
                type: 'suggestion'
              }
            )
          }

          if (organization.settings.feedbackRequestsEnabled) {
            this.fabButtons.push(
              {
                icon: 'question_answer',
                text: this.translate.instant('feedback.feedback_button_request_feedback'),
                type: 'request_feedback'
              }
            )
          }

          if (organization.settings.tipsEnabled) {
            this.fabButtons.push(
              {
                icon: 'lightbulb_outline',
                text: this.translate.instant('feedback.feedback_button_tip'),
                type: 'tip'
              }
            )
          }

          if (organization.settings.publicPraisesEnabled) {
            this.fabButtons.push(
              {
                icon: 'thumb_up',
                text: this.translate.instant('feedback.feedback_button_praise'),
                type: 'praise'
              }
            )
          }
        })
      ).subscribe()

    )

  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  openFeedbackDialog(button) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'feedback-dialog-container'

    switch (button.type) {
      case 'praise':
        dialogConfig.data = {
          preselectedRecipient: null,
          isGiveFeedbackForm: true,
          feedbackKind: FeedbackKind.Praise
        };
        break;
      case 'tip':
        dialogConfig.data = {
          preselectedRecipient: null,
          isGiveFeedbackForm: true,
          feedbackKind: FeedbackKind.Tip
        };
        break;
      case 'request_feedback':
        dialogConfig.data = {
          preselectedRecipient: null,
          isRequestFeedbackForm: true,
        };
        break;
      case 'suggestion':
        dialogConfig.data = {
          isSuggestionForm: true,
        };
        break;
    }

    this.onToggleFab();
    this.dialog.open(FeedbackComponent, dialogConfig);

  }
 
  ngOnDestroy(){
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
