import { BotActionTextInput } from './../../../../../../shared/model/bot-action-text-input.interface';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-bot-action-text-input',
  templateUrl: './bot-action-text-input.component.html',
  styleUrls: ['./bot-action-text-input.component.scss']
})
export class BotActionTextInputComponent implements OnInit {

  @Input()
  botActionTextInput: BotActionTextInput;

  @Output()
  submit: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
 
  onSubmit(text: string){
    // console.log('submit', text);
    this.submit.emit(text);
  }
}
