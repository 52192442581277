import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../../../../../../shared/model/user.interface';

import { Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/store/reducers/auth.reducer';
import * as authActions from '../../../auth/store/actions/auth.actions';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../services/navigation.service';
import { Organization } from '../../../../../../shared/model/organization.interface';

@Component({
  selector: 'app-nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.scss']
})
export class NavTopComponent implements OnInit {

  authUser: Observable<User>;
  organization: Observable<Organization>;
  isSearchShown: Observable<boolean>;

  @Input() 
  private sidenavRef: MatSidenav;

  constructor(
    private store: Store<fromAuth.State>,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.authUser = this.store.select(fromAuth.getUser);
    this.organization = this.store.select(fromAuth.getOrganization);
    this.isSearchShown = this.navigationService.isSearchShown();
  }

  logout() {
    this.store.dispatch(new authActions.Logout());
  }

  // toggleSearch() {
  //   this.showSearch = !this.showSearch;

  //   console.log(this.showSearch);
  // }

  openSearch(){
    this.navigationService.openSearch();
  }

  closeSearch(){
    this.navigationService.closeSearch();
  }

  toggleSidenav(){
    this.sidenavRef.toggle();
  }

}
