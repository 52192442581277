import { Component, OnInit, Input } from '@angular/core';
import { feedbackType } from 'app/admin-feedback-types/store/reducers/feedback-type.reducer';
import { FeedbackType } from '../../../../../../shared/model/feedback-type.interface';

@Component({
  selector: 'app-feedback-type-icon',
  templateUrl: './feedback-type-icon.component.html',
  styleUrls: ['./feedback-type-icon.component.scss']
})
export class FeedbackTypeIconComponent implements OnInit {

  @Input()
  feedbackType: FeedbackType
  
  constructor() { }

  ngOnInit() {
  }

}
