import { Notification } from './../../model/notification.model';
import { NotificationService } from './../../services/notification.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  notifications: Observable<Notification[]>;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.notifications = this.notificationService.getNotifications();
  }

  onDismissNotification(notificationId: number){
    this.notificationService.dismissNotification(notificationId);
  }

}
