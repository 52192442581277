<div class="user-select-wrapper card-panel">
  <mat-form-field class="full-width user-select-input" appearance="outline">
    <mat-label>{{ botActionTeamSelect.placeholder }}</mat-label>

    <mat-chip-list #chipList>
      <mat-chip *ngFor="let team of selectedTeams" [selectable]="true" [removable]="true"
        (removed)="removeTeamFromSelected(team)">
        {{team.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <mat-autocomplete #autoComplete>
        <mat-option class="user-select-item" *ngFor="let team of filteredTeams" [value]="team.name">

            <div class="user-item" (click)="addTeamToSelected(team)">

            <div>
              <div class="user-name">{{team.name}}</div>
              <div class="user-jobTitle text-muted">{{team.description}}</div>
            </div>
          </div>

        </mat-option>
      </mat-autocomplete>


      <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="resetInput($event)" [matAutocomplete]="autoComplete"
        [formControl]="searchValue">

    </mat-chip-list>
  </mat-form-field>

  <button mat-icon-button class="submit-button" (click)="onSubmit()" [disabled]="selectedTeams.length < 1">
    <mat-icon class="submit-button-icon">send</mat-icon>
  </button>
</div>