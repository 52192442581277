import { FeedbackType } from './../../../../../../shared/model/feedback-type.interface';
import { BotActionFeedbackTypeSelect } from './../../../../../../shared/model/bot-action-feedback-type-select.interface';
import { Observable } from 'rxjs';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-bot-action-feedback-type-select',
  templateUrl: './bot-action-feedback-type-select.component.html',
  styleUrls: ['./bot-action-feedback-type-select.component.scss']
})
export class BotActionFeedbackTypeSelectComponent implements OnInit {

  @Input()
  botActionFeedbackTypeSelect: BotActionFeedbackTypeSelect;

  @Output()
  submit: EventEmitter<FeedbackType> = new EventEmitter();

  feedbackTypes: Observable<FeedbackType[]>;

  constructor(
    private dataProvider: DataProviderService,
  ) { }

  ngOnInit() {

    // if(this.botActionFeedbackTypeSelect.onlyPositive){
    //   this.feedbackTypes = this.dataProvider.getActivePositiveFeedbackTypesAllowedForAuthUser();
    // } else {
    //   this.feedbackTypes = this.dataProvider.getActiveFeedbackTypesAllowedForAuthUser();
    // }
    this.feedbackTypes = this.dataProvider.getActiveFeedbackTypes();
  }

  onSubmit(feedbackType: FeedbackType){
    this.submit.emit(feedbackType);
  }

}
