import { FeedbackType } from '../../../../../../shared/model/feedback-type.interface';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FeedbackTypesActionTypes, FeedbackTypesActions } from './../actions/feedback-types.actions';

export interface State extends EntityState<FeedbackType> {
    // additional entities state properties
    loading: boolean;
    error: any;
};

const adapter: EntityAdapter<FeedbackType> = createEntityAdapter<FeedbackType>();
export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    loading: true,
    error: null
});



export function reducer(
    state: State = initialState,
    action: FeedbackTypesActions
): State {

    switch (action.type) {
        case FeedbackTypesActionTypes.LoadFeedbackTypes:
            // return {
            //     ...state,
            //     loading: true
            // }
            return initialState;

        case FeedbackTypesActionTypes.LoadFeedbackTypesSuccess:
            return adapter.addAll(action.feedbackTypes, {
                ...state,
                loading: false,
            })

        case FeedbackTypesActionTypes.LoadFeedbackTypesFailure:
            return {
                ...state,
                error: action.error,
            }

        default:
            return state;

    }
}

// Selectors
export const loading = (state: State) => state.loading;
export const {
    // select the array of ids
    selectIds,
    // select the dictionary of entities
    selectEntities,
    // select the array of objects
    selectAll,
    // select the total count
    selectTotal
} = adapter.getSelectors();