import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-notification-pwa-install',
  templateUrl: './notification-pwa-install.component.html',
  styleUrls: ['./notification-pwa-install.component.scss']
})
export class NotificationPwaInstallComponent implements OnInit {

  showInstallNotification = false;

  constructor(
    private swUpdate: SwUpdate,
  ) { }

  ngOnInit() {
    if (this.swUpdate.isEnabled && this.isIOS() && !this.isInStandaloneMode()) {
      this.showInstallNotification = true;
    }
  }


  isIOS(): boolean {
    return ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
  }

  isInStandaloneMode() {
    // var displayModes = ["fullscreen", "standalone", "minimal-ui"];
    // displayModes.forEach(function (displayMode) {
    //   if (window.matchMedia('(display-mode: ' + displayMode + ')').matches) {
    //     console.log('!!!isPwa', displayMode);
    //     return true;
    //   }
    // });
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }
    return false;
  }

  onCloseNotification(){
    this.showInstallNotification = false;
  }
}
