import { NotificationPwaInstallComponent } from './containers/notification-pwa-install/notification-pwa-install.component';
import { NotificationPwaOfflineComponent } from './containers/notification-pwa-offline/notification-pwa-offline.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationPwaUpdateComponent } from './containers/notification-pwa-update/notification-pwa-update.component';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule.forChild()
  ],
  declarations: [
    NotificationPwaUpdateComponent,
    NotificationPwaInstallComponent,
    NotificationPwaOfflineComponent
  ],
  exports: [
    NotificationPwaUpdateComponent,
    NotificationPwaInstallComponent,
    NotificationPwaOfflineComponent
  ]
})
export class NotificationPwaModule { }
