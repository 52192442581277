import { BotEventType } from './../../../../../../shared/model/bot-event-type.enum';
import { BotActionType } from '../../../../../../shared/model/bot-action-type.enum';
import { BotMessage } from '../../../../../../shared/model/bot-message.interface';
import { Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bot-dialog',
  templateUrl: './bot-dialog.component.html',
  styleUrls: ['./bot-dialog.component.scss']
})
export class BotDialogComponent implements OnInit {

  isDialogOpen: boolean = false;
  eventType: BotEventType = BotEventType.Welcome;
  
  constructor() { }

  ngOnInit() {
  }

  openDialog(){
    this.isDialogOpen = true;
  }

  closeDialog(){
    this.isDialogOpen = false;
  }
}
