import { FeedbackTag } from './../../../../../../shared/model/feedback-tag.interface';
import { Team } from './../../../../../../shared/model/team.interface';
import { FeedbackType } from './../../../../../../shared/model/feedback-type.interface';
import { BotActionType } from './../../../../../../shared/model/bot-action-type.enum';
import { BotMessage } from './../../../../../../shared/model/bot-message.interface';
import { BotAction } from './../../../../../../shared/model/bot-action.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BotMessageAuthor } from '../../../../../../shared/model/bot-message-author.enum';
import { User } from '../../../../../../shared/model/user.interface';

@Component({
  selector: 'app-bot-action',
  templateUrl: './bot-action.component.html',
  styleUrls: ['./bot-action.component.scss']
})
export class BotActionComponent implements OnInit {

  @Input()
  action: BotAction;
  // action: BotActionButton | BotActionUserSelect;

  @Output()
  message: EventEmitter<BotMessage> = new EventEmitter();

  botActionType = BotActionType;

  constructor() { }

  ngOnInit() {
  }

  onButtonSubmit(text: string) {
    this.message.emit({
      text: text,
      sentBy: BotMessageAuthor.User,
      producedByActionInteraction: true,
      originActionType: BotActionType.Button,
    })
  }

  onTextInputSubmit(text: string) {
    this.message.emit({
      text: text,
      sentBy: BotMessageAuthor.User,
      producedByActionInteraction: true,
      originActionType: BotActionType.TextInput,
    })
  }

  onUserSelectSubmit(users: User[]) {
    // Convert user ids to format <@ >
    let text: string = '';
    users.map(user => text = `${text}<@${user.id}>`)

    // console.log('userSelect', text);
    this.message.emit({
      text: text,
      sentBy: BotMessageAuthor.User,
      producedByActionInteraction: true,
      originActionType: BotActionType.UserSelect,
      originActionParameters: {
        users: users
      }
    })
  }

  onTeamSelectSubmit(teams: Team[]) {
    // Convert team ids to format <# >
    let text: string = '';
    teams.map(team => text = `${text}<#${team.id}>`)

    // console.log('userSelect', text);
    this.message.emit({
      text: text,
      sentBy: BotMessageAuthor.User,
      producedByActionInteraction: true,
      originActionType: BotActionType.TeamSelect,
      originActionParameters: {
        teams: teams
      }
    })
  }
  

  onFeedbackTypeSelectSubmit(feedbackType: FeedbackType) {
    // Convert feedback type id to format <@ >
    let text: string = `<@${feedbackType.id}>`;
    this.message.emit({
      text: text,
      sentBy: BotMessageAuthor.User,
      producedByActionInteraction: true,
      originActionType: BotActionType.FeedbackTypeSelect,
      originActionParameters: {
        feedbackType: feedbackType
      }
    })
  }

  onFeedbackTagSelectSubmit(feedbackTags: FeedbackTag[]) {
    // Convert ids to format <@ >
    let text: string = '';
    feedbackTags.map(feedbackTag => text = `${text}<@${feedbackTag.id}>`)

    this.message.emit({
      text: text,
      sentBy: BotMessageAuthor.User,
      producedByActionInteraction: true,
      originActionType: BotActionType.TeamSelect,
      originActionParameters: {
        teams: feedbackTags
      }
    })
  }

}
