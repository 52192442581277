<form [formGroup]="loginForm" novalidate>
  <div class="row">
    <div class="col">
      <mat-form-field class="full-width">
        <input matInput type="email" formControlName="email" placeholder="{{ 'auth.email' | translate }}" required>
        <!--Required error-->
        <mat-hint *ngIf="loginForm.get('email').hasError('required') && loginForm.get('email').touched" [ngStyle]="{'color': 'red'}"
          align="start">{{ 'auth.email_required' | translate }}</mat-hint>
        <!--Wrong email error-->
        <mat-hint *ngIf="!loginForm.get('email').hasError('required') && loginForm.get('email').hasError('email') && loginForm.get('email').touched"
          [ngStyle]="{'color': 'red'}" align="start">{{ 'auth.email_invalid' | translate }}</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="row">

    <div class="col">
      <mat-form-field class="full-width">
        <input matInput type="password" formControlName="password" placeholder="{{ 'auth.password' | translate }}" required>
        <mat-hint *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched" [ngStyle]="{'color': 'red'}"
          align="start">{{ 'auth.password_required' | translate }}</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      <button type="button" mat-flat-button color="primary" class="login-button" [disabled]="!loginForm.valid" (click)="onSubmit()">
          {{ 'auth.login_button' | translate }}
      </button>
    </div>
  </div>
</form>