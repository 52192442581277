import { NotificationService } from './../../../notification/services/notification.service';
import { FeedbackRequestToken } from './../../../../../../shared/model/feedback-request-token.interface';
import { FeedbackKind } from './../../../../../../shared/model/feedback-kind.enum';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { take, map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../../../../../../shared/model/user.interface';

import { Store } from '@ngrx/store';
import * as fromAuthStore from '../../../auth/store/reducers/auth.reducer';
import { Feedback } from '../../../../../../shared/model/feedback.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  preselectedRecipient: User;
  feedbackKind: FeedbackKind;
  isGiveFeedbackForm: boolean;
  isRequestFeedbackForm: boolean;
  isResponseFeedbackForm: boolean;
  feedbackRequestToken: FeedbackRequestToken;
  isSuggestionForm: boolean;

  author: Observable<User>;

  constructor(
    private authStore: Store<fromAuthStore.State>,
    private dialogRef: MatDialogRef<FeedbackComponent>,
    private notificationService: NotificationService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.preselectedRecipient = data.preselectedRecipient;
    this.isGiveFeedbackForm = data.isGiveFeedbackForm;
    this.feedbackKind = data.feedbackKind;
    this.isRequestFeedbackForm = data.isRequestFeedbackForm;
    this.isResponseFeedbackForm = data.isResponseFeedbackForm;
    this.feedbackRequestToken = data.feedbackRequestToken;
    this.isSuggestionForm = data.isSuggestionForm;
  }


  ngOnInit() {
    // Get feedback author
    this.author = this.authStore.select(fromAuthStore.getUser).pipe(
      map((author: User) => {
        // this.isDailyFeedbackLimitExceeded = this.dataProvider.isDailyFeedbackLimitExceeded(author.id, this.user.id);
        return author;
      }));


  }


  onSubmit() {
    this.dialogRef.close();
    if (this.isRequestFeedbackForm) {
      this.notificationService.showNotification(this.translate.instant('feedback.feedback_request_success'), 'success', true);
    }
    if (this.isGiveFeedbackForm || this.isResponseFeedbackForm) {
      if (this.feedbackKind === FeedbackKind.Praise) {
        this.notificationService.showNotification(this.translate.instant('feedback.praise_success'), 'success', true);
      }
      if (this.feedbackKind === FeedbackKind.Tip) {
        this.notificationService.showNotification(this.translate.instant('feedback.tip_success'), 'success', true);
      }
    }
    if (this.isSuggestionForm) {
      this.notificationService.showNotification(this.translate.instant('feedback.suggestion_success'), 'success', true);
    }

  }

  onCancel() {
    this.dialogRef.close();
  }
}
