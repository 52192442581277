import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { map, take, tap, filter, switchMap } from 'rxjs/operators';
import * as AuthActions from '../store/actions/auth.actions';
import * as fromAuth from '../store/reducers/auth.reducer';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private store: Store<fromAuth.State>,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        
        // Check AngularFire2 auth state
        return this.afAuth.authState.pipe(
            take(1),
            map((firebaseUser: firebase.User) => {
                if (!firebaseUser) {
                    this.store.dispatch(new AuthActions.SaveLoginRedirectUrl(state.url));
                    this.router.navigate(['/login']);
                    return false;
                } else {
                    this.store.dispatch(new AuthActions.LoginSuccess(firebaseUser));
                    return true;
                }
            })
        )
    }
}
