import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  showSearch: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  openSearch() {
    this.showSearch.next(true);
  }
  
  closeSearch(){
    this.showSearch.next(false);
  }

  isSearchShown() {
    return this.showSearch;
  }

}
