import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../../../../shared/model/user.interface';

@Component({
  selector: 'app-user-avatar-with-info',
  templateUrl: './user-avatar-with-info.component.html',
  styleUrls: ['./user-avatar-with-info.component.scss']
})
export class UserAvatarWithInfoComponent implements OnInit {

  @Input()
  user: User;
  
  @Input()
  isLoading: boolean;

  @Input()
  showLinkToUserProfile?: boolean = true;
  
  constructor() { }

  ngOnInit() {
  }

}
