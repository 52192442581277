import { FeedbackKind } from './../../../../../../shared/model/feedback-kind.enum';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-feedback-kind-icon',
  templateUrl: './feedback-kind-icon.component.html',
  styleUrls: ['./feedback-kind-icon.component.scss']
})
export class FeedbackKindIconComponent implements OnInit {

  @Input()
  feedbackKind: FeedbackKind

  feedbackKindEnum = FeedbackKind;

  constructor() { }

  ngOnInit() {
  }

}
