import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Injectable()
export class RedirectIfLoggedInGuard implements CanActivate {

    constructor(
        private router: Router,
        private afAuth: AngularFireAuth,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        // Check AngularFire2 auth state
        return this.afAuth.authState.pipe(
            switchMap((firebaseUser: firebase.User) => {
                if (firebaseUser) { //if logged in redirect to feed
                    this.router.navigate(['/feed']);
                    return of(false);
                } else {
                    return of(true);
                }
            }),
            take(1)
        )
    }

}
