import { BotActionType } from './../../../../../../shared/model/bot-action-type.enum';
import { BotMessageAuthor } from './../../../../../../shared/model/bot-message-author.enum';
import { BotMessage } from './../../../../../../shared/model/bot-message.interface';
import { Component, OnInit, Input } from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';

@Component({
  selector: 'app-bot-message',
  templateUrl: './bot-message.component.html',
  styleUrls: ['./bot-message.component.scss']
})
export class BotMessageComponent implements OnInit {

  @Input()
  message: BotMessage;

  @Input()
  isLoading: boolean;

  botMessageAuthor = BotMessageAuthor;
  botActionType = BotActionType;

  constructor() { }

  ngOnInit() {
  }

}
