import { BotActionButton } from './../../../../../../shared/model/bot-action-button.interface';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-bot-action-button',
  templateUrl: './bot-action-button.component.html',
  styleUrls: ['./bot-action-button.component.scss']
})
export class BotActionButtonComponent implements OnInit {

  @Input()
  botActionButton: BotActionButton;

  @Output()
  submit: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onSubmit(text: string){
    this.submit.emit(text);
  }

}
