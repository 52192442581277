import { OrganizationSettings } from './../../../../../../shared/model/organization-settings.interface';
import { Organization } from './../../../../../../shared/model/organization.interface';
import { FeedbackRequestToken } from './../../../../../../shared/model/feedback-request-token.interface';
import { FeedbackKind } from './../../../../../../shared/model/feedback-kind.enum';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { User } from '../../../../../../shared/model/user.interface';
import { Feedback } from '../../../../../../shared/model/feedback.interface';
import { FeedbackType } from '../../../../../../shared/model/feedback-type.interface';
import { FeedbackVisibilityType } from '../../../../../../shared/model/feedback-visibility-type.enum';
import { FeedbackTag } from '../../../../../../shared/model/feedback-tag.interface';
import { FeedbackTagPartialData } from '../../../../../../shared/model/feedback-tag-partial-data.interface';
import { DataProviderService } from 'app/shared/services/data-provider.service';
import { take, map, switchMap } from 'rxjs/operators';
import { FeedbackRequestTokenStatus } from '../../../../../../shared/model/feedback-request-token-status.enum';

import { Store } from '@ngrx/store';
import * as fromAuthStore from '../../../auth/store/reducers/auth.reducer';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {

  @Input()
  preselectedRecipient: User;

  @Input()
  feedbackKind: FeedbackKind;

  @Input()
  feedbackRequestToken: FeedbackRequestToken;

  @Input()
  author: User;

  @Output()
  cancel = new EventEmitter();

  @Output()
  submit = new EventEmitter();

  showLoader: boolean = true;
  submitDisabled: boolean = false;

  feedbackKindEnum = FeedbackKind;
  feedbackTags: Observable<FeedbackTag[]>
  feedbackTypes: Observable<FeedbackType[]>

  feedbackRecipientsGroup: FormGroup;
  feedbackTypeGroup: FormGroup;
  feedbackTagsGroup: FormGroup;
  feedbackCommentGroup: FormGroup;

  showFeedbackRecipientsStep: boolean = true;
  showFeedbackTypeStep: boolean = true;
  showFeedbackTagsStep: boolean = true;
  showFeedbackCommentStep: boolean = true;

  organizationSettings: OrganizationSettings;

  constructor(
    private formBuilder: FormBuilder,
    private dataProvider: DataProviderService,
    private authStore: Store<fromAuthStore.State>,
  ) { }


  ngOnInit() {

    // Load organization settings
    this.authStore.select(fromAuthStore.getOrganization).pipe(
      take(1),
      map((organization: Organization) => {

        this.organizationSettings = organization.settings;

        // Validate steps to show
        if (this.feedbackKind === FeedbackKind.Tip) {
          this.showFeedbackTypeStep = false;
        }
        if (this.feedbackRequestToken) {
          this.showFeedbackRecipientsStep = false;
        }
        if (!this.organizationSettings.badgesEnabled) {
          this.showFeedbackTypeStep = false;
        }
        if (!this.organizationSettings.feedbackTagsEnabled) {
          this.showFeedbackTagsStep = false;
        }

        // Load data 
        if (this.showFeedbackTypeStep) {
          this.feedbackTypes = this.dataProvider.getActiveFeedbackTypes();
          // this.feedbackTypes = this.dataProvider.getActiveFeedbackTypesAllowedForAuthUser();
        }
        if (this.showFeedbackTagsStep) {
          this.feedbackTags = this.dataProvider.getActiveFeedbackTags();
        }

        this.createFeedbackForm();

        this.showLoader = false;

      })).subscribe();

  }

  createFeedbackForm() {
    // Buid form
    if (this.showFeedbackRecipientsStep) {
      this.feedbackRecipientsGroup = this.formBuilder.group({
        feedbackRecipients: ['', Validators.required],
      });
    }

    if (this.showFeedbackTypeStep) {
      this.feedbackTypeGroup = this.formBuilder.group({
        feedbackType: ['', Validators.required],
      });
    }

    if (this.showFeedbackTagsStep) {
      this.feedbackTagsGroup = this.formBuilder.group({
        feedbackTags: ['', Validators.required],
      });
    }

    if (this.showFeedbackCommentStep) {
      this.feedbackCommentGroup = this.formBuilder.group({
        comment: ['', Validators.required],
      });
    }
  }

  isFeedackFormValid(): boolean {
    let isValid = true;
    if (this.showFeedbackRecipientsStep && !this.feedbackRecipientsGroup.valid) {
      isValid = false;
    }
    if (this.showFeedbackTypeStep && !this.feedbackTypeGroup.valid) {
      isValid = false;
    }
    if (this.showFeedbackTagsStep && !this.feedbackTagsGroup.valid) {
      isValid = false;
    }
    if (this.showFeedbackCommentStep && !this.feedbackCommentGroup.valid) {
      isValid = false;
    }
    return isValid;
  }

  onSubmit() {
    this.submitDisabled = true;

    let kind = this.feedbackKind;

    let comment = '';
    if (this.showFeedbackCommentStep) {
      comment = this.feedbackCommentGroup.value.comment;
    }

    let visibility = this.feedbackKind === FeedbackKind.Praise ? FeedbackVisibilityType.Public : FeedbackVisibilityType.Private;

    let feedbackTypeId = '';
    let points = 0;
    if (this.showFeedbackTypeStep) {
      feedbackTypeId = this.feedbackTypeGroup.value.feedbackType.id;
      points = this.feedbackTypeGroup.value.feedbackType.points;
    }

    let feedbackRequestTokenId = '';
    let feedbackRequestId = '';
    if (this.feedbackRequestToken) {
      feedbackRequestTokenId = this.feedbackRequestToken.id;
      feedbackRequestId = this.feedbackRequestToken.feedbackRequestPartial.id;
    }

    let authorId = this.author.id;

    let tagsIds: string[] = [];
    let tagsPartials: FeedbackTagPartialData[] = [];
    if (this.showFeedbackTagsStep) {
      // Multiple tags
      this.feedbackTagsGroup.value.feedbackTags.map((feedbackTag: FeedbackTag) => {
        tagsIds.push(feedbackTag.id);
        let tagPartial: FeedbackTagPartialData = {
          id: feedbackTag.id,
          name: feedbackTag.name,
          icon: feedbackTag.icon
        }
        tagsPartials.push(tagPartial)
      })

      // // Single tag
      // const feedbackTag: FeedbackTag = this.feedbackTagsGroup.value.feedbackTags;
      // tagsIds.push(feedbackTag.id);
      // const tagPartial: FeedbackTagPartialData = {
      //   id: feedbackTag.id,
      //   name: feedbackTag.name,
      //   icon: feedbackTag.icon
      // }
      // tagsPartials.push(tagPartial)
    }

    // Save feedback for each recipient
    if (this.feedbackRequestToken) {
      // Get recipient from request token
      const feedback: Feedback = {
        kind: kind,
        comment: comment,
        visibility: visibility,
        userId: this.feedbackRequestToken.authorId,
        teamId: this.feedbackRequestToken.authorPartial.teamId,
        authorId: authorId,
        feedbackTypeId: feedbackTypeId,
        points: points,
        feedbackRequestTokenId: feedbackRequestTokenId,
        feedbackRequestId: feedbackRequestId,
        tagsIds: tagsIds,
        tagsPartials: tagsPartials,
      };
      // console.log('!!!!!!!!', feedback);
      this.saveFeedback(feedback);
    }
    else {
      // Get recipients from form
      this.feedbackRecipientsGroup.value.feedbackRecipients.map((feedbackRecipient: User) => {
        const feedback: Feedback = {
          kind: kind,
          comment: comment,
          visibility: visibility,
          userId: feedbackRecipient.id,
          teamId: feedbackRecipient.teamId,
          authorId: authorId,
          feedbackTypeId: feedbackTypeId,
          points: points,
          feedbackRequestTokenId: feedbackRequestTokenId,
          feedbackRequestId: feedbackRequestId,
          tagsIds: tagsIds,
          tagsPartials: tagsPartials,
        };
        // console.log('!!!!!!!!', feedback);
        this.saveFeedback(feedback);
      })
    }

  }

  onCancel() {
    this.cancel.emit();
  }


  saveFeedback(feedback: Feedback) {
    // Save to db 
    this.dataProvider.addFeedback(feedback).pipe(
      take(1),
      map((result: boolean) => {
        if (this.feedbackRequestToken && result) {
          this.updateFeedbackRequestTokenStatus();
        }
        else if (result) {
          this.submit.emit(); //TODO: zamyka sie po pierwszym zapisanym feedbacku obsluzyc wielu odbiorcow
        }
      }),
    ).subscribe();
  }

  updateFeedbackRequestTokenStatus() {
    this.feedbackRequestToken.status = FeedbackRequestTokenStatus.Responded;
    this.dataProvider.updateFeedbackRequestToken(this.feedbackRequestToken).pipe(
      take(1),
      map((result: boolean) => {
        if (result) {
          this.submit.emit(); //TODO: zamyka sie po pierwszym zapisanym feedbacku obsluzyc wielu odbiorcow
        }
      })
    ).subscribe();
  }



}
