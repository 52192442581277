<div class="text-input-wrapper card-panel">

  <mat-form-field class="full-width text-input" appearance="outline">
    <mat-label>{{ botActionTextInput.placeholder }}</mat-label>
    <textarea matInput matTextareaAutosize placeholder="{{ botActionTextInput.placeholder }}" maxlength="200" #text></textarea>
    <div class="text-length-hint text-muted">{{text.value.length}} / 200</div>

    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
    <!-- <mat-hint>Hint</mat-hint> -->
  </mat-form-field>
  <button mat-icon-button class="submit-button" (click)="onSubmit(text.value)" [disabled]="text.value.length < 1">
      <mat-icon>send</mat-icon>
  </button>
</div>