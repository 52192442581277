import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Credentials } from '../../models/auth.models';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Output()
  submit: EventEmitter<Credentials> = new EventEmitter();

  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) { }


  ngOnInit() {
    this.createLoginForm();
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  onSubmit() {

    const credentials: Credentials = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    }

    this.submit.emit(credentials);

  }


}
