<div class="user-avatar-with-info">

  <!-- Avatar -->
  <div *ngIf="!isLoading; else userAvatarPlaceholder">
    <app-user-avatar [user]="user"></app-user-avatar>
  </div>
  <ng-template #userAvatarPlaceholder>
    <app-user-avatar [isLoading]="isLoading"></app-user-avatar>
  </ng-template>

  <!-- Info-->
  <div *ngIf="!isLoading; else userInfoPlaceholder">
    <div class="user-info">
      <a routerLink="/users/{{user.id}}" *ngIf="showLinkToUserProfile">
        {{ user.firstName }} {{ user.lastName }}
      </a>
      <div *ngIf="!showLinkToUserProfile">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <div class="user-job-title text-muted" *ngIf="user.jobTitle">
        {{ user.jobTitle }}
      </div>
    </div>
  </div>

  <ng-template #userInfoPlaceholder>
    <div class="user-info">
      <div class="user-name-placeholder placeholder"></div>
      <div class="user-job-title-placeholder placeholder"></div>
    </div>
  </ng-template>
</div>