export enum BotActionType {
    Button = 'button',
    TextInput = 'textInput',
    UserSelect = 'userSelect',
    FeedbackTypeSelect = 'feedbackTypeSelect',
    PositiveFeedbackTypeSelect = 'positiveFeedbackTypeSelect',
    TeamSelect = 'teamSelect',
    FeedbackTagSelect = 'feedbackTagSelect'
  }

