export class Notification {
    id: number;
    message: string;
    type: 'info' | 'success' | 'error';
    autoClose: boolean;
    autoCloseTime: number; // time to autoclose in ms
    autoCloseProgress: number; // time to autoclose in ms

    constructor(id, message, type?, autoClose?, autoCloseTime?) {
        this.id = id;
        this.message = message;
        this.type = type || 'info';
        this.autoClose = autoClose || false;
        this.autoCloseTime = autoCloseTime || 2500;
        this.autoCloseProgress = 0;
    }

}