import { BotActionFeedbackTagSelectComponent } from './components/bot-action-feedback-tag-select/bot-action-feedback-tag-select.component';
import { BotActionTeamSelectComponent } from './components/bot-action-team-select/bot-action-team-select.component';
import { BotComponent } from './components/bot/bot.component';
import { BotDialogComponent } from './containers/bot-dialog/bot-dialog.component';
import { BotPageComponent } from './containers/bot-page/bot-page.component';
import { BotActionButtonComponent } from './components/bot-action-button/bot-action-button.component';
import { BotService } from './services/bot.service';
import { BotActionTextInputComponent } from './components/bot-action-text-input/bot-action-text-input.component';
import { BotActionFeedbackTypeSelectComponent } from './components/bot-action-feedback-type-select/bot-action-feedback-type-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/shared/shared.module';
import { BotActionUserSelectComponent } from './components/bot-action-user-select/bot-action-user-select.component';
import { BotMessageComponent } from './components/bot-message/bot-message.component';
import { BotActionComponent } from './components/bot-action/bot-action.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSelectModule } from 'app/user-select/user-select.module';
// import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { routes } from './bot.routing';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    UserSelectModule,
    // RouterModule.forChild(routes),
    
    MatChipsModule,
    MatAutocompleteModule,
    MatButtonModule,
    ReactiveFormsModule,
    
    SharedModule,
    TranslateModule.forChild(),
    // NgxAutoScrollModule
  ],
  declarations: [
    BotDialogComponent,
    BotPageComponent,
    BotComponent,
    BotActionTextInputComponent,
    BotActionComponent,
    BotActionButtonComponent,
    BotActionUserSelectComponent,
    BotActionTeamSelectComponent,
    BotActionFeedbackTypeSelectComponent,
    BotActionFeedbackTagSelectComponent,
    BotMessageComponent
  ],
  providers: [
    BotService
  ],
  exports: [
    BotDialogComponent
    // BotPageComponent
  ]
})
export class BotModule { }
